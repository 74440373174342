import React from 'react'

import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'
import { Validator } from 'common/types'

import { ButtonWrapper } from './styles'

interface Props {
  loading: boolean
  open: boolean
  onConfirm: () => void
  onClose: () => void
  validator?: Validator
}

function DeleteConfirmModal({
  open,
  onClose,
  validator,
  onConfirm,
  loading,
}: Props) {
  return (
    <ModalWrapper
      open={open}
      title={`Do you really want to delete ${validator?.name || 'validator'}?`}
      onClose={onClose}
    >
      <ButtonWrapper>
        <Button color="success" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          color="error"
          disabled={loading}
          variant="outlined"
          onClick={onConfirm}
        >
          Delete
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default DeleteConfirmModal
