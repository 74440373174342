import React, { MouseEvent, useCallback } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import PublishIcon from '@mui/icons-material/Publish'
import { Box, TablePagination, Tooltip } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { NewsArticle } from 'common/types'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { getDifferenceFromNow, getFormattedDate } from 'utils/Date'

import { noop } from 'lodash'

import { TextCellWrapper } from './styles'

type Props = {
  count: number
  data?: NewsArticle[]
  onEdit: (rowData: NewsArticle) => void
  onView: (rowData: NewsArticle) => void
  onDelete: (id: string) => void
  onPublish: (rowData: NewsArticle) => void
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

const DAYS_PASSED_FOR_EDIT_LOCK = 2
export default function BasicTable({
  data = [],
  onView,
  onEdit,
  onDelete,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  onPublish,
}: Props) {
  const { meAdmin } = useAppContext()

  const canChange = useCallback((published?: boolean, publishedAt?: string) => {
    if (!published || !publishedAt) {
      return true
    }
    const daysPassed = getDifferenceFromNow(publishedAt)

    return !(
      published &&
      publishedAt &&
      daysPassed >= DAYS_PASSED_FOR_EDIT_LOCK
    )
  }, [])

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Created at</TableCell>
            <TableCell>Published At</TableCell>
            <TableCell>Title</TableCell>
            <TableCell align={'center'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>
                  <TableCell component="th" scope="row">
                    {row?.published
                      ? getFormattedDate(row?.publishedAt)
                      : 'News in drafts'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title={row?.title}>
                      <TextCellWrapper>{row?.title}</TextCellWrapper>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center" width="100%">
                      <Tooltip title="View details">
                        <InfoIcon
                          className="itemIcon"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onView(row)}
                        />
                      </Tooltip>
                      {(row?.createdById === meAdmin?.id ||
                        meAdmin?.isOwner) && (
                        <>
                          {!row?.published && (
                            <Tooltip title="Publish">
                              <PublishIcon
                                className="itemIcon"
                                sx={{ ml: '10px', cursor: 'pointer' }}
                                onClick={() => onPublish(row)}
                              />
                            </Tooltip>
                          )}
                          <Tooltip title="Edit">
                            <EditIcon
                              className="itemIcon"
                              sx={{ ml: '10px', cursor: 'pointer' }}
                              onClick={() => onEdit(row)}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              canChange(row?.published, row?.publishedAt)
                                ? 'Delete'
                                : "Function unavailable. It's been over 48 hours since the news was published."
                            }
                          >
                            <ClearIcon
                              className="itemIcon"
                              color={
                                canChange(row?.published, row?.publishedAt)
                                  ? 'inherit'
                                  : 'disabled'
                              }
                              sx={{
                                ml: '10px',
                                cursor: canChange(
                                  row?.published,
                                  row?.publishedAt,
                                )
                                  ? 'pointer'
                                  : 'not-allowed',
                              }}
                              onClick={
                                canChange(row?.published, row?.publishedAt)
                                  ? () => onDelete(row?.id)
                                  : noop
                              }
                            />
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
