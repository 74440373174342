// todo ask about win32 ?
export default function getPlatform(platform: string) {
  switch (platform) {
    case 'darwin':
      return 'MacOS'
    case 'linux':
      return 'Linux'
    case 'win':
      return 'Windows'
    case 'win32':
      return 'Windows'
    default:
      return platform
  }
}
