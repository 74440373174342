import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'
import { DateTime } from 'luxon'

import find from 'lodash/find'
import map from 'lodash/map'

import { getFormattedNumber } from './Number'

export const createUptimeStatsChartOptions = (
  xTicks?: Array<Date>,
  startHAxis?: string,
) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    colors: ['#4D75B8', '#26B568'],
    chartArea: { left: 40, right: 7, width: '100%', height: 260 },
    hAxis: {
      ...commonChartOptions?.hAxis,
      ticks: xTicks || [],
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
  }
}

export const generateTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
		</div>
		`
}

export const generatePercentageTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${point?.value?.toFixed()}%</p>
		</div>
		`
}

export function generateUptimeStatsChartData(
  points1: Point[],
  points2: Point[],
) {
  return [
    [
      'Date',
      'Amount of below uptime nodes',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Percent of such nodes to all active nodes',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(points1, point => {
      const pointDiff = find(points2, diff => diff?.day === point?.day)
      return [
        new Date(point?.day),
        point?.value,
        generateTooltipBlock(point),
        pointDiff ? pointDiff.value : 0,
        pointDiff && generatePercentageTooltipBlock(pointDiff),
      ]
    }),
  ]
}
