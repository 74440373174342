import React, { useCallback, useState } from 'react'

import { AlertColor, Snackbar } from '@mui/material'
import { Alert } from 'common'
import PageWrapper from 'common/pageWrapper'
import { TwoFactorAuth } from 'common/twoFactorAuth'
import { AlertMessage } from 'common/types'
import { ALERT_DELAY } from 'constants/params'
import { useAppContext } from 'context/AppContext'

import SettingsForm from './settingsForm'
import { Container } from './styles'

function Settings() {
  const { meAdmin } = useAppContext()

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  const handleOpenConfirmModal = useCallback(
    () => setIsConfirmModalOpen(true),
    [],
  )

  const handleCloseConfirmModal = useCallback(
    () => setIsConfirmModalOpen(false),
    [],
  )

  return (
    <PageWrapper
      buttonText="Save settings"
      pageTitle="Settings"
      onClick={meAdmin?.isOwner ? handleOpenConfirmModal : undefined}
    >
      <Container>
        {meAdmin?.isOwner && (
          <SettingsForm
            openConfirmModal={isConfirmModalOpen}
            onAlert={handleAlert}
            onCloseConfirmModal={handleCloseConfirmModal}
          />
        )}

        <TwoFactorAuth onAlert={handleAlert} />
      </Container>

      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </PageWrapper>
  )
}

export default Settings
