import { APIClientStats } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'

import map from 'lodash/map'

import { getDateForCharts } from './Date'

export const createClientStatsChartOptions = (xTicks: Array<Date>) => ({
  ...commonChartOptions,
  isStacked: true,
  colors: ['#D92E35', '#91A7D4'],
  chartArea: { width: '100%', height: 180 },
  hAxis: {
    ...commonChartOptions?.hAxis,
    ticks: xTicks,
    textPosition: 'out',
  },
})

export const generateClientStatsChartTooltipBlock = (point: APIClientStats) =>
  `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 50px;" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${getDateForCharts(
    point.date,
  )}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">Successes: <span style="color: ${'#5674B3'}; font-weight: bold; padding: 0; margin: 0;">${
    point.successes
  }</span></p>
  <p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px; margin: 0;">Failed: <span style="color: ${'#D92E35'}; font-weight: bold; padding: 0; margin: 0;">${
    point.failures
  }</span></p>
		</div>
		`

export function generateClientStatsChartData(points: APIClientStats[]) {
  return [
    [
      'Date',
      'Success',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Failed',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(points, point => {
      return [
        new Date(point.date),
        point.failures,
        generateClientStatsChartTooltipBlock(point),
        point.successes,
        generateClientStatsChartTooltipBlock(point),
      ]
    }),
  ]
}
