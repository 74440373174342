import { gql } from '@apollo/client'

export const CREATE_PROVIDER = gql`
  mutation CreateProvider($createProviderData: CreateProvider!) {
    createProvider(createProviderData: $createProviderData) {
      name
      address
      dailyQueriesCap
      queryConfirmationsCap
    }
  }
`

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider(
    $updateProviderId: String!
    $updateProviderData: UpdateProvider!
  ) {
    updateProvider(
      id: $updateProviderId
      updateProviderData: $updateProviderData
    ) {
      dailyQueriesCap
      queryConfirmationsCap
    }
  }
`

export const DELETE_PROVIDER = gql`
  mutation DeleteProvider($deleteProviderId: String!) {
    deleteProvider(id: $deleteProviderId)
  }
`
