import React, { useMemo } from 'react'

import LensIcon from '@mui/icons-material/Lens'
import { Stack, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { PieChart } from '@mui/x-charts'
import { EmptyChartPlaceholder } from 'common'
import { getFormattedNumber } from 'utils/Number'

import CustomMuiPieChartTooltip from './customMuiPieChartTooltip'
import { ChartWrapper } from './styles'

const pieParams = {
  height: 200,
  margin: { right: 5 },
  slotProps: { legend: { hidden: true } },
}
export const valueFormatter = (item: { value: number }) =>
  getFormattedNumber(item.value)

interface Props {
  bridgedTokens?: number
  stakedTokens?: number
  totalSupply?: number
  legendTitles?: string[]
}
function BridgedCirculationChart({
  bridgedTokens = 0,
  stakedTokens = 0,
  totalSupply = 0,
  legendTitles,
}: Props) {
  const materialTheme = useTheme()

  const series = useMemo(
    () => [
      {
        innerRadius: 0,
        outerRadius: 60,
        id: 'first-level-chart',
        data: [
          {
            label: 'Staked Tokens',
            value: stakedTokens,
            color: materialTheme.palette.primary.main,
          },
          {
            label: 'Bridged Reserve',
            value: bridgedTokens - stakedTokens,
            color: materialTheme.palette.warning.main,
          },
          {
            label: 'Total Supply - Bridged Tokens',
            value: totalSupply - bridgedTokens,
            color: '#C5D1EA',
          },
        ],
        valueFormatter,
      },
      {
        innerRadius: 65,
        outerRadius: 85,
        id: 'second-level-chart',
        data: [
          {
            label: 'Total Bridged Tokens',
            value: bridgedTokens,
            color: materialTheme.palette.warning.main,
          },
          {
            label: 'Total Supply - Bridged Tokens',
            value: totalSupply - bridgedTokens,
            color: '#C5D1EA',
          },
        ],
        valueFormatter,
      },
    ],
    [materialTheme, bridgedTokens, stakedTokens, totalSupply],
  )

  const renderLegend = useMemo(() => {
    return (
      <Box
        alignItems="center"
        display="flex"
        gap={3}
        justifyContent="center"
        sx={{
          fontSize: '12px',
        }}
        width="100%"
      >
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon color="primary" sx={{ fontSize: '8px' }} />
          {legendTitles?.[0]}
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon color="warning" sx={{ fontSize: '8px' }} />
          {legendTitles?.[1]}
        </Box>
      </Box>
    )
  }, [legendTitles])

  if (!bridgedTokens || !totalSupply) return <EmptyChartPlaceholder />

  return (
    <Box>
      <Stack direction="row" spacing={2} textAlign="center" width="100%">
        <ChartWrapper flexGrow={1}>
          <PieChart
            series={series}
            {...pieParams}
            tooltip={{ trigger: 'item', itemContent: CustomMuiPieChartTooltip }}
          />
        </ChartWrapper>
      </Stack>
      {renderLegend}
    </Box>
  )
}

export default BridgedCirculationChart
