import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  background-color: #f7f9ff;
  border: 1px solid #e5edfd;
  border-radius: 8px;
  grid-column: span 1;
  grid-row: span 1;
`
