import { Box, linearProgressClasses } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'

export const TotalWrapper = styled(Box)`
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
`
export const TotalContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1px;
  height: 70px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: white;
`
export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }
`

export const Subtitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const Text = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`
export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: 7,
  borderRadius: 5,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
}))

export const LoadingHolder = styled(Box)`
  height: 70px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
