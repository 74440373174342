import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const TextFieldWrapper = styled(Box)({
  textarea: {
    maxHeight: '150px',
    overflowY: 'auto!important',
    resize: 'both',
  },
})
