export function calculatePercentagesFromValues<
  T extends Record<string, number>,
>(obj: T): Partial<T> {
  let sum: number = 0
  const result: Partial<T> = {}

  Object.values(obj).forEach(value => {
    if (typeof value === 'number') {
      sum += value
    }
  })

  Object.keys(obj).forEach((key: keyof T) => {
    const value = obj[key]
    if (typeof value === 'number') {
      result[key] = ((value / sum) * 100) as T[keyof T]
    }
  })

  return result
}

export const calculatePercentage = (
  partAmount?: number,
  total?: number,
): number => {
  if (!partAmount || !total) {
    return 0
  }

  const percentage = (partAmount / total) * 100
  return Math.round(percentage * 100) / 100
}
