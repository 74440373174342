import React, { MouseEvent } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import { TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/system'
import { Admin } from 'common/types'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { getFormattedDate } from 'utils/Date'

type Props = {
  count: number
  data?: Admin[]
  onDelete: (rowData: Admin) => void
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function BasicTable({
  data = [],
  onDelete,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: Props) {
  const { meAdmin } = useAppContext()
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Created at</TableCell>
            {meAdmin?.isOwner && <TableCell>Delete</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.email}</TableCell>
                  <TableCell>{row?.isOwner ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>

                  {meAdmin?.isOwner && (
                    <TableCell>
                      <Box display="flex" width="100%">
                        <ClearIcon
                          className="itemIcon"
                          sx={{ ml: '10px', cursor: 'pointer' }}
                          onClick={() => onDelete(row)}
                        />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
