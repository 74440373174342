import { gql } from '@apollo/client'
import { PHASE_FIELDS } from 'graphql/phase/fragments/phase'

export const PHASES = gql`
  query Phases($status: PhaseStatus) {
    phases(status: $status) {
      ...PhaseFields
    }
  }
  ${PHASE_FIELDS}
`
