import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Routes } from 'router/routes'

import { ConfirmEmail, Login, Register, ResetPassword } from '../login'
import { Verification } from '../verification'

export default function NotAuthorizedRouter() {
  return useRoutes([
    {
      path: Routes.LOGIN,
      element: <Login />,
    },
    {
      path: Routes.VERIFICATION,
      element: <Verification />,
    },
    {
      path: Routes.REGISTER,
      element: <Register />,
    },
    {
      path: Routes.CONFIRM_EMAIL,
      element: <ConfirmEmail />,
    },
    {
      path: Routes.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: '*',
      element: <Login />,
    },
  ])
}
