import React, { useCallback, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Range } from 'common/types'
import { DEFAULT_DATE_FORMAT } from 'constants/params'
import { MIN_START_DATE_OF_CHARTS } from 'constants/strings'
import { NODE_REWARD_STATS } from 'graphql/rewards/queries/nodeRewardStats'
import { DateTime } from 'luxon'
import { calculatePercentagesFromValues } from 'utils/Percentage'

import {
  BorderLinearProgress,
  Container,
  LoadingHolder,
  Subtitle,
  Text,
  TotalContainer,
  TotalWrapper,
} from './styles'

type Props = {
  range?: Range
  onChangeDateRange?: (rangeUpdater: (prevState: Range) => Range) => void
}

function TotalRewards({ range, onChangeDateRange }: Props) {
  const { loading, data } = useQuery(NODE_REWARD_STATS, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd'),
      to: range?.to?.toFormat('yyyy-MM-dd'),
    },
    skip: !range?.from || !range?.to,
  })

  const validatorRewardsStats = useMemo(
    () => data?.nodeRewardStats || {},
    [data],
  )

  const totalRewardsPercentage = useMemo(
    () =>
      calculatePercentagesFromValues(
        validatorRewardsStats as unknown as {
          [key: string]: number
        },
      ),
    [validatorRewardsStats],
  )

  const handleFrom = useCallback(
    (date: DateTime | null) => {
      onChangeDateRange?.(prevState => ({ ...prevState, from: date }))
    },
    [onChangeDateRange],
  )

  const handleTo = useCallback(
    (date: DateTime | null) => {
      onChangeDateRange?.(prevState => ({ ...prevState, to: date }))
    },
    [onChangeDateRange],
  )

  return (
    <TotalWrapper>
      <Box alignItems="center" display="flex" justifyContent="end">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="From"
            minDate={MIN_START_DATE_OF_CHARTS}
            shouldDisableDate={day => (range?.to ? day > range?.to : false)}
            slotProps={{
              textField: {
                size: 'small',
                sx: { width: '160px' },
                variant: 'standard',
              },
            }}
            value={range?.from}
            onChange={handleFrom}
          />
          <Box mx={0.5}>-</Box>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="To"
            minDate={MIN_START_DATE_OF_CHARTS}
            shouldDisableDate={day => (range?.from ? day < range?.from : false)}
            slotProps={{
              textField: {
                size: 'small',
                sx: { width: '160px' },
                variant: 'standard',
              },
            }}
            value={range?.to}
            onChange={handleTo}
          />
        </LocalizationProvider>
      </Box>
      {loading ? (
        <LoadingHolder>
          <CircularProgress size={20} />
        </LoadingHolder>
      ) : (
        <TotalContainer>
          <Container px={1} py={1}>
            <Subtitle>Penalized</Subtitle>
            <Text sx={{ color: '#26B568' }}>
              {validatorRewardsStats?.penalized?.toFixed(2)} MNW
            </Text>
            <BorderLinearProgress
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#26B568',
                },
              }}
              value={totalRewardsPercentage?.penalized}
              variant="determinate"
            />
          </Container>
          <Container px={1} py={1}>
            <Subtitle>To be paid</Subtitle>
            <Text sx={{ color: '#1976d2' }}>
              {validatorRewardsStats?.toBePaid?.toFixed(2)} MNW
            </Text>
            <BorderLinearProgress
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#1976d2',
                },
              }}
              value={totalRewardsPercentage?.toBePaid}
              variant="determinate"
            />
          </Container>
        </TotalContainer>
      )}
    </TotalWrapper>
  )
}

export default TotalRewards
