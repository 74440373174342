import { gql } from '@apollo/client'

export const VALIDATIONS = gql`
  query validations(
    $filter: Object
    $search: String
    $order: String
    $page: Int
    $take: Int
  ) {
    validations(
      filter: $filter
      search: $search
      order: $order
      page: $page
      take: $take
    ) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        id
        dataProviderRequestTime
        createdAt
        updatedAt
        transactionHash
        hashedData
        nodeId
        providerId
        queryId
        validatorId
        query {
          queryUrl
          hashedData

          externalApiClient {
            id
            name
            isSystem
            enabled
          }
        }
      }
    }
  }
`

export const VALIDATIONS_PER_DAY_BY_NODE_ID = gql`
  query validationsPerDayByNodeId(
    $nodeId: String!
    $filter: Object
    $search: String
    $order: String
    $page: Int
    $take: Int
  ) {
    validationsPerDayByNodeId(
      nodeId: $nodeId
      filter: $filter
      search: $search
      order: $order
      page: $page
      take: $take
    ) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        amount
        day
        reward
      }
    }
  }
`
