import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border: 1px solid #e5edfd;
  background-color: #f7f9ff;
  border-radius: 8px;
`

export const AuthOptionsWrapper = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 8px;
  grid-auto-rows: 1fr;
  margin-top: 16px;
`

export const AuthOptionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #e5edfd;
  background-color: white;
  border-radius: 16px;
`
export const OptionWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Separator = styled('div')`
  height: 100%;
  border-left: 1px solid #e5edfd;
  margin-left: 12px;
  margin-right: 12px;
`
