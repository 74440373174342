import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4D75B8',
    },
    success: {
      main: '#26B568',
    },
    warning: {
      main: '#FFB406',
    },
    error: {
      main: '#E93940',
    },
  },
  typography: {
    fontFamily: ['Archivo', 'sans-serif'].join(','),
  },
})

export default theme
