export enum NodeStatus {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
  READY = 'Ready',
  TERMINATED = 'Terminated',
}

export interface Params {
  filter?: { status: NodeStatus }
  page: number
  take: number
  search?: string
  order?: 'DESC' | 'ASC'
}
