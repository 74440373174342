import { gql } from '@apollo/client'

export const ME_ADMIN = gql`
  query MeAdmin {
    meAdmin {
      createdAt
      email
      id
      isOwner
      name
      role
      verificationTOTPEnabled
    }
  }
`
