import React, { MouseEvent, useCallback } from 'react'

import { TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Node } from 'common/types'
import TableHeader from 'common/ui/tableHeader'
import { SortInput, SortInputOrder } from 'common/ui/tableHeader/types'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'

import map from 'lodash/map'

import { HEADER_CELLS } from './constants'
import TableRowReward from './tableRow'

interface Props {
  count: number
  data?: Node[]
  onCopy: (copyContent: string, filed: string) => void
  page: number
  rowsPerPage: number
  onChangeSort?: (sort: SortInput) => void
  sort?: SortInput
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onOpenValidations: (nodeId: string) => void
  onOpenUptimeChart: (nodeId: string) => void
}

export default function BasicTable({
  data = [],
  count,
  page,
  onPageChange,
  rowsPerPage,
  onCopy,
  onRowsPerPageChange,
  onOpenValidations,
  onOpenUptimeChart,
  onChangeSort,
  sort,
}: Props) {
  const handleRequestSort = useCallback(
    (property: string) => {
      const isAsc =
        sort?.column === property && sort?.order === SortInputOrder.Asc

      onChangeSort?.({
        column: property,
        order: isAsc ? SortInputOrder.Desc : SortInputOrder.Asc,
      })
    },
    [onChangeSort, sort],
  )
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHeader
          headerCells={HEADER_CELLS}
          sort={sort}
          onChangeSort={handleRequestSort}
        />
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {map(data, row => (
                <TableRowReward
                  key={row.id}
                  row={row}
                  onCopy={onCopy}
                  onOpenUptimeChart={onOpenUptimeChart}
                  onOpenValidations={onOpenValidations}
                />
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
