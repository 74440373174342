import React from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Tooltip } from '@mui/material'

import { ChartWrapper, SubTitle, Title } from './styles'

interface Props {
  info?: string
  title: string
  subtitle?: string
  children: React.ReactNode
  withoutBottomPadding?: boolean
}
function ChartContainer({
  title,
  subtitle,
  children,
  withoutBottomPadding,
  info,
}: Props) {
  return (
    <ChartWrapper
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb={withoutBottomPadding ? 0 : 3}
      pt={3}
      px={3}
    >
      <Box>
        <Box alignItems="center" display="flex">
          <Title>{title}</Title>
          {info && (
            <Tooltip title={info}>
              <InfoIcon
                className="itemIcon"
                sx={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  ml: 1,
                }}
              />
            </Tooltip>
          )}
        </Box>
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
      </Box>

      {children}
    </ChartWrapper>
  )
}

export default ChartContainer
