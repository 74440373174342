import React from 'react'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'
import { ExternalAPIClient } from 'common/types'

interface Props {
  entity?: ExternalAPIClient | null
  loading: boolean
  open: boolean
  onClose: () => void
  onConfirm: (entity: ExternalAPIClient | null) => void
}

function DisableExternalAPIClientModal({
  entity,
  open,
  onClose,
  onConfirm,
  loading,
}: Props) {
  if (!entity) return null

  return (
    <ModalWrapper open={open} style={{ width: '400px' }} onClose={onClose}>
      <Box alignItems="center" display="flex" flexDirection="column">
        <ErrorOutlineOutlinedIcon
          sx={{ width: '36px', height: '36px', fill: '#FFB406' }}
        />
        <Typography fontSize={16} fontWeight={600} mt={1.5} textAlign="center">
          Are you sure you want to {entity.enabled ? 'deactivate' : 'activate'}
        </Typography>
        <Typography fontSize={18} fontWeight={600} textAlign="center">
          {entity.name}
        </Typography>
        <Typography
          color="#5B5B5B"
          fontSize={12}
          mb={5}
          mt={1.5}
          textAlign="center"
        >
          You will be able to {entity.enabled ? 'activate' : 'deactivate'} this
          client later
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="16px">
        <Button
          color="error"
          disabled={loading}
          variant="contained"
          onClick={() => onConfirm(entity)}
        >
          {entity.enabled ? 'Deactivate' : 'Activate'}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </ModalWrapper>
  )
}

export default DisableExternalAPIClientModal
