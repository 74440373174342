import { gql } from '@apollo/client'

import { NEWS_FIELDS } from './fragments'

export const ALL_NEWS = gql`
  query AllNews(
    $filter: Object
    $order: String
    $search: String
    $page: Int
    $take: Int
    $published: Boolean
  ) {
    allNews(
      filter: $filter
      order: $order
      search: $search
      page: $page
      take: $take
      published: $published
    ) {
      data {
        ...NewsFields
      }
      meta {
        hasNextPage
        hasPreviousPage
        itemCount
        page
        pageCount
        take
      }
    }
  }
  ${NEWS_FIELDS}
`
