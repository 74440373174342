import React from 'react'

import { Box, Typography } from '@mui/material'
import { ModalWrapper } from 'common'
import { Quest } from 'common/types'
import { getFormattedDate } from 'utils/Date'

interface Props {
  open: boolean
  onClose: () => void
  quest: Quest
}

function ViewQuestModal({ open, onClose, quest }: Props) {
  const {
    title,
    createdAt,
    content,
    publishDate,
    expirationDate,
    twitterId,
    imageUrl,
  } = quest

  return (
    <ModalWrapper
      open={open}
      style={{ maxWidth: '780px' }}
      title="Quest details"
      onClose={onClose}
    >
      <Box display="flex" gap={3} height="100%" width="100%">
        {imageUrl && (
          <Box width="50%">
            <Box
              alt={title}
              component="img"
              loading="lazy"
              src={imageUrl}
              sx={{
                width: '100%',
                height: '400px',
                objectFit: 'cover',
              }}
            />
          </Box>
        )}
        <Box width="50%">
          <Box>
            <Typography fontWeight={600} sx={{ wordWrap: 'break-word' }}>
              {title}
            </Typography>
            <Box
              maxHeight={230}
              my={2}
              sx={{ overflow: 'auto', wordWrap: 'break-word' }}
            >
              {content}
            </Box>
          </Box>
          <Box>
            <Typography mt={1}>Twitter ID: {twitterId}</Typography>

            <Typography mt={1}>
              Created At: {getFormattedDate(createdAt)}{' '}
            </Typography>
            {publishDate && (
              <Typography mt={1}>
                Published At: {getFormattedDate(publishDate)}
              </Typography>
            )}
            {expirationDate && (
              <Typography mt={1}>
                Expire At: {getFormattedDate(expirationDate)}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  )
}

export default ViewQuestModal
