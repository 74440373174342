import React from 'react'

import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'

import { ButtonWrapper } from './styles'

interface Props {
  id: string | null
  loading: boolean
  open: boolean
  onClose: () => void
  onConfirm: (id: string | null) => void
}

function ConfirmModal({ id, open, onClose, onConfirm, loading }: Props) {
  return (
    <ModalWrapper
      open={open}
      title={'Are you sure you want to revoke this node?'}
      onClose={onClose}
    >
      <ButtonWrapper>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="error"
          disabled={loading}
          fullWidth
          variant="contained"
          onClick={() => onConfirm(id)}
        >
          Revoke
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default ConfirmModal
