import React, { useCallback } from 'react'

import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'
import { Input } from 'common'
import { AuthVerification } from 'common/types/verification'
import { VALID_KEYS_FOR_CODE } from 'constants/params'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'

enum FIELDS {
  CODE = 'code',
}
interface Props {
  authVerificationOption?: AuthVerification
  loading?: boolean
  onFinish?: (code: string) => void
  onPrevStep: () => void
}

interface InitialValues {
  [FIELDS.CODE]: string
}

function VerificationCodeForm({
  loading,
  onFinish,
  onPrevStep,
  authVerificationOption,
}: Props) {
  const initialValues = { [FIELDS.CODE]: '' }

  const validationSchema = yup.object().shape({
    [FIELDS.CODE]: yup
      .string()
      .length(
        authVerificationOption === AuthVerification.BACKUPCODE ? 8 : 6,
        `Code must be a ${
          authVerificationOption === AuthVerification.BACKUPCODE
            ? '8-digit'
            : '6-digit'
        }`,
      )
      .required('Code is required'),
  })

  const {
    errors,
    handleSubmit,
    values,
    resetForm,
    handleChange,
  }: FormikProps<InitialValues> = useFormik<InitialValues>({
    initialValues,
    validationSchema,
    onSubmit: (values): void => submit(values),
  })

  const submit = useCallback(
    (values: InitialValues) => {
      if (!values[FIELDS.CODE]) return
      onFinish?.(values[FIELDS.CODE]?.toString()?.trim())
      resetForm()
    },
    [onFinish, resetForm],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Typography fontSize={18} mb={1.25} textAlign={'center'}>
        {authVerificationOption === AuthVerification.BACKUPCODE
          ? 'Enter one of your 8-digit backup codes'
          : 'Get a verification code from the Google Authenticator app'}
      </Typography>
      <Box mt={2}>
        <Input
          error={errors[FIELDS.CODE]}
          name={FIELDS.CODE}
          placeholder="Enter code"
          value={values[FIELDS.CODE]}
          onChange={handleChange}
          onKeyDown={e => {
            if (!VALID_KEYS_FOR_CODE.includes(e.key)) {
              e.preventDefault()
            }
          }}
        />
      </Box>

      <Box display="flex" gap={2} mt={2.5}>
        {' '}
        <Button
          color="primary"
          disabled={loading}
          fullWidth
          variant="outlined"
          onClick={onPrevStep}
        >
          Try another way
        </Button>
        <Button
          color="primary"
          disabled={loading}
          fullWidth
          type={'submit'}
          variant="contained"
        >
          Continue
        </Button>
      </Box>
    </form>
  )
}

export default VerificationCodeForm
