import { ApolloLink, Observable } from '@apollo/client'
import { API } from 'config'
import { print } from 'graphql'
import { createClient } from 'graphql-ws'
import ms from 'ms'
import Shared from 'services/Shared'
import { getToken } from 'services/token'

class WebSocketLink extends ApolloLink {
  client

  timedOut

  socket

  token

  constructor(path) {
    super()

    this.token = getToken()

    this.client = createClient({
      url: `${API.WS}/${path}`,
      keepAlive: ms('15s'),
      on: {
        ping: received => {
          if (!received) {
            this.timedOut = setTimeout(() => {
              if (this.socket?.readyState === WebSocket.OPEN)
                this.socket?.close(4408, 'Request Timeout')
            }, 5_000)
          }
        },
        pong: received => {
          if (received) {
            clearTimeout(this.timedOut)
          }
        },
        connected: socket => {
          this.socket = socket
        },
        closed: () => {
          this.socket = null
        },
      },
      connectionParams: () => {
        const token = getToken()
        return token ? { Authorization: `Bearer ${token}` } : {}
      },
      shouldRetry: () => true,
    })

    Shared.setWebSocketLink(this)
  }

  request(operation) {
    return new Observable(sink =>
      this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: err => {
            if (err instanceof Error) {
              sink.error(err)
            } else if (err instanceof CloseEvent) {
              sink.error(
                new Error(
                  `Socket closed with event ${err.code}: ${err.reason || ''}`,
                ),
              )
            } else if (err instanceof Event) {
              sink.error(err)
            } else {
              sink.error(
                new Error(err.map(({ message }) => message).join(', ')),
              )
            }
          },
        },
      ),
    )
  }

  onTokenChanged(newToken) {
    if (this.socket && this.token !== newToken) {
      this.token = newToken
      this.socket.close(4205, 'Client Restart')
    }
  }
}

export default WebSocketLink
