import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'

import find from 'lodash/find'
import map from 'lodash/map'

import { getDateForCharts } from './Date'
import { getFormattedNumber } from './Number'

export const createBridgedChartOptions = (
  xTicks?: Array<Date>,
  startHAxis?: string,
) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    colors: ['#4D75B8'],
    chartArea: { left: 80, right: 7, width: '100%', height: 350 },
    hAxis: {
      ...commonChartOptions?.hAxis,
      ticks: xTicks || [],
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
  }
}

export const generateTooltipBlock = (point: Point, pointDiff?: number) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${getDateForCharts(
    point?.day,
  )}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
  ${
    pointDiff
      ? `<p style="color: ${
          pointDiff > 0 ? '#26B568' : '#E93940'
        }; font-weight: bold; padding: 0 5px; margin: 0;">(${
          pointDiff > 0 ? '↑' : '↓'
        } ${getFormattedNumber(pointDiff)})</p>`
      : ' '
  }
		</div>
		`
}

export function generateBridgetChartData(points: Point[], diffs: Point[]) {
  return [
    ['Date', 'Tokens', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => {
      const pointDiff = find(diffs, diff => diff?.day === point?.day)
      return [
        new Date(point?.day),
        point?.value,
        generateTooltipBlock(point, pointDiff?.value || 0),
      ]
    }),
  ]
}
