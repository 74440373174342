import { useCallback, useState } from 'react'

import { Range } from 'common/types'
import { DateTime } from 'luxon'

export default function useDateRange(
  defaultFrom = DateTime.now().minus({ year: 1 }),
  defaultTo = DateTime.now(),
  format = 'yyyy-MM-dd',
) {
  const [range, setRange] = useState<Range>({
    from: defaultFrom,
    to: defaultTo,
  })

  const handleDateChange = useCallback(
    (key: 'from' | 'to', date: DateTime | null) => {
      setRange(prevState => ({ ...prevState, [key]: date }))
    },
    [],
  )

  const formattedFrom = range?.from ? range.from.toFormat(format) : undefined
  const formattedTo = range?.to ? range.to.toFormat(format) : undefined

  return {
    range,
    formattedTo,
    formattedFrom,
    setFrom: (date: DateTime | null) => handleDateChange('from', date),
    setTo: (date: DateTime | null) => handleDateChange('to', date),
  }
}
