import { TokenPriceChartKind } from 'common/blocks/tokenPriceChart'
import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'
import { DateTime } from 'luxon'
import { getFormattedNumber } from 'utils/Number'

export const createTokenPriceChartOptions = (
  xTicks?: Array<Date>,
  startHAxis?: string,
) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    chartArea: { left: 40, right: 7, width: '100%', height: 200 },

    hAxis: {
      ...commonChartOptions?.hAxis,
      ticks: xTicks || [],
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
  }
}

export const generateTooltipBlock = (point: Point) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )}</p>
		</div>
		`
}

export function generateTokenPriceChartData(
  chartKind: TokenPriceChartKind,
  chartDataPrice?: Point[],
  chartDataTVL?: Point[],
) {
  const chartPoints =
    chartKind === TokenPriceChartKind.PRICE ? chartDataPrice : chartDataTVL

  if (!chartPoints?.length) return []

  return [
    [
      'Date',
      chartKind === TokenPriceChartKind.PRICE ? 'Price' : 'TVL',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...chartPoints.map(point => [
      new Date(point?.day),
      point?.value,
      generateTooltipBlock(point),
    ]),
  ]
}
