import { ApolloCache } from '@apollo/client'
import { Quest } from 'common/types'

import { QUEST_FIELDS } from './fragments'

export function updateQuestQueryUpdater(
  cache: ApolloCache<any>,
  data: any,
  quest: Quest,
) {
  cache.updateFragment(
    {
      id: `Quest:${quest.id}`,
      fragment: QUEST_FIELDS,
    },
    (data: any) => ({
      ...quest,
      ...data,
    }),
  )
}
