import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { ModalWrapper } from 'common'
import { AlertMessage } from 'common/types'
import { BackupCode, TOTPFactor } from 'common/types/verification'
import { CREATE_TOTP_FACTOR } from 'graphql/auth/mutations'
import { useStepper } from 'hooks'

import {
  BackupCodesForm,
  DownloadLinks,
  QRCodeForm,
  SuccessForm,
} from '../Forms'

interface Props {
  open: boolean
  onAlert: (alert: AlertMessage) => void
  onClose: () => void
}

function ConnectTOTPAuth({ open, onClose, onAlert }: Props) {
  const { step, next, prev, reset } = useStepper([1, 2, 3, 4])

  const [factor, setFactor] = useState<TOTPFactor>()
  const [backupCodes, setBackupCodes] = useState<BackupCode[]>([])

  const [createTOTPFactor, { loading }] = useMutation(CREATE_TOTP_FACTOR)

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const handleSaveBackupCodes = useCallback(
    (codes: BackupCode[]) => setBackupCodes(codes),
    [],
  )

  useEffect(() => {
    if (!open) {
      setFactor(undefined)
      return
    }

    createTOTPFactor().then(result => {
      setFactor(result.data?.createTOTPFactor)
    })
  }, [createTOTPFactor, open])

  const renderContent = useMemo(() => {
    switch (step) {
      case 1:
        return <DownloadLinks onNextStep={next} />
      case 2:
        return (
          <QRCodeForm
            factor={factor}
            loading={loading}
            onAlert={onAlert}
            onNextStep={next}
            onPrevStep={prev}
            onSaveBackupCodes={handleSaveBackupCodes}
          />
        )
      case 3:
        return <BackupCodesForm backupCodes={backupCodes} onNextStep={next} />
      case 4:
        return (
          <SuccessForm
            authMethod={'Authentication App (TOTP)'}
            onNextStep={handleClose}
          />
        )
      default:
        return ''
    }
  }, [
    backupCodes,
    factor,
    handleClose,
    handleSaveBackupCodes,
    loading,
    next,
    onAlert,
    prev,
    step,
  ])

  return (
    <ModalWrapper open={open} onClose={handleClose}>
      {renderContent}
    </ModalWrapper>
  )
}

export default ConnectTOTPAuth
