import { gql } from '@apollo/client'

export const GET_PROVIDERS = gql`
  query GetProviders($search: String, $order: String, $page: Int, $take: Int) {
    getProviders(search: $search, order: $order, page: $page, take: $take) {
      data {
        id
        createdAt
        name
        dailyQueriesCap
        queryConfirmationsCap
      }
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_PROVIDER = gql`
  query GetProvider($getProviderId: String!) {
    getProvider(id: $getProviderId) {
      id
      createdAt
      name
      dailyQueriesCap
      queryConfirmationsCap
    }
  }
`
