import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { NODE_VERSIONS_WITH_COUNT } from 'graphql/overview/queries'
import {
  createVersionsDonutChartOptions,
  generateVersionDonutChartData,
} from 'utils/VersionsDonatChart'

import ChartContainer from '../chartContainer'

const pieColors = ['#9056B3', '#57A1D7', '#56B37B']
function Index() {
  const { loading, data } = useQuery(NODE_VERSIONS_WITH_COUNT)

  const versionsCliData = useMemo(
    () =>
      data?.nodeVersionsWithCount?.cliData?.length
        ? generateVersionDonutChartData(data?.nodeVersionsWithCount?.cliData)
        : [],
    [data],
  )

  const chartOptions = createVersionsDonutChartOptions()

  const offsetsForCli = useMemo(
    () =>
      versionsCliData.map((_: any, index: number) => ({
        color: pieColors[index % pieColors.length],
        offset: 0.008,
      })),
    [versionsCliData],
  )

  return (
    <ChartContainer subtitle="CLI Version" title="Users per CLI version">
      {loading ? (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          {versionsCliData.length ? (
            <Box height="100%" width="100%">
              <Chart
                chartType="PieChart"
                data={versionsCliData}
                height="100%"
                options={{ ...chartOptions, slices: offsetsForCli }}
                width="100%"
              />
            </Box>
          ) : (
            <Typography
              color={'colors.font.default'}
              fontSize={16}
              fontWeight={500}
            >
              Data will be available in a day
            </Typography>
          )}
        </Box>
      )}
    </ChartContainer>
  )
}

export default Index
