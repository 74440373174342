import { InputLabel, Select } from '@mui/material'
import { styled } from '@mui/system'

export const SelectItem = styled(Select)({
  minWidth: '100px',
  margin: '0 10px',
  fontSize: '12px',
  padding: '0px',
  maxHeight: '35px',
})

export const SelectInput = styled(InputLabel)({
  fontSize: '16px',
  lineHeight: '15px',
  top: '-5px',
})
