import React, { useCallback, useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import {
  createUnstakeChartOptions,
  generateUnstakeChartData,
} from 'utils/UnstakeChart'

export enum UnstakeChartKind {
  VALIDATORS = 'validators',
  NODES = 'nodes',
}

function UnstakeStatsChart() {
  const [chartKind, setChartKind] = useState(UnstakeChartKind.VALIDATORS)
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const earlyUnstakeNodeAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.earlyUnstakeNodeAmount || [],
    [data],
  )

  const earlyUnstakeValidatorAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.earlyUnstakeValidatorAmount || [],
    [data],
  )

  const startHAxis = useMemo(() => {
    if (chartKind === UnstakeChartKind.VALIDATORS) {
      return data?.systemDailyStatsGraphData?.earlyUnstakeValidatorAmount[0]
        ?.day
    }
    return data?.systemDailyStatsGraphData?.earlyUnstakeNodeAmount[0]?.day
  }, [chartKind, data])

  const chartOptions = createUnstakeChartOptions(startHAxis)

  const statsChartData = useMemo(
    () =>
      generateUnstakeChartData(
        chartKind,
        earlyUnstakeValidatorAmount,
        earlyUnstakeNodeAmount,
      ),
    [chartKind, earlyUnstakeNodeAmount, earlyUnstakeValidatorAmount],
  )

  const chartColors = useMemo(() => {
    if (chartKind === UnstakeChartKind.VALIDATORS) {
      return ['#91A7D4']
    }

    return ['#26B568']
  }, [chartKind])

  const handleChangeChartKind = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChartKind((event.target as HTMLInputElement).value as UnstakeChartKind)
    },
    [],
  )

  const noChartData = useMemo(() => {
    if (chartKind === UnstakeChartKind.VALIDATORS) {
      return earlyUnstakeValidatorAmount?.length < 2
    }
    return earlyUnstakeNodeAmount?.length < 2
  }, [chartKind, earlyUnstakeNodeAmount, earlyUnstakeValidatorAmount])

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <RadioGroup row value={chartKind} onChange={handleChangeChartKind}>
          <FormControlLabel
            control={<Radio size="small" />}
            label="Validators"
            value={UnstakeChartKind.VALIDATORS}
          />
          <FormControlLabel
            control={<Radio size="small" />}
            label="Nodes"
            value={UnstakeChartKind.NODES}
          />
        </RadioGroup>
        <Box alignItems="center" display="flex" justifyContent="end">
          <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Box height="300px" width="100%">
            <Chart
              chartType="AreaChart"
              data={statsChartData}
              height="100%"
              options={{ ...chartOptions, colors: chartColors }}
              width="100%"
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default UnstakeStatsChart
