import { gql } from '@apollo/client'

export const LOGIN_ADMIN = gql`
  mutation LoginAdmin(
    $email: String!
    $password: String!
    $code: String
    $verification: AuthVerification
  ) {
    loginAdmin(
      email: $email
      password: $password
      code: $code
      verification: $verification
    ) {
      access
      refresh
    }
  }
`

export const REGISTER_ADMIN = gql`
  mutation RegisterAdmin(
    $email: String!
    $password: String!
    $name: String!
    $role: AdminRole!
    $token: String!
  ) {
    registerAdmin(
      email: $email
      password: $password
      name: $name
      role: $role
      token: $token
    ) {
      access
      refresh
    }
  }
`

export const INVITE_ADMIN = gql`
  mutation InviteAdmin($email: String!, $role: AdminRole!) {
    inviteAdmin(email: $email, role: $role)
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshTokenData: RefreshToken!) {
    refreshToken(refreshTokenData: $refreshTokenData) {
      access
      refresh
    }
  }
`

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($deleteAdminId: String!) {
    deleteAdmin(id: $deleteAdminId)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String!
    $newPassword: String!
    $token: String!
  ) {
    resetPassword(email: $email, newPassword: $newPassword, token: $token)
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`

export const CREATE_TOTP_FACTOR = gql`
  mutation createTOTPFactor {
    createTOTPFactor {
      factorId
      secret
      uri
    }
  }
`

export const ENABLE_TOTP_VERIFICATION = gql`
  mutation enableTOTPVerification($code: String!, $factorId: String!) {
    enableTOTPVerification(code: $code, factorId: $factorId) {
      adminId
      code
      createdAt
      id
      updatedAt
    }
  }
`

export const DISABLE_TOTP_VERIFICATION = gql`
  mutation disableTOTPVerification(
    $code: String!
    $verification: AuthVerification!
  ) {
    disableTOTPVerification(code: $code, verification: $verification) {
      ok
    }
  }
`

export const UPDATE_BACKUP_CODES = gql`
  mutation updateBackupCodes {
    updateBackupCodes {
      adminId
      code
      createdAt
      id
      updatedAt
    }
  }
`
