import { ApolloCache } from '@apollo/client'
import { NewsArticle } from 'common/types'
import { DateTime } from 'luxon'

import { NEWS_FIELDS } from './fragments'

export function updateNewsQueryUpdater(
  cache: ApolloCache<any>,
  data: any,
  news: NewsArticle,
) {
  cache.updateFragment(
    {
      id: `NewsType:${news.id}`,
      fragment: NEWS_FIELDS,
    },
    (data: any) => ({
      ...data,
      publishedAt: DateTime.now().toISO(),
    }),
  )
}
