import { gql } from '@apollo/client'

export const NODE_PRINCIPAL_AND_FEE_BY_DAYS = gql`
  query nodePrincipalAndRewardsByDays($from: DateTime!, $to: DateTime!) {
    nodePrincipalAndRewardsByDays(from: $from, to: $to) {
      day
      principal
      reward
    }
  }
`
