import chroma from 'chroma-js'

export function generateColors({
  start,
  base,
  end,
  numSections,
}: {
  start: string
  base: string
  end: string
  numSections: number
}): string[] {
  const baseChromaColor = chroma(base)

  return chroma.scale([start, baseChromaColor, end]).colors(numSections)
}
