import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const PhaseWrapper = styled(Box)`
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
`

export const PhaseFilterContainer = styled(Box)`
  background-color: #f1f1f1;
  border-radius: 8px;
  display: flex;
  padding-right: 8px;
  justify-content: space-between;
`

export const PhaseInfoContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  grid-column-gap: 1px;
  margin-top: 10px;
  border-radius: 8px;
  background-color: white;
  padding: 8px;
  min-height: 55px;
`
