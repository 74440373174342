import { AdminRole } from '../common/types'

export function maskCharacter(str: string, mask: string, n = 1) {
  return str
    .split('')
    .reduce(
      (acc, x, i) => (i > n && i < str.length - n ? acc + mask : acc + x),
      '',
    )
}

export function getAdminRoleFromUrlParams(roleParam?: string) {
  if (!roleParam) return null

  switch (roleParam) {
    case 'admin':
      return AdminRole.ADMIN
    case 'moderator':
      return AdminRole.MODERATOR
    case 'regular-admin':
      return AdminRole.REGULAR_ADMIN
    default:
      return null
  }
}
