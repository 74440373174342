import React from 'react'

import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'
import { AdminRole } from 'common/types'

interface DeleteAdminData {
  name: string
  id: string
}

interface Props {
  open: boolean
  onClose: () => void
  admin: DeleteAdminData
  onDelete: (id: string) => void
  role?: AdminRole
}

function DeleteModal({
  open,
  onClose,
  admin,
  onDelete,
  role = AdminRole.ADMIN,
}: Props) {
  return (
    <ModalWrapper
      open={open}
      title={`Do you really want to delete  ${admin?.name || `${role}`}?`}
      onClose={onClose}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        maxWidth="200px"
        sx={{ m: '0 auto' }}
      >
        <Button color="success" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => onDelete(admin?.id)}
        >
          Delete
        </Button>
      </Box>
    </ModalWrapper>
  )
}

export default DeleteModal
