import { DEFAULT_DATE_FORMAT } from 'constants/params'
import { DateTime } from 'luxon'

export function getFormattedDate(date?: string) {
  return date ? DateTime.fromISO(date).toFormat(DEFAULT_DATE_FORMAT) : '—'
}

export function formatDate(date: DateTime): String {
  return date.toLocaleString(DateTime.DATE_FULL, {
    locale: 'en',
  })
}

export function getDifferenceFromNow(date: string) {
  return Math.abs(DateTime.fromISO(date).diffNow('days').get('day'))
}

export function dateFormatParserForChart(date: string) {
  return DateTime.fromISO(date).toFormat('HH:mm') === '00:00'
    ? DateTime.fromISO(date).toFormat(DEFAULT_DATE_FORMAT)
    : DateTime.fromISO(date).toFormat(`${DEFAULT_DATE_FORMAT} HH:mm`)
}

export function getDateForCharts(date?: string) {
  return date ? DateTime.fromISO(date).toFormat('LLL dd') : '__'
}
