import { Link } from '@mui/material'
import { styled } from '@mui/system'

export const DownloadLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid #e5edfd;
  background-color: #f7f9ff;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
`
