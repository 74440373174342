import React from 'react'

import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'
import { NewsArticle } from 'common/types'

interface Props {
  entity: NewsArticle | null
  loading: boolean
  open: boolean
  onClose: () => void
  onConfirm: (entity: NewsArticle | null) => void
}

function PublishNewsModal({
  entity,
  open,
  onClose,
  onConfirm,
  loading,
}: Props) {
  return (
    <ModalWrapper
      open={open}
      title={'Are you sure you want to proceed?'}
      onClose={onClose}
    >
      <Typography>
        Please note that after publishing the announcement, the deletion
        function will be unavailable for 48 hours.
      </Typography>
      <Typography>This action will be final and irreversible.</Typography>
      <Box
        alignItems="center"
        display="flex"
        gap="16px"
        justifyContent="center"
        mt={2}
      >
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="error"
          disabled={loading}
          variant="contained"
          onClick={() => onConfirm(entity)}
        >
          Publish
        </Button>
      </Box>
    </ModalWrapper>
  )
}

export default PublishNewsModal
