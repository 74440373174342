import React from 'react'

import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'

import { ButtonWrapper } from './styles'

interface DeleteProviderData {
  name: string
  id: string
}

interface Props {
  open: boolean
  onClose: () => void
  provider: DeleteProviderData
  onDelete: (id: string) => void
}

function DeleteModal({ open, onClose, provider, onDelete }: Props) {
  return (
    <ModalWrapper
      open={open}
      title={`Do you really want to delete ${provider?.name || 'provider'}?`}
      onClose={onClose}
    >
      <ButtonWrapper>
        <Button color="success" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          color="error"
          variant="outlined"
          onClick={() => onDelete(provider?.id)}
        >
          Delete
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default DeleteModal
