import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'

import find from 'lodash/find'
import map from 'lodash/map'

import { getDateForCharts } from './Date'

export const createValidatorsBarChartOptions = (
  xTicks?: Array<Date>,
  startHAxis?: string,
) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    isStacked: true,
    colors: ['#D92E35', '#91A7D4'],
    chartArea: { left: 40, right: 7, width: '100%', height: 200 },
    hAxis: {
      ...commonChartOptions?.hAxis,
      ticks: xTicks || [],
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
  }
}

export const generateTooltipBlock = (
  date: string,
  registeredValidators: number,
  closedValidators: number,
) =>
  `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 50px;" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${getDateForCharts(
    date,
  )}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">Registered: <span style="color: ${'#5674B3'}; font-weight: bold; padding: 0; margin: 0;">${registeredValidators}</span></p>
  <p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px; margin: 0;">Closed: <span style="color: ${'#D92E35'}; font-weight: bold; padding: 0; margin: 0;">${closedValidators}</span></p>
		</div>
		`

export function generateValidatorsBarChartData(
  points1: Point[],
  points2: Point[],
) {
  return [
    [
      'Date',
      'Registered',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Closed',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(points1, point => {
      const pointDiff = find(points2, diff => diff?.day === point?.day)
      return [
        new Date(point?.day),
        pointDiff ? pointDiff.value : 0,
        generateTooltipBlock(point?.day, point?.value, pointDiff?.value || 0),
        point?.value,
        generateTooltipBlock(point?.day, point?.value, pointDiff?.value || 0),
      ]
    }),
  ]
}
