import { NavLink } from 'react-router-dom'

import { Accordion, AccordionSummary, Box, css } from '@mui/material'
import { styled } from '@mui/system'

export const StyledNavBar = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '175px',
  minWidth: '175px',
  borderRight: '1px solid rgb(241, 243, 244)',
})

export const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
  padding: 12px;
  text-decoration: none;

  &.active {
  font-weight: 700;
  background-color: #f1f1f1;
},
  
  svg {
    font-size: 18px;
    width: 30px;
  },
`
export const StyledContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
  padding: 12px;
  
  svg {
    font-size: 18px;
    width: 30px;
  },


`

export const Title = styled(Box)({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 700,
})

export const MenuWrapper = styled(Accordion)`
  box-shadow: none;
  border-radius: 8px;
  border: none;
`

export interface VariantProps {
  active?: boolean
}
const activeCss = ({ active }: VariantProps) =>
  active &&
  css`
    font-weight: 700;
    background-color: #f1f1f1;
  `

export const MenuItem = styled(AccordionSummary)<VariantProps>`
  font-weight: 400;
  font-size: 14px;
  color: #212b36;
  padding: 0;

  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 4px;
  }

  .MuiAccordionSummary-content {
    height: 48px;
    margin: 0;
  }

  ${activeCss}
`
