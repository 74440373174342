import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '30px',
})

export const InfoContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`
