export const ALERT_DELAY = 5000

export const ROWS_PER_PAGE_DEFAULT = 10

export const MIN_ROWS_PER_PAGE_DEFAULT = 5

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50]

export const TOKENS_PER_NODE = 1800

export const WARNING_UPTIME_LINE = 90

export const LOW_UPTIME_LINE = 50

export const DEFAULT_DATE_FORMAT = 'MMM dd yyyy'

export const DEFAULT_DATE_TIME_FORMAT = 'MMM dd yyyy HH:MM'

export const VALID_KEYS_FOR_CODE = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
]

export const DEFAULT_SORT = 'DESC'

export const UPTIME_THRESHOLD = 99
