import React from 'react'

import { ContentWrapper, StyledButton, StyledTopBar, Title } from './styles'

interface Props {
  buttonText?: string
  onClick?: () => void
  pageTitle?: string
  children: React.ReactNode
}

function PageWrapper({ children, onClick, buttonText, pageTitle }: Props) {
  return (
    <ContentWrapper>
      {(pageTitle || buttonText) && (
        <StyledTopBar>
          <Title>{pageTitle}</Title>
          {typeof onClick === 'function' && (
            <StyledButton onClick={onClick}>{buttonText}</StyledButton>
          )}
        </StyledTopBar>
      )}

      {children}
    </ContentWrapper>
  )
}

export default PageWrapper
