import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { LoaderHolder } from 'common'
import { ValidationsPerDay } from 'common/types'
import { MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { VALIDATIONS_PER_DAY_BY_NODE_ID } from 'graphql/validations/queries'

import Table from './table'

type Props = {
  nodeId: string
}

export default function ValidationsTable({ nodeId }: Props) {
  const [page, setPage] = useState(0)

  const [loadRewards, { data, loading, refetch }] = useLazyQuery(
    VALIDATIONS_PER_DAY_BY_NODE_ID,
  )

  const fetchValidatorData = useCallback(() => {
    loadRewards({
      variables: { page: 1, take: MIN_ROWS_PER_PAGE_DEFAULT, nodeId },
    }).then()
  }, [loadRewards, nodeId])

  useEffect(() => {
    if (nodeId) {
      fetchValidatorData()
    }
  }, [fetchValidatorData, nodeId])

  const validationsPerDayMeta = useMemo(
    () => data?.validationsPerDayByNodeId?.meta || [],
    [data],
  )

  const validationsPerDayData: ValidationsPerDay[] = useMemo(
    () => data?.validationsPerDayByNodeId?.data || [],
    [data],
  )

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ page: page + 1 })
      setPage(page)
    },
    [refetch],
  )

  if (loading)
    return (
      <LoaderHolder sx={{ height: '250px' }}>
        <CircularProgress size={30} />
      </LoaderHolder>
    )

  return (
    <Table
      count={validationsPerDayMeta?.itemCount ?? 0}
      data={validationsPerDayData}
      page={page}
      onPageChange={handlePageChange}
    />
  )
}
