export const commonChartOptions = {
  curveType: 'function',
  fontFamily: 'Archivo',
  baselineColor: '#D0D0D9',
  vAxis: {
    viewWindow: { min: 0 },
    baseline: 0,
    gridlines: {
      color: 'transparent',
    },
    textStyle: { color: '#697A97', fontSize: 12 },
  },
  // x
  hAxis: {
    gridlines: {
      color: 'transparent',
    },
    textStyle: { color: '#697A97', fontSize: 12 },
    format: 'dd MMM',
  },
  backgroundColor: 'transparent',
  legend: 'none',
  areaOpacity: 0.1,
  tooltip: { isHtml: true, trigger: 'visible' },
}

export enum ChartType {
  PIE_CHART = 'PieChart',
  COLUMN_CHART = 'ColumnChart',
}
