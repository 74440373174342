import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { LoaderHolder, ModalWrapper } from 'common'
import { DEFAULT_SORT, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { VALIDATIONS } from 'graphql/validations/queries'

import Table from '../ValidationsTable'

interface Props {
  entity: string | null
  open: boolean
  onClose: () => void
}

function ValidationsModal({ open, onClose, entity }: Props) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)

  const [loadValidations, { data, loading, refetch }] =
    useLazyQuery(VALIDATIONS)

  const fetchValidations = useCallback(
    (nodeId: string) =>
      loadValidations({
        variables: {
          page: 1,
          take: ROWS_PER_PAGE_DEFAULT,
          order: DEFAULT_SORT,
          filter: { nodeId },
        },
      }),
    [loadValidations],
  )

  useEffect(() => {
    if (entity) {
      fetchValidations(entity).then()
    }
  }, [entity, fetchValidations])

  const validationsData = useMemo(() => data?.validations?.data || {}, [data])
  const validationsMeta = useMemo(() => data?.validations?.meta || {}, [data])

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ page: page + 1, take: rowsPerPage })
      setPage(page)
    },
    [refetch, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [refetch],
  )

  return (
    <ModalWrapper
      open={open}
      style={{ maxWidth: '70%', height: '90%', overflow: 'hidden' }}
      title={'Validations'}
      onClose={onClose}
    >
      {loading ? (
        <LoaderHolder>
          <CircularProgress />
        </LoaderHolder>
      ) : (
        <Box height="100%" overflow="auto" width="100%">
          <Table
            count={validationsMeta?.itemCount ?? 0}
            data={validationsData}
            page={page}
            rowsPerPage={validationsMeta?.take ?? 0}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </ModalWrapper>
  )
}

export default ValidationsModal
