import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import {
  CircularProgress,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material'
import { Box } from '@mui/system'
import { Alert, SearchInput, SelectInput, SelectItem } from 'common'
import PageWrapper from 'common/pageWrapper'
import { NodesTable } from 'common/tables'
import { AlertMessage, Node } from 'common/types'
import {
  ALERT_DELAY,
  DEFAULT_SORT,
  ROWS_PER_PAGE_DEFAULT,
} from 'constants/params'
import { REVOKE_NODE } from 'graphql/nodes/mutations'
import { NODES } from 'graphql/nodes/queries'
import { useTableSearch } from 'hooks'
import { getErrorMessage } from 'utils/Error'

import ConfirmModal from './modals/confirmModal'
import ViewModal from './modals/viewModal'
import { NodeStatus, Params } from './types'

const params: Params = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  search: '',
  order: DEFAULT_SORT,
}

function Nodes() {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)
  const [filter, setFilter] = useState<undefined | { status: NodeStatus }>(
    undefined,
  )
  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const [viewModal, setViewModal] = useState<{
    isOpen: boolean
    entity: Node | null
  }>({
    isOpen: false,
    entity: null,
  })
  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean
    validatorId: string | null
  }>({
    isOpen: false,
    validatorId: null,
  })

  const [revokeNode, { loading: revokeLoading }] = useMutation(REVOKE_NODE)
  const { loading, data, refetch } = useQuery(NODES, {
    variables: { ...params },
  })

  const nodesData: Node[] = useMemo(() => data?.nodes?.data || [], [data])
  const nodesMeta = useMemo(() => data?.nodes?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        filter,
        page: 1,
      })
      setCurrentPage(0)
    },
  })

  const handleCloseViewModal = useCallback(() => {
    setViewModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenViewModal = useCallback((entity: Node) => {
    setViewModal({ isOpen: true, entity })
  }, [])

  const handleFilter = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      refetch({
        ...params,
        search,
        filter: value ? { status: value as NodeStatus } : undefined,
      })
      setFilter(value ? { status: value as NodeStatus } : undefined)
      setCurrentPage(0)
    },
    [refetch, search],
  )

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ take: rowsPerPage, page: page + 1, search, filter })
      setCurrentPage(page)
    },
    [filter, refetch, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
        filter,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [filter, refetch, search],
  )

  const handleRevoke = useCallback(
    async (validatorId: string | null) => {
      if (!validatorId) {
        setConfirmModal({ isOpen: false, validatorId: null })
        return
      }

      try {
        await revokeNode({
          variables: { validatorId },
          refetchQueries: [
            {
              query: NODES,
              variables: {
                ...params,
                take: rowsPerPage,
                search,
              },
            },
          ],
        })
        setAlert({
          isOpen: true,
          text: 'Node revoked successfully',
          alertColor: 'success',
        })
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
      setConfirmModal({ isOpen: false, validatorId: null })
    },
    [revokeNode, rowsPerPage, search],
  )

  return (
    <>
      <PageWrapper pageTitle="Nodes">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          sx={{ mb: '10px', mx: '30px' }}
        >
          <Box sx={{ my: '10px' }}>
            <SearchInput
              endAdornment={<SearchIcon />}
              placeholder="Search"
              value={search}
              onChange={handleChangeSearch}
            />
            <FormControl>
              <SelectInput id="filter-node">Filter by</SelectInput>
              <SelectItem
                id="nodes-select"
                label="Filter"
                labelId="filter-node"
                sx={{ borderRadius: '6px' }}
                value={filter?.status}
                onChange={handleFilter}
              >
                <MenuItem value={''}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={'Enabled'}>Enabled</MenuItem>
                <MenuItem value={'Disabled'}>Disabled</MenuItem>
              </SelectItem>
            </FormControl>
          </Box>
          {loading ? (
            <Box
              alignItems="center"
              display="flex"
              height={1}
              justifyContent="center"
              width={1}
            >
              <CircularProgress />
            </Box>
          ) : (
            <NodesTable
              count={nodesMeta?.itemCount ?? 0}
              data={nodesData}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRevoke={validatorId =>
                setConfirmModal({ isOpen: true, validatorId })
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
              onView={handleOpenViewModal}
            />
          )}
        </Box>
      </PageWrapper>
      <ConfirmModal
        id={confirmModal.validatorId}
        loading={revokeLoading}
        open={confirmModal.isOpen}
        onClose={() => setConfirmModal({ isOpen: false, validatorId: null })}
        onConfirm={handleRevoke}
      />
      {viewModal?.entity && (
        <ViewModal
          nodeData={viewModal?.entity}
          open={viewModal?.isOpen}
          onClose={handleCloseViewModal}
        />
      )}
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Nodes
