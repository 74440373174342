import { Button } from '@mui/material'
import { styled } from '@mui/system'
import theme from 'theme'

export const StyledButton = styled(Button)({
  background: theme.palette.primary.main,
  color: 'white',
  height: '30px',
  '&:hover': {
    background: theme.palette.primary.main,
    opacity: '0.6',
  },
})
