import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import LensIcon from '@mui/icons-material/Lens'
import { Box } from '@mui/material'
import { EmptyChartPlaceholder } from 'common'
import { createCircularChartOptions } from 'utils/CircularChart'

// todo update type
interface Props {
  chartData?: any
}
function CirculationChart({ chartData }: Props) {
  const chartOptions = createCircularChartOptions()

  const renderLegend = useMemo(() => {
    return (
      <Box
        display="flex"
        sx={{
          color: '#5B5B5B',
          fontSize: '12px',
          position: 'absolute',
          bottom: '0',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Box alignItems="center" display="flex" mr={4}>
          <LensIcon sx={{ mr: 0.5, fontSize: '10px', fill: '#E7ECF6' }} />
          {chartData?.[0]?.[0]}
        </Box>
        <Box alignItems="center" display="flex">
          <LensIcon sx={{ mr: 0.5, fontSize: '10px', fill: '#5674B3' }} />
          {chartData?.[0]?.[1]}
        </Box>
      </Box>
    )
  }, [chartData])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {chartData.length ? (
        <Box height="100%" position="relative" width="100%">
          <Chart
            chartType="PieChart"
            data={chartData}
            height="100%"
            options={chartOptions}
          />
          {renderLegend}
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default CirculationChart
