import { Box } from '@mui/material'
import { MarginProps, styled } from '@mui/system'

export type VariantProps = {
  active?: boolean
  success?: boolean
  danger?: boolean
  disabled?: boolean
}

export type SizeProps = {
  small?: boolean
  medium?: boolean
  large?: boolean
}

export type ContainerProps = VariantProps & SizeProps

export type WrapperProps = ContainerProps & MarginProps

export const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;

  .PhoneInput {
    margin: 0;
    display: flex;
    align-items: center;
    padding: 4px 0 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .PhoneInputCountry {
    margin: 0;
    display: flex;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 15%);
  }

  .PhoneInputInput {
    width: 100%;
    margin: 0;
    border: none;
    outline: none;
    appearance: none;
    background: transparent;
    font-size: 16px;
  }
`
export const ErrorBox = styled(Box)({
  color: 'red',
  minHeight: '15px',
  fontSize: '12px',
  marginTop: '1px',
})

export const LabelBox = styled(Box)({
  fontSize: '12px',
  marginBottom: '6px',
})
