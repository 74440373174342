import React from 'react'

import { Box } from '@mui/material'
import { KeyValueLine, ModalWrapper } from 'common'
import { getFormattedDate } from 'utils/Date'

import { Provider } from '../types'

interface Props {
  open: boolean
  onClose: () => void
  providerData: Provider
}

function ViewProvider({ open, onClose, providerData }: Props) {
  const { name, createdAt, dailyQueriesCap, queryConfirmationsCap } =
    providerData

  return (
    <ModalWrapper open={open} title="View Provider Details" onClose={onClose}>
      <Box sx={{ width: '80%', margin: '0 auto' }}>
        <KeyValueLine label="Name" value={name} />
        <KeyValueLine label="Daily queries cap" value={dailyQueriesCap} />
        <KeyValueLine
          label="Query confirmations cap"
          value={queryConfirmationsCap}
        />
        <KeyValueLine label="Created at" value={getFormattedDate(createdAt)} />
      </Box>
    </ModalWrapper>
  )
}

export default ViewProvider
