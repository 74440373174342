import React, { MouseEvent } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { ValidationsPerDay } from 'common/types'
import { MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { getFormattedDate } from 'utils/Date'

import map from 'lodash/map'

type Props = {
  count: number
  data?: ValidationsPerDay[]
  page: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
}

export default function BasicTable({
  data = [],
  count,
  onPageChange,
  page,
}: Props) {
  return (
    <Table aria-label="rewards" size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left" width={4}>
            Staked at
          </TableCell>
          <TableCell align="center" width={3}>
            Validated transactions
          </TableCell>
          <TableCell align="right" width={1}>
            Total rewards
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!data?.length ? (
          <TableRow>
            <TableCell align={'center'} colSpan={3}>
              The list is empty
            </TableCell>
          </TableRow>
        ) : (
          <>
            {map(data, validationPerDay => (
              <TableRow key={validationPerDay.day}>
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                  {getFormattedDate(validationPerDay.day)}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: 'none' }}>
                  {validationPerDay.amount || '—'}
                </TableCell>
                <TableCell align="right" sx={{ borderBottom: 'none' }}>
                  {validationPerDay.reward.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
            <TablePagination
              count={count}
              page={page}
              rowsPerPage={MIN_ROWS_PER_PAGE_DEFAULT}
              rowsPerPageOptions={[]}
              sx={{
                minHeight: '30px',
                borderTop: '1px solid rgba(224, 224, 224, 1)',
              }}
              onPageChange={onPageChange}
            />
          </>
        )}
      </TableBody>
    </Table>
  )
}
