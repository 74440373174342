import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Title = styled(Box)({
  textAlign: 'center',
  marginBottom: '16px',
})

export const StyledLink = styled(Link)({
  color: '#4D75B8',
  textAlign: 'center',
  fontSize: '12px',
  textDecoration: 'none',
})
