import React, { MouseEvent } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Node } from 'common/types'
import { MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'

import map from 'lodash/map'

import NodeRow from './nodesRow'

type Props = {
  count: number
  data?: Node[]
  page: number
  onCopyAddress?: (field: string, text: string) => void
  onOpenUptimeModal: (nodeId: string) => void
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
}

export default function BasicTable({
  data = [],
  count,
  onCopyAddress,
  onPageChange,
  page,
  onOpenUptimeModal,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="Validators table" size="small" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Rewards</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Term</TableCell>
            <TableCell align="left">Start Date</TableCell>
            <TableCell align="left">End date</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell align="left">Penalty</TableCell>
            <TableCell align="left">Validated tx</TableCell>
            <TableCell align="left">Earned</TableCell>
            <TableCell align="left">Uptime</TableCell>
            <TableCell align="left">App version</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="center">Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={7}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {map(data, (row: Node) => (
                <NodeRow
                  key={row.id}
                  row={row}
                  onCopyAddress={() =>
                    onCopyAddress?.(
                      row?.address,
                      'The node address was copied!',
                    )
                  }
                  onOpenUptimeModal={onOpenUptimeModal}
                />
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={MIN_ROWS_PER_PAGE_DEFAULT}
                rowsPerPageOptions={[]}
                onPageChange={onPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
