import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import {
  AlertColor,
  CircularProgress,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Snackbar,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  Alert,
  LoaderHolder,
  SearchInput,
  SelectInput,
  SelectItem,
} from 'common'
import {
  CreateNewsModal,
  DeleteNewsModal,
  PublishNewsModal,
  ViewNewsModal,
} from 'common/modals'
import PageWrapper from 'common/pageWrapper'
import { NewsTable } from 'common/tables'
import { AlertMessage, NewsArticle } from 'common/types'
import {
  ALERT_DELAY,
  DEFAULT_SORT,
  ROWS_PER_PAGE_DEFAULT,
} from 'constants/params'
import { DELETE_NEWS, PUBLISH_NEWS } from 'graphql/news/mutations'
import { ALL_NEWS } from 'graphql/news/queries'
import { updateNewsQueryUpdater } from 'graphql/news/updaters'
import useTableSearch from 'hooks/useTableSearch'
import { getErrorMessage } from 'utils/Error'

import { Container } from './styles'

interface Params {
  published?: boolean
  page: number
  take: number
  search: string
  order?: 'DESC' | 'ASC'
}

const params: Params = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  search: '',
  order: DEFAULT_SORT,
}

function News() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)
  const [published, setPublished] = useState<boolean | undefined>(undefined)

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const [viewModal, setViewModal] = useState<{
    isOpen: boolean
    entity: NewsArticle | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean
    id: string | null
  }>({
    isOpen: false,
    id: null,
  })

  const [publishModal, setPublishModal] = useState<{
    isOpen: boolean
    entity: NewsArticle | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [createModal, setCreateModal] = useState<{
    isOpen: boolean
    entity: NewsArticle | null
  }>({
    isOpen: false,
    entity: null,
  })

  const { data, loading, refetch } = useQuery(ALL_NEWS, {
    variables: {
      ...params,
    },
  })

  const [publishNews, { loading: publishLoading }] = useMutation(PUBLISH_NEWS)

  const [deleteNews, { loading: deleteLoading }] = useMutation(DELETE_NEWS)

  const allNewsData: NewsArticle[] = useMemo(
    () => data?.allNews?.data || [],
    [data],
  )

  const allNewsMeta = useMemo(() => data?.allNews?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        published,
        page: 1,
      })
      setPage(0)
    },
  })

  const handleFilter = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      let published: boolean | null

      switch (value) {
        case 'true':
          published = true
          break
        case 'false':
          published = false
          break
        case '':
        default:
          published = null
          break
      }

      refetch({
        ...params,
        search,
        published: published !== null ? published : undefined,
      })
      setPublished(published !== null ? published : undefined)
      setPage(0)
    },
    [refetch, search],
  )

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ take: rowsPerPage, page: page + 1, search, published })
      setPage(page)
    },
    [published, refetch, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
        published,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [published, refetch, search],
  )

  const handleDelete = useCallback(
    async (id: string | null) => {
      if (!id) {
        setConfirmModal({ isOpen: false, id: null })
        return
      }

      try {
        await deleteNews({
          variables: { id },
        })
        await refetch({ ...params, take: rowsPerPage, search })
        setAlert({
          isOpen: true,
          text: 'News Article was deleted successfully',
          alertColor: 'success',
        })
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
      setConfirmModal({ isOpen: false, id: null })
    },
    [deleteNews, refetch, rowsPerPage, search],
  )

  const handleCloseCreateModal = useCallback(() => {
    setCreateModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenCreateModal = useCallback(() => {
    setCreateModal({ isOpen: true, entity: null })
  }, [])

  const handleOpenViewModal = useCallback((entity: NewsArticle) => {
    setViewModal({ isOpen: true, entity })
  }, [])

  const handleCloseViewModal = useCallback(() => {
    setViewModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenConfirmModal = useCallback((id: string) => {
    setConfirmModal({ isOpen: true, id })
  }, [])

  const handleCloseConfirmModal = useCallback(() => {
    setConfirmModal({ isOpen: false, id: null })
  }, [])

  const handleOpenPublishModal = useCallback((entity: NewsArticle) => {
    setPublishModal({ isOpen: true, entity })
  }, [])
  const handleClosePublishModal = useCallback(() => {
    setPublishModal({ isOpen: false, entity: null })
  }, [])

  const handleEditModal = useCallback((entity: NewsArticle) => {
    setCreateModal({ isOpen: true, entity })
  }, [])

  const handleCloseAlert = useCallback(() => setAlert({ isOpen: false }), [])

  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  const handlePublish = useCallback(
    async (entity: NewsArticle | null) => {
      if (!entity) {
        setPublishModal({ isOpen: false, entity: null })
        return
      }
      try {
        await publishNews({
          variables: { id: entity?.id },
          update(cache, { data }) {
            return updateNewsQueryUpdater(cache, data, entity)
          },
        })
        setAlert({
          isOpen: true,
          text: 'Announcements was published successfully',
          alertColor: 'success',
        })
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
      setPublishModal({ isOpen: false, entity: null })
    },
    [publishNews],
  )

  return (
    <>
      <PageWrapper
        buttonText="Create"
        pageTitle="Announcements"
        onClick={handleOpenCreateModal}
      >
        <Container>
          <Box sx={{ my: '10px' }}>
            <SearchInput
              endAdornment={<SearchIcon />}
              placeholder="Search by title, content"
              value={search}
              onChange={handleChangeSearch}
            />
            <FormControl>
              <SelectInput id="filter-node">Filter by</SelectInput>
              <SelectItem
                id="news-select"
                label="Filter"
                labelId="filter-news"
                sx={{ borderRadius: '6px' }}
                value={published}
                onChange={handleFilter}
              >
                <MenuItem value={''}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={'true'}>Published</MenuItem>
                <MenuItem value={'false'}>In drafts</MenuItem>
              </SelectItem>
            </FormControl>
          </Box>
          {loading ? (
            <LoaderHolder>
              <CircularProgress />
            </LoaderHolder>
          ) : (
            <NewsTable
              count={allNewsMeta?.itemCount ?? 0}
              data={allNewsData}
              page={page}
              rowsPerPage={rowsPerPage}
              onDelete={handleOpenConfirmModal}
              onEdit={handleEditModal}
              onPageChange={handlePageChange}
              onPublish={handleOpenPublishModal}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onView={handleOpenViewModal}
            />
          )}
        </Container>
      </PageWrapper>
      {viewModal?.entity && (
        <ViewNewsModal
          newsData={viewModal.entity}
          open={viewModal.isOpen}
          onClose={handleCloseViewModal}
        />
      )}

      <CreateNewsModal
        entity={createModal.entity}
        open={createModal.isOpen}
        onAlert={handleAlert}
        onClose={handleCloseCreateModal}
        onRefetch={refetch}
      />
      <DeleteNewsModal
        id={confirmModal.id}
        loading={deleteLoading}
        open={confirmModal.isOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleDelete}
      />
      <PublishNewsModal
        entity={publishModal.entity}
        loading={publishLoading}
        open={publishModal.isOpen}
        onClose={handleClosePublishModal}
        onConfirm={handlePublish}
      />

      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={handleCloseAlert}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={handleCloseAlert}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default News
