import React, { MouseEvent } from 'react'

import { TablePagination, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/system'
import { ExternalAPIClient } from 'common/types'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'

import TableDataRow from './tableRow'

type Props = {
  count: number
  data?: ExternalAPIClient[]
  page?: number
  rowsPerPage: number
  onChangeStatus?: (rowData: ExternalAPIClient) => void
  onCopyKey?: (apiKey: string) => void
  onEdit?: (rowData: ExternalAPIClient) => void
  onOpenStats?: (rowData: ExternalAPIClient) => void
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function BasicTable({
  data = [],
  count,
  page = 0,
  rowsPerPage,
  onChangeStatus,
  onCopyKey,
  onEdit,
  onOpenStats,
  onPageChange,
  onRowsPerPageChange,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>API key</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>
              <Box display="flex" flexDirection="column">
                <Typography fontSize="inherit">
                  Total calls for last 24 hours
                </Typography>
                <Typography fontSize="inherit">(successes/failures)</Typography>
              </Box>
            </TableCell>
            <TableCell>Validations for last 24 hours</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableDataRow
                  row={row}
                  onChangeStatus={() => onChangeStatus?.(row)}
                  onCopyKey={() => onCopyKey?.(row.apiKey)}
                  onEdit={() => onEdit?.(row)}
                  onOpenStats={() => onOpenStats?.(row)}
                />
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
