import { useCallback, useMemo, useState } from 'react'

import get from 'lodash/get'
import values from 'lodash/values'

interface Stepper {
  step: number | undefined
  next: () => void
  prev: () => void
  reset: () => void
}

export default function useStepper(steps: number[]): Stepper {
  const [index, setIndex] = useState<number>(0)
  const stepValues = useMemo(() => values(steps), [steps])

  const next = useCallback(() => {
    setIndex(currentIndex =>
      Math.min(currentIndex + 1, (stepValues.length || 1) - 1),
    )
  }, [stepValues])

  const prev = useCallback(() => {
    setIndex(currentIndex => Math.max(currentIndex - 1, 0))
  }, [])

  const reset = useCallback(() => {
    setIndex(0)
  }, [])

  const step = useMemo(() => get(stepValues, index), [index, stepValues])

  return { step, next, prev, reset }
}
