import { gql } from '@apollo/client'
import { PHASE_FIELDS } from 'graphql/phase/fragments/phase'

export const CURRENT_PHASE = gql`
  query CurrentPhase {
    currentPhase {
      ...PhaseFields
    }
  }
  ${PHASE_FIELDS}
`
