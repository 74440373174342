import { gql } from '@apollo/client'

export const QUERIES_LIST = gql`
  query queriesList(
    $filter: Object
    $search: String
    $page: Int
    $order: String
    $take: Int
  ) {
    queriesList(
      filter: $filter
      search: $search
      page: $page
      order: $order
      take: $take
    ) {
      data {
        id
        createdAt
        dataProvider
        hashedData
        processedAt
        updatedAt
        providerId
        queryUrl
        trackingCode
        transactionHash
        validationTransactionHash
        validationsCount
        validationsLeft
        successValidationsCount
        provider {
          name
        }
        externalApiClient {
          id
          name
          isSystem
          enabled
        }
      }
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
