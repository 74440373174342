import React, { useCallback, useMemo } from 'react'

import { useMutation } from '@apollo/client'
import { Box, TextField, Typography } from '@mui/material'
import { Input, ModalWrapper } from 'common'
import { AlertMessage, NewsArticle } from 'common/types'
import { FormikProps, useFormik } from 'formik'
import { CREATE_NEWS, UPDATE_NEWS } from 'graphql/news/mutations'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { TextFieldWrapper } from './styles'

export interface NewsForm {
  id?: string
  title: string
  content: string
}

interface Props {
  open: boolean
  onClose: () => void
  entity: NewsArticle | null
  onRefetch: () => void
  onAlert: (alert: AlertMessage) => void
}

enum FIELDS {
  ID = 'id',
  TITLE = 'title',
  CONTENT = 'content',
}

interface InitialValues {
  [FIELDS.ID]: string | undefined
  [FIELDS.TITLE]: string
  [FIELDS.CONTENT]: string
}

const CHARACTER_LIMIT_TITLE = 200
const CHARACTER_LIMIT_CONTENT = 4094 - CHARACTER_LIMIT_TITLE

function CreateNewsModal({ open, onClose, entity, onAlert, onRefetch }: Props) {
  const [createNews, { loading: createLoading }] = useMutation(CREATE_NEWS)

  const [updateNews, { loading: updateLoading }] = useMutation(UPDATE_NEWS)

  const validationSchema = yup.object({
    [FIELDS.TITLE]: yup
      .string()
      .required('Title is required')
      .max(
        CHARACTER_LIMIT_TITLE,
        `Title can be at most ${CHARACTER_LIMIT_TITLE} characters long`,
      )
      .matches(
        /^[^\s]+(\s+[^\s]+)*$/,
        'Title cannot start or end with a space, not contain multiple consecutive spaces',
      ),
    [FIELDS.CONTENT]: yup
      .string()
      .required('Content is required')
      .max(
        CHARACTER_LIMIT_CONTENT,
        `Content can be at most ${CHARACTER_LIMIT_CONTENT} characters long`,
      )
      .matches(
        /^[^\s]+(\s+[^\s]+)*$/,
        'Content cannot start or end with a space, not contain multiple consecutive spaces',
      ),
  })

  const initialValues: InitialValues = {
    [FIELDS.ID]: entity?.id,
    [FIELDS.TITLE]: entity?.title || '',
    [FIELDS.CONTENT]: entity?.content || '',
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    dirty,
  }: FormikProps<NewsForm> = useFormik<NewsForm>({
    initialValues,
    validationSchema,
    onSubmit: values => submit(values),
    validateOnChange: false,
    enableReinitialize: true,
  })

  const submit: (values: NewsForm) => Promise<void> = useCallback(
    async (values: NewsForm) => {
      const alertText = values?.id
        ? 'Announcement updated successfully'
        : 'Announcement created successfully'
      const mutationHandler = values.id ? updateNews : createNews
      try {
        await mutationHandler({
          variables: {
            ...values,
          },
        })
        await onRefetch()
        onAlert({
          isOpen: true,
          text: alertText,
          alertColor: 'success',
        })
        onClose()
        resetForm()
      } catch (error) {
        onAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [createNews, onAlert, onClose, onRefetch, resetForm, updateNews],
  )

  const isEdit = useMemo(() => !!entity?.id, [entity])

  return (
    <ModalWrapper
      buttonText={isEdit ? 'Edit' : 'Create'}
      disabled={createLoading || updateLoading || (isEdit && !dirty)}
      open={open}
      title={isEdit ? 'Update announcement' : 'Create announcement'}
      onClose={() => {
        onClose()
        resetForm()
      }}
      onSubmit={handleSubmit}
    >
      <Box>
        <Input
          error={
            errors[FIELDS.TITLE] &&
            `${values[FIELDS.TITLE].length}/${CHARACTER_LIMIT_TITLE} - ${
              errors[FIELDS.TITLE]
            }`
          }
          inputProps={{
            maxlength: CHARACTER_LIMIT_TITLE,
          }}
          label="Title"
          name={FIELDS.TITLE}
          placeholder="Type your title for announcement"
          value={values[FIELDS.TITLE]}
          onChange={handleChange}
        />
        {!errors[FIELDS.TITLE] && (
          <Typography fontSize={12} mt="-17px" sx={{ opacity: 0.7 }}>{`${
            values[FIELDS.TITLE].length
          }/${CHARACTER_LIMIT_TITLE}`}</Typography>
        )}
      </Box>

      <TextFieldWrapper mt={2}>
        <Box mb={1}>Announcement content</Box>
        <TextField
          error={Boolean(errors[FIELDS.CONTENT])}
          fullWidth
          helperText={
            errors[FIELDS.CONTENT]
              ? `${
                  values[FIELDS.CONTENT].length
                }/${CHARACTER_LIMIT_CONTENT} - ${errors[FIELDS.CONTENT]}`
              : `${values[FIELDS.CONTENT].length}/${CHARACTER_LIMIT_CONTENT}`
          }
          inputProps={{
            maxlength: CHARACTER_LIMIT_CONTENT,
          }}
          multiline
          name={FIELDS.CONTENT}
          placeholder="Type your announcement"
          sx={{ mb: 2 }}
          value={values[FIELDS.CONTENT]}
          variant="standard"
          onChange={handleChange}
        />
      </TextFieldWrapper>
    </ModalWrapper>
  )
}

export default CreateNewsModal
