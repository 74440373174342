import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import LensIcon from '@mui/icons-material/Lens'
import { Box } from '@mui/material'
import { EmptyChartPlaceholder } from 'common'
import { StakingPeriod, StakingTermsLabel } from 'common/types'
import { createStakingTermsChartOptions } from 'utils/StakingTermsChart'

interface Props {
  chartData?: (string | number)[][]
}

const stakingTermsLegends = [
  {
    title: StakingTermsLabel[StakingPeriod.MONTH_3],
    color: '#E7ECF6',
  },
  {
    title: StakingTermsLabel[StakingPeriod.MONTH_6],
    color: '#91A7D4',
  },
  {
    title: StakingTermsLabel[StakingPeriod.MONTH_12],
    color: '#5674B3',
  },
]

function StakingTermsCirculationChart({ chartData }: Props) {
  const chartOptions = createStakingTermsChartOptions()

  const renderLegend = useMemo(() => {
    return (
      <Box
        display="flex"
        gap={4}
        sx={{
          color: '#5B5B5B',
          fontSize: '12px',
          position: 'absolute',
          bottom: '0',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {stakingTermsLegends.map(legend => (
          <Box alignItems="center" display="flex">
            <LensIcon sx={{ fontSize: '10px', fill: legend.color, mr: 0.5 }} />
            {legend.title}
          </Box>
        ))}
      </Box>
    )
  }, [])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {chartData?.length ? (
        <Box height="100%" position="relative" width="100%">
          <Chart
            chartType="PieChart"
            data={chartData}
            height="100%"
            options={{
              ...chartOptions,
              colors: stakingTermsLegends.map(legend => legend.color),
            }}
          />
          {renderLegend}
        </Box>
      ) : (
        <EmptyChartPlaceholder />
      )}
    </Box>
  )
}

export default StakingTermsCirculationChart
