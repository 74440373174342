import React, { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { ModalWrapper } from 'common'
import {
  VerificationCodeForm,
  VerificationOptions,
} from 'common/form/verificationForm'
import { AlertMessage } from 'common/types'
import { AuthVerification } from 'common/types/verification'
import { useAppContext } from 'context/AppContext'
import { DISABLE_TOTP_VERIFICATION } from 'graphql/auth/mutations'
import { useStepper } from 'hooks'
import { getErrorMessage } from 'utils/Error'

interface Props {
  open: boolean
  onAlert: (alert: AlertMessage) => void
  onClose: () => void
}

function DisconnectTOTPAuth({ open, onClose, onAlert }: Props) {
  const { refetchMeAdmin } = useAppContext()
  const { step, next, prev, reset } = useStepper([1, 2])
  const [verification, setVerification] = useState<AuthVerification>()

  const [disableTOTPVerification, { loading }] = useMutation(
    DISABLE_TOTP_VERIFICATION,
  )

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const handleCheckOption = useCallback(
    (option: AuthVerification) => {
      setVerification(option)
      next()
    },
    [next],
  )
  const handleDisableTOTPVerification = useCallback(
    async (code: string) => {
      try {
        await disableTOTPVerification({
          variables: {
            code,
            verification,
          },
        })

        onAlert({
          isOpen: true,
          text: 'TOTP Verification has been successfully disabled',
          alertColor: 'success',
        })

        await refetchMeAdmin?.()
        handleClose()
      } catch (error) {
        onAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [
      disableTOTPVerification,
      handleClose,
      onAlert,
      refetchMeAdmin,
      verification,
    ],
  )

  const renderContent = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <VerificationOptions
            title={'Attempting to Disable Two-Factor Authentication'}
            onCheckOption={handleCheckOption}
            onPrevStep={handleClose}
          />
        )
      case 2:
        return (
          <VerificationCodeForm
            authVerificationOption={verification}
            loading={loading}
            onFinish={handleDisableTOTPVerification}
            onPrevStep={prev}
          />
        )
      default:
        return ''
    }
  }, [
    handleCheckOption,
    handleClose,
    handleDisableTOTPVerification,
    loading,
    prev,
    step,
    verification,
  ])

  return (
    <ModalWrapper open={open} onClose={handleClose}>
      {renderContent}
    </ModalWrapper>
  )
}

export default DisconnectTOTPAuth
