import { ApolloCache } from '@apollo/client'
import { Phase } from 'common/types'

import { uniqBy } from 'lodash'

import { PHASE_FIELDS } from './fragments/phase'

export function updatePhaseQueryUpdater(
  cache: ApolloCache<any>,
  phase?: Phase,
) {
  if (!phase) return

  cache.updateFragment(
    {
      id: `Phase:${phase.id}`,
      fragment: PHASE_FIELDS,
    },
    (data: any) => ({
      ...data,
      ...phase,
    }),
  )
}

export function addPhaseToPhaseQueryUpdater(
  cache: ApolloCache<any>,
  phase?: Phase,
) {
  if (!phase) return
  const id = `Phase:${phase.id}`

  cache.writeFragment({
    id,
    fragment: PHASE_FIELDS,
    fragmentName: 'PhaseFields',
    data: phase,
  })

  cache.modify({
    fields: {
      phases(existingPhases = []) {
        return uniqBy([{ phase, __ref: id }, ...existingPhases], '__ref')
      },
    },
  })
}
