import React from 'react'

import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'

import { ButtonWrapper } from './styles'

interface Props {
  loading: boolean
  open: boolean
  onConfirm: () => void
  onClose: () => void
}

function KycConfirmModal({ open, onClose, onConfirm, loading }: Props) {
  return (
    <ModalWrapper
      open={open}
      title={`Failed Verification Reset`}
      onClose={onClose}
    >
      <Typography sx={{ textAlign: 'justify' }}>
        Are you certain you wish to start the process of resetting failed
        verifications?
      </Typography>
      <Typography sx={{ textAlign: 'justify' }}>
        Please be aware that this action is irreversible.
      </Typography>
      <Typography sx={{ textAlign: 'justify' }}>
        After confirmation, the process may take some time. We recommend
        refreshing the page after approximately 15 minutes to check the results.
      </Typography>
      <Typography sx={{ textAlign: 'justify', mb: 2 }}>
        Press &apos;Confirm&apos; to proceed.
      </Typography>
      <ButtonWrapper>
        <Button color="success" variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          color="error"
          disabled={loading}
          variant="outlined"
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default KycConfirmModal
