import React, { useCallback, useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, CircularProgress, Typography } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ModalWrapper } from 'common'
import { ExternalAPIClient, Range } from 'common/types'
import { DEFAULT_DATE_FORMAT } from 'constants/params'
import { EXTERNAL_API_CLIENT_STATS } from 'graphql/externalApiClients/queries'
import { DateTime } from 'luxon'
import { LoadingHolder } from 'pages/overview/charts/styles'
import {
  createClientStatsChartOptions,
  generateClientStatsChartData,
} from 'utils/ExternalAPIClientChartOptions'

import map from 'lodash/map'

interface Props {
  open: boolean
  onClose?: () => void
  entity?: ExternalAPIClient | null
}

const DEFAULT_RANGE = {
  from: DateTime.now().minus({ month: 1, day: 1 }),
  to: DateTime.now().minus({ day: 1 }),
}

function StatsExternalAPIClientModal({ open, onClose, entity }: Props) {
  const [range, setRange] = useState<Range>(DEFAULT_RANGE)

  const { loading, data } = useQuery(EXTERNAL_API_CLIENT_STATS, {
    variables: {
      externalApiClientId: entity?.id,
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
    context: {
      validate: true,
    },
    skip: !entity?.id,
  })

  const xTicks = useMemo(
    () => map(data?.externalAPIClientStats, point => new Date(point.date)),
    [data],
  )

  const options = createClientStatsChartOptions(xTicks)

  const statsData = useMemo(() => {
    if (data?.externalAPIClientStats?.length) {
      return generateClientStatsChartData(data?.externalAPIClientStats)
    }
    return []
  }, [data])

  const handleFrom = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, from: date }))
  }, [])

  const handleTo = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, to: date }))
  }, [])

  const handleClose = useCallback(() => {
    setRange(DEFAULT_RANGE)
    onClose?.()
  }, [onClose])

  const legend = useMemo(() => {
    return (
      <Box
        display="flex"
        sx={{
          color: '#5B5B5B',
          fontSize: '12px',
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      >
        <Box alignItems="center" display="flex" mr={4}>
          <CircleIcon
            sx={{
              width: '12px',
              fill: '#91A7D4',
            }}
          />
          Success
        </Box>
        <Box alignItems="center" display="flex">
          <CircleIcon
            sx={{
              width: '12px',
              fill: '#D92E35',
            }}
          />
          Failed
        </Box>
      </Box>
    )
  }, [])

  return (
    <ModalWrapper
      open={open}
      style={{ width: '600px' }}
      title={'Client Stats'}
      onClose={handleClose}
    >
      <Box alignItems="center" display="flex" justifyContent="end" my={2}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="From"
            shouldDisableDate={day => (range?.to ? day > range?.to : false)}
            slotProps={{
              textField: {
                size: 'small',
                sx: { width: '130px' },
                variant: 'standard',
              },
            }}
            value={range?.from}
            onChange={handleFrom}
          />
          <Box mx={0.5}>-</Box>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="To"
            shouldDisableDate={day => (range?.from ? day < range?.from : false)}
            slotProps={{
              textField: {
                size: 'small',
                sx: { width: '130px' },
                variant: 'standard',
              },
            }}
            value={range?.to}
            onChange={handleTo}
          />
        </LocalizationProvider>
      </Box>

      <Box height="250px">
        {loading ? (
          <LoadingHolder>
            <CircularProgress size={20} />
          </LoadingHolder>
        ) : (
          <Box display="flex" flexDirection="column" height="100%">
            {statsData.length ? (
              <Box height="100%" position="relative" width="100%">
                {legend}
                <Chart
                  chartType="ColumnChart"
                  data={statsData}
                  height="100%"
                  options={options}
                />
              </Box>
            ) : (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
              >
                <Typography
                  color={'colors.font.default'}
                  fontSize={16}
                  fontWeight={500}
                >
                  No data
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ModalWrapper>
  )
}

export default StatsExternalAPIClientModal
