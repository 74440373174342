const STORAGE_KEY = 'refreshToken'
export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(STORAGE_KEY, refreshToken)
}

export function getRefreshToken() {
  return localStorage.getItem(STORAGE_KEY)
}

export function removeRefreshToken() {
  localStorage.removeItem(STORAGE_KEY)
}
