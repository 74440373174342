import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'
import theme from 'theme'

export const ContentWrapper = styled(Box)({
  width: 'calc(100% - 175px)',
  display: 'flex',
  flexDirection: 'column',
})

export const StyledTopBar = styled(Box)({
  padding: '15px 30px',
  display: 'flex',
  minHeight: '80px',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const StyledButton = styled(Button)({
  background: theme.palette.primary.main,
  color: 'white',
  height: '30px',
  '&:hover': {
    background: theme.palette.primary.main,
    opacity: '0.6',
  },
})

export const Title = styled(Box)({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 700,
})
