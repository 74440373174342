import React from 'react'

export interface HeadCell {
  id: string
  label: React.ReactNode
  sortable?: boolean
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

export enum SortInputOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export interface SortInput {
  column: string
  order: SortInputOrder
}
