import React from 'react'

import { Tooltip, TooltipProps } from '@mui/material'

interface Styles {
  arrow: object
  tooltip: object
}

const styles: Styles = {
  arrow: {
    color: '#EBEBEB',
    '&::before': {
      backgroundColor: '#EBEBEB',
      border: '1px solid #EBEBEB',
    },
  },
  tooltip: {
    backgroundColor: '#EBEBEB',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
}

function DomainsTooltip({ className, children, ...rest }: TooltipProps) {
  return (
    <Tooltip
      {...rest}
      classes={{ popper: className }}
      componentsProps={{
        arrow: {
          sx: styles.arrow,
        },
        tooltip: {
          sx: styles.tooltip,
        },
      }}
    >
      {children}
    </Tooltip>
  )
}

export default DomainsTooltip
