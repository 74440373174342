import React, { MouseEvent } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/system'
import { Provider } from 'common/types'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { getFormattedDate } from 'utils/Date'

type Props = {
  count: number
  data?: Provider[]
  onView: (rowData: Provider) => void
  onEdit: (rowData: Provider) => void
  onDelete: (rowData: Provider) => void
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function BasicTable({
  data = [],
  onDelete,
  onEdit,
  onView,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>
                  <TableCell component="th" scope="row">
                    {row?.name}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" width="100%">
                      <InfoIcon
                        className="itemIcon"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onView(row)}
                      />
                      <EditIcon
                        className="itemIcon"
                        sx={{ ml: '10px', cursor: 'pointer' }}
                        onClick={() => onEdit(row)}
                      />
                      <ClearIcon
                        className="itemIcon"
                        sx={{ ml: '10px', cursor: 'pointer' }}
                        onClick={() => onDelete(row)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
