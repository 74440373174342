import React, { MouseEvent } from 'react'

import CircleIcon from '@mui/icons-material/Circle'
import LinkSharpIcon from '@mui/icons-material/LinkSharp'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box, TablePagination, Tooltip, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from 'common/styles'
import { Query } from 'common/types'
import { WEB3 } from 'config'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { truncateAddress } from 'constants/truncateAddress'
import { getFormattedDate } from 'utils/Date'

type Props = {
  count: number
  data?: Query[]
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function BasicTable({
  data = [],
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Provider</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Tracking code</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Validations</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              Validations left
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              Success validations
            </TableCell>
            <TableCell>Client</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Created At</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Processed At</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              Transaction hash
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell scope="row">{row?.provider?.name}</TableCell>
                  <TableCell scope="row">{row?.trackingCode}</TableCell>
                  <TableCell>{row?.queryUrl}</TableCell>
                  <TableCell scope="row">{row?.validationsCount}</TableCell>
                  <TableCell>{row?.validationsLeft}</TableCell>
                  <TableCell>{row?.successValidationsCount}</TableCell>
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                      gap={1}
                      maxWidth="150px"
                    >
                      <CircleIcon
                        color={
                          row?.externalApiClient?.enabled
                            ? 'success'
                            : 'disabled'
                        }
                        sx={{
                          width: '12px',
                        }}
                      />
                      <Typography
                        fontSize="inherit"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {row?.externalApiClient?.name}
                      </Typography>
                      {row?.externalApiClient?.isSystem && (
                        <Tooltip arrow placement="top" title={'System key'}>
                          <LockOutlinedIcon
                            color="primary"
                            sx={{ mr: 1, fontSize: '16px' }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>
                  <TableCell>{getFormattedDate(row?.processedAt)}</TableCell>

                  <TableCell scope="row">
                    <Box alignItems="center" display="flex">
                      <Box mr={2}>{truncateAddress(row?.transactionHash)}</Box>
                      <Link
                        href={`${WEB3.POLYGONSCAN_URL}/tx/${row?.transactionHash}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <LinkSharpIcon />
                      </Link>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
