import { gql } from '@apollo/client'

import { EXTERNAL_API_CLIENT_FIELDS } from './fragments'

export const CREATE_EXTERNAL_API_CLIENT = gql`
  mutation CreateExternalAPIClient(
    $domains: [String!]
    $enabled: Boolean!
    $name: String!
  ) {
    createExternalAPIClient(domains: $domains, enabled: $enabled, name: $name) {
      ...ExternalAPIClientFields
    }
  }
  ${EXTERNAL_API_CLIENT_FIELDS}
`

export const UPDATE_EXTERNAL_API_CLIENT = gql`
  mutation UpdateExternalAPIClient(
    $id: ID!
    $domains: [String!]
    $enabled: Boolean!
    $name: String!
  ) {
    updateExternalAPIClient(
      id: $id
      domains: $domains
      enabled: $enabled
      name: $name
    ) {
      ...ExternalAPIClientFields
    }
  }
  ${EXTERNAL_API_CLIENT_FIELDS}
`
