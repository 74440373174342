import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chart, GoogleChartWrapper } from 'react-google-charts'

import { useLazyQuery } from '@apollo/client'
import { Box, CircularProgress } from '@mui/material'
import { LoaderHolder, ModalWrapper } from 'common'
import { LOW_UPTIME_LINE, WARNING_UPTIME_LINE } from 'constants/params'
import { NODE_UPTIME_DATA } from 'graphql/nodes/queries'
import { Duration } from 'luxon'
import { createUptimeChartOptions } from 'utils/CreateChartOptions'
import { generateUptimeChartData } from 'utils/UptimeChart'

import forEach from 'lodash/forEach'

import {
  Amount,
  ChartWrapper,
  GridLimitsContainer,
  GridTitle,
  Text,
} from './styles'

const gridlinesColors = ['#E93940', '#FFB406', '#26B568']

interface Props {
  nodeId: string | null
  open: boolean
  onClose: () => void
}

function UptimeModal({ open, onClose, nodeId }: Props) {
  const [uptimeContainerId, setUptimeContainerId] = useState<string>('')

  const [loadNodeUptime, { data, loading }] = useLazyQuery(NODE_UPTIME_DATA)

  const uptimeChartOptions = createUptimeChartOptions()

  const fetchUptimeData = useCallback(
    (nodeId: string) => loadNodeUptime({ variables: { nodeId } }),
    [loadNodeUptime],
  )

  useEffect(() => {
    if (nodeId) {
      fetchUptimeData(nodeId).then()
    }
  }, [fetchUptimeData, nodeId])

  const uptimeChartData = useMemo(() => {
    if (data?.nodeUptime?.items) {
      return generateUptimeChartData(data?.nodeUptime?.items)
    }
    return []
  }, [data])

  const totalPercentage = useMemo(() => data?.nodeUptime?.total || 0, [data])

  const uptime = useMemo(
    () =>
      data?.nodeUptime?.totalTime
        ? Duration.fromISO(data?.nodeUptime?.totalTime).toHuman({
            unitDisplay: 'short',
          })
        : '',
    [data],
  )
  const handleChartWrapper = (chartWrapper: GoogleChartWrapper) => {
    const containerId = chartWrapper?.getContainerId()
    setUptimeContainerId(containerId)
  }

  const handleUptimeChart = useCallback((uptimeContainerId: string) => {
    const container = document.getElementById(uptimeContainerId)
    let gridlineIndex = 0

    forEach(container?.getElementsByTagName('rect'), rect => {
      if (rect.getAttribute('height') === '1') {
        rect.setAttribute('fill-opacity', '0.2')
        rect.setAttribute('fill', gridlinesColors[gridlineIndex])
        gridlineIndex = +1
      }
    })
  }, [])

  useEffect(() => {
    if (uptimeContainerId) {
      handleUptimeChart(uptimeContainerId)
    }
  }, [handleUptimeChart, uptimeContainerId])

  const totalPercentageColor = useMemo(() => {
    if (totalPercentage >= WARNING_UPTIME_LINE) {
      return gridlinesColors[2]
    }
    if (
      totalPercentage < WARNING_UPTIME_LINE &&
      totalPercentage >= LOW_UPTIME_LINE
    ) {
      return gridlinesColors[1]
    }
    return gridlinesColors[0]
  }, [totalPercentage])

  return (
    <ModalWrapper
      open={open}
      style={{ maxWidth: '400px' }}
      title={'Uptime'}
      onClose={() => {
        onClose()
      }}
    >
      {loading ? (
        <LoaderHolder>
          <CircularProgress />
        </LoaderHolder>
      ) : (
        <Box width="100%">
          <Amount color={totalPercentageColor}>{totalPercentage}%</Amount>
          <Text mb={3} mt={1}>
            {uptime}
          </Text>
          {uptimeChartData?.length > 2 ? (
            <ChartWrapper>
              <GridLimitsContainer>
                <GridTitle color={gridlinesColors[1]} mb="28px">
                  {WARNING_UPTIME_LINE}%
                </GridTitle>
                <GridTitle color={gridlinesColors[0]}>
                  {LOW_UPTIME_LINE}%
                </GridTitle>
              </GridLimitsContainer>
              <Chart
                chartType="AreaChart"
                data={uptimeChartData}
                getChartWrapper={handleChartWrapper}
                height="100%"
                options={uptimeChartOptions}
              />
            </ChartWrapper>
          ) : (
            <Text>Data will be available in a day</Text>
          )}
        </Box>
      )}
    </ModalWrapper>
  )
}

export default UptimeModal
