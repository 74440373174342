import { getEnv } from 'services/Envs'

const apiProtocol = getEnv('API_SSL') === 'true' ? 'https' : 'http'
const socketProtocol = getEnv('API_SSL') === 'true' ? 'wss' : 'ws'

const API_URL = `${apiProtocol}://${getEnv('API_URL')}`
const VALIDATE_API_URL = `${apiProtocol}://${getEnv('VALIDATE_API_URL')}`
export const WEB3 = {
  POLYGONSCAN_URL: getEnv('POLYGONSCAN_URL'),
}
export const API = {
  URL: API_URL,
  WS: `${socketProtocol}://${getEnv('API_URL')}`,
  PROXY: `${API_URL}/proxy/`,
}

export const VALIDATE_API = {
  URL: VALIDATE_API_URL,
}

export const APP_ENV = getEnv('ENV')

export const IS_STAGING = APP_ENV === 'staging'
