import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

export const ErrorBox = styled(Typography)({
  minHeight: '15px',
  fontSize: '12px',
})

export const LabelBox = styled(Box)({
  fontSize: '12px',
})

export const InputWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})
