import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, Snackbar } from '@mui/material'
import { Box } from '@mui/system'
import { Alert, SearchInput } from 'common'
import PageWrapper from 'common/pageWrapper'
import { ProvidersTable } from 'common/tables'
import { AlertMessage, Provider, TQP } from 'common/types'
import {
  ALERT_DELAY,
  DEFAULT_SORT,
  ROWS_PER_PAGE_DEFAULT,
} from 'constants/params'
import { DELETE_PROVIDER } from 'graphql/providers/mutations'
import { GET_PROVIDERS } from 'graphql/providers/queries'
import useTableSearch from 'hooks/useTableSearch'
import { getErrorMessage } from 'utils/Error'

import CreateModal from './modals/createModal'
import DeleteModal from './modals/deleteModal'
import ViewProvider from './modals/viewModal'

const params: TQP = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  search: '',
  order: DEFAULT_SORT,
}

function Providers() {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)
  const [createModal, setCreateModal] = useState<{
    isOpen: boolean
    entity: Provider | null
  }>({
    isOpen: false,
    entity: null,
  })
  const [viewModal, setViewModal] = useState<{
    isOpen: boolean
    entity: Provider | null
  }>({
    isOpen: false,
    entity: null,
  })
  const [deleteModal, setDeleteModal] = useState<{
    isOpen: boolean
    entity: Provider | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const { loading, data, refetch } = useQuery(GET_PROVIDERS, {
    variables: { ...params },
  })

  const [deleteProvider] = useMutation(DELETE_PROVIDER)

  const providersData: Provider[] = useMemo(
    () => data?.getProviders?.data || [],
    [data],
  )

  const providersMeta = useMemo(() => data?.getProviders?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        page: 1,
      })
      setCurrentPage(0)
    },
  })

  const handleCloseCreateModal = useCallback(
    () => setCreateModal({ isOpen: false, entity: null }),
    [],
  )

  const handleOpenCreateModal = useCallback(
    () => setCreateModal({ isOpen: true, entity: null }),
    [],
  )

  const handleOpenEditModal = useCallback(
    (entity: Provider) => setCreateModal({ isOpen: true, entity }),
    [],
  )

  const handleCloseViewModal = useCallback(() => {
    setViewModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenViewModal = useCallback((entity: Provider) => {
    setViewModal({ isOpen: true, entity })
  }, [])

  const handleOpenDeleteModal = useCallback((entity: Provider) => {
    setDeleteModal({ isOpen: true, entity })
  }, [])

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ isOpen: false, entity: null })
  }, [])

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ take: rowsPerPage, page: page + 1, search })
      setCurrentPage(page)
    },
    [refetch, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [refetch, search],
  )

  const handleDelete = useCallback(
    async (providerId: string) => {
      try {
        await deleteProvider({
          variables: {
            deleteProviderId: providerId,
          },
          refetchQueries: [
            {
              query: GET_PROVIDERS,
              variables: {
                ...params,
                take: rowsPerPage,
                search,
              },
            },
          ],
        })

        setAlert({
          isOpen: true,
          text: 'Provider successfully removed',
          alertColor: 'success',
        })
        handleCloseDeleteModal()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
        handleCloseDeleteModal()
      }
    },
    [deleteProvider, handleCloseDeleteModal, rowsPerPage, search],
  )

  return (
    <>
      <PageWrapper
        buttonText="Create provider"
        pageTitle="Providers"
        onClick={handleOpenCreateModal}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          sx={{ mb: '10px', mx: '30px' }}
        >
          <Box sx={{ my: '10px' }}>
            <SearchInput
              endAdornment={<SearchIcon />}
              placeholder="Search"
              value={search}
              onChange={handleChangeSearch}
            />
          </Box>
          {loading ? (
            <Box
              alignItems="center"
              display="flex"
              height={1}
              justifyContent="center"
              width={1}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ProvidersTable
              count={providersMeta?.itemCount ?? 0}
              data={providersData}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onDelete={handleOpenDeleteModal}
              onEdit={handleOpenEditModal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onView={handleOpenViewModal}
            />
          )}
        </Box>
        <Snackbar
          autoHideDuration={ALERT_DELAY}
          open={alert?.isOpen}
          onClose={() => setAlert({ isOpen: false })}
        >
          <Alert
            severity={alert?.alertColor}
            sx={{ width: '100%' }}
            onClose={() => setAlert({ isOpen: false })}
          >
            {alert?.text}
          </Alert>
        </Snackbar>
      </PageWrapper>
      {viewModal?.entity && (
        <ViewProvider
          open={viewModal?.isOpen}
          providerData={{
            id: viewModal?.entity?.id,
            name: viewModal?.entity?.name,
            createdAt: viewModal?.entity?.createdAt,
            dailyQueriesCap: viewModal?.entity?.dailyQueriesCap,
            queryConfirmationsCap: viewModal?.entity?.queryConfirmationsCap,
          }}
          onClose={handleCloseViewModal}
        />
      )}
      <CreateModal
        entity={createModal?.entity}
        open={createModal?.isOpen}
        onClose={handleCloseCreateModal}
      />

      {deleteModal?.entity && (
        <DeleteModal
          open={deleteModal?.isOpen}
          provider={{
            id: deleteModal?.entity?.id,
            name: deleteModal?.entity?.name,
          }}
          onClose={handleCloseDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </>
  )
}

export default Providers
