import React, { useCallback, useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import { getXTicks } from 'utils/Charts'
import {
  createTokenPriceChartOptions,
  generateTokenPriceChartData,
} from 'utils/TokenPriceChart'

export enum TokenPriceChartKind {
  TVL = 'TVL',
  PRICE = 'Price',
}
function TokenPriceChart() {
  const [chartKind, setChartKind] = useState(TokenPriceChartKind.PRICE)
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const tokenPrice = useMemo(
    () => data?.systemDailyStatsGraphData?.tokenPrice || [],
    [data],
  )

  const totalLockedAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.totalLockedAmount || [],
    [data],
  )

  const xTicks = useMemo(() => {
    if (chartKind === TokenPriceChartKind.PRICE) {
      return getXTicks(tokenPrice)
    }
    return getXTicks(totalLockedAmount)
  }, [chartKind, tokenPrice, totalLockedAmount])

  const startHAxis = useMemo(() => {
    if (chartKind === TokenPriceChartKind.PRICE) {
      return data?.systemDailyStatsGraphData?.tokenPrice[0]?.day
    }
    return data?.systemDailyStatsGraphData?.totalLockedAmount[0]?.day
  }, [chartKind, data])

  const chartOptions = createTokenPriceChartOptions(xTicks, startHAxis)

  const statsChartData = useMemo(
    () => generateTokenPriceChartData(chartKind, tokenPrice, totalLockedAmount),
    [tokenPrice, totalLockedAmount, chartKind],
  )

  const chartColors = useMemo(() => {
    if (chartKind === TokenPriceChartKind.PRICE) {
      return ['#91A7D4']
    }

    return ['#26B568']
  }, [chartKind])

  const handleChangeChartKind = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChartKind(
        (event.target as HTMLInputElement).value as TokenPriceChartKind,
      )
    },
    [],
  )

  const noChartData = useMemo(() => {
    if (chartKind === TokenPriceChartKind.PRICE) {
      return tokenPrice?.length < 2
    }
    return totalLockedAmount?.length < 2
  }, [chartKind, tokenPrice, totalLockedAmount])

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <RadioGroup row value={chartKind} onChange={handleChangeChartKind}>
          <FormControlLabel
            control={<Radio size="small" />}
            label="Price of 1 MNW Token"
            value={TokenPriceChartKind.PRICE}
          />
          <FormControlLabel
            control={<Radio size="small" />}
            label="TVL"
            value={TokenPriceChartKind.TVL}
          />
        </RadioGroup>
        <Box alignItems="center" display="flex" justifyContent="end">
          <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={{ ...chartOptions, colors: chartColors }}
          />
        )}
      </Box>
    </>
  )
}

export default TokenPriceChart
