import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'
import { AuthVerification } from 'common/types/verification'

import { VerificationOptionWrapper } from './styles'

interface Props {
  onPrevStep?: () => void
  onCheckOption: (option: AuthVerification) => void
  title: string
}

function VerificationOptions({ onCheckOption, title, onPrevStep }: Props) {
  return (
    <Box>
      <Typography fontWeight={600} mb={2} textAlign="center">
        2-Step Verification
      </Typography>
      <Typography>{title}</Typography>
      <Typography fontWeight={600} mb={1} mt={3}>
        Choose how you want to do it:
      </Typography>
      <VerificationOptionWrapper
        onClick={() => onCheckOption(AuthVerification.TOTP)}
      >
        <QrCodeScannerIcon color="primary" />
        <Typography>
          Get a verification code from the Google Authenticator app
        </Typography>
      </VerificationOptionWrapper>
      <VerificationOptionWrapper
        onClick={() => onCheckOption(AuthVerification.BACKUPCODE)}
      >
        <LockIcon color="primary" />
        <Typography>Enter one of your 8-digit backup codes</Typography>
      </VerificationOptionWrapper>
      {onPrevStep && (
        <Box>
          {' '}
          <Button
            color="primary"
            fullWidth
            sx={{ mt: '30px' }}
            variant="outlined"
            onClick={onPrevStep}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default VerificationOptions
