import React from 'react'

import { Box, TableSortLabel } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import { HeadCell, SortInput, SortInputOrder } from './types'

type Props = {
  headerCells: HeadCell[]
  sort?: SortInput
  onChangeSort?: (property: string) => void
}
function TableHeader({ sort, onChangeSort, headerCells }: Props) {
  return (
    <TableHead>
      <TableRow>
        {headerCells.map(headCell => (
          <TableCell
            align={headCell?.align}
            key={headCell.id}
            sortDirection={sort?.column === headCell.id ? sort?.order : false}
          >
            {headCell?.sortable ? (
              <TableSortLabel
                active={sort?.column === headCell.id}
                direction={
                  sort?.column === headCell.id
                    ? sort?.order
                    : SortInputOrder.Asc
                }
                onClick={() => onChangeSort?.(headCell.id)}
              >
                <Typography fontSize="inherit" whiteSpace="nowrap">
                  {headCell.label}
                </Typography>
                {sort?.column === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sort?.order === SortInputOrder.Desc
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography fontSize="inherit" whiteSpace="nowrap">
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
export default TableHeader
