import React, { PropsWithChildren } from 'react'

import { FormWrapper, PageWrapper } from './styles'

const LoginFormLayout: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <PageWrapper>
      <FormWrapper>{children}</FormWrapper>
    </PageWrapper>
  )
}
export default LoginFormLayout
