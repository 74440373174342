import React from 'react'

import { Container } from './styles'

import { Subtitle, Text } from '../styles'

interface Props {
  averageTransactionTime: number
  confirmedTransactionsPercent: number
  pendingQueries: number
  processedQueries: number
  totalQueries: number
  totalValidation: number
  totalReceived: number
}

function Average({
  averageTransactionTime = 0,
  confirmedTransactionsPercent = 0,
  pendingQueries = 0,
  processedQueries = 0,
  totalQueries = 0,
  totalValidation = 0,
  totalReceived = 0,
}: Props) {
  return (
    <>
      <Container px={2} py={2}>
        <Subtitle>Total queries</Subtitle>
        <Text title={`${totalQueries} queries`}>{totalQueries}</Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Queries processed / requested</Subtitle>
        <Text title={`${processedQueries} / ${pendingQueries}`}>
          {processedQueries} / {pendingQueries}
        </Text>
      </Container>

      <Container px={2} py={2}>
        <Subtitle>Transactions processed / requested</Subtitle>
        <Text title={`${totalReceived} / ${totalValidation}`}>
          {totalValidation} / {totalReceived}
        </Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Average transaction processing time by Node</Subtitle>
        <Text title={`${averageTransactionTime?.toFixed()}}ms`}>
          {averageTransactionTime?.toFixed()}ms
        </Text>
      </Container>
      <Container px={2} py={2}>
        <Subtitle>Confirmed transactions progress</Subtitle>
        <Text title={`${confirmedTransactionsPercent?.toFixed(2)}%`}>
          {confirmedTransactionsPercent?.toFixed(2)}%
        </Text>
      </Container>
    </>
  )
}

export default Average
