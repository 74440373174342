import { gql } from '@apollo/client'

export const NODES = gql`
  query Nodes(
    $search: String
    $page: Int
    $take: Int
    $filter: Object
    $validatorId: ID
    $order: String
    $from: DateTime
    $to: DateTime
    $rewardStatus: NodeRewardStatus
    $period: StakingPeriod
    $uptimeFrom: Int
    $uptimeTo: Int
  ) {
    nodes(
      search: $search
      page: $page
      take: $take
      filter: $filter
      validatorId: $validatorId
      order: $order
      from: $from
      to: $to
      rewardStatus: $rewardStatus
      period: $period
      uptimeFrom: $uptimeFrom
      uptimeTo: $uptimeTo
    ) {
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
      data {
        id
        active
        address
        createdAt
        closesAt
        lastActivity
        stakedAmount
        workers
        totalUptime
        status
        transactionHash
        updatedAt
        validatorId
        period
        receivedAmount
        validationAmount
        penalty
        unstakesAt
        projectedRewards {
          earning
          perDay
          apy
        }
        earnedRewards {
          earning
          perDay
          apy
        }
        reward
        earlyUnstaked
        nodeInfo {
          platform
          type
          version
        }
        validator {
          email
          wallet
        }
      }
    }
  }
`

export const NODE_UPTIME_DATA = gql`
  query NodeUptime($nodeId: String!) {
    nodeUptime(nodeId: $nodeId) {
      items {
        key
        value
      }
      total
      totalTime
    }
  }
`
