import React, { useCallback, useMemo, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { Node, StakingTermsLabel } from 'common/types'
import { LOW_UPTIME_LINE, WARNING_UPTIME_LINE } from 'constants/params'
import getPlatform from 'constants/platform'
import { truncateAddress } from 'constants/truncateAddress'
import { NodeStatus } from 'pages/nodes/types'
import { getFormattedDate } from 'utils/Date'

import ValidationsTable from '../validationsTable'

interface Props {
  row: Node
  onCopyAddress?: () => void
  onOpenUptimeModal: (nodeId: string) => void
}

function NodeRow({ row, onCopyAddress, onOpenUptimeModal }: Props) {
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false)

  const appVersion = useMemo(() => {
    const { platform, version, type } = row.nodeInfo
    return platform && version && type
      ? `${getPlatform(platform)} ${version} (${
          type === 'desktop' ? 'Desktop' : 'CLI'
        })`
      : '--'
  }, [row])

  const getUptimeColor = useCallback((totalUptime: number) => {
    if (totalUptime >= WARNING_UPTIME_LINE) {
      return '#26B568'
    }
    if (totalUptime < WARNING_UPTIME_LINE && totalUptime >= LOW_UPTIME_LINE) {
      return '#FFB406'
    }
    return '#E93940'
  }, [])

  const penaltyMessage = useMemo(() => {
    if (row?.status === NodeStatus.ENABLED) return ''
    if (row?.earlyUnstaked || row?.totalUptime >= 99) {
      return 'Node was early unstaked'
    }
    if (row?.totalUptime < 99) {
      return 'Node Uptime Below 99%'
    }
    return ''
  }, [row])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          >
            {isCollapseOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Box alignItems="center" display="flex">
            {truncateAddress(row.address)}
            {row.address && (
              <Tooltip arrow placement="top" title="Copy address to clipboard">
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => onCopyAddress?.()}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell align="left"> {StakingTermsLabel[row.period]}</TableCell>
        <TableCell align="left"> {getFormattedDate(row.createdAt)}</TableCell>
        <TableCell align="left"> {getFormattedDate(row.closesAt)}</TableCell>
        <TableCell align="left">{row.stakedAmount}</TableCell>
        <TableCell align="left">
          <Box alignItems="center" display="flex" justifyContent="start">
            <Typography
              color={row?.penalty ? 'error' : 'inherit'}
              fontSize="inherit"
            >
              {row?.penalty?.toFixed(2)}
            </Typography>
            {penaltyMessage && !!row?.penalty && (
              <Tooltip sx={{ mt: '5px' }} title={penaltyMessage}>
                <InfoIcon style={{ height: '18px', marginLeft: '8px' }} />
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">{row?.validationAmount || 0}</TableCell>
        <TableCell align="left">
          {row?.earnedRewards?.earning?.toFixed(2) || 0}
        </TableCell>
        <TableCell align="center">
          <Box alignItems="center" display="flex" justifyContent="start">
            {row?.totalUptime < LOW_UPTIME_LINE && (
              <Tooltip
                sx={{ mt: '5px' }}
                title={`Node went below uptime of ${LOW_UPTIME_LINE}%`}
              >
                <ReportProblemIcon
                  color="error"
                  sx={{ height: '18px', mr: 0.5 }}
                />
              </Tooltip>
            )}
            <Typography
              fontSize="inherit"
              sx={{ color: getUptimeColor(row?.totalUptime) }}
            >
              {row?.totalUptime || 0}%
            </Typography>
            {row?.totalUptime ? (
              <ReadMoreIcon
                sx={{ height: '18px', ml: 0.5, cursor: 'pointer' }}
                onClick={() => onOpenUptimeModal(row.id)}
              />
            ) : null}
          </Box>
        </TableCell>
        <TableCell align="left">{appVersion}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">
          <FiberManualRecordSharpIcon
            style={{ width: 14, fill: row.active ? 'green' : 'red' }}
          />
          {row.active ?? '—'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={13}
          style={{
            padding: 0,
            backgroundColor: '#f1f1f1',
          }}
        >
          <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
            <ValidationsTable nodeId={row.id} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default NodeRow
