import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { AlertColor, CircularProgress, Snackbar } from '@mui/material'
import { Alert, LoaderHolder } from 'common'
import {
  CreateExternalAPIClientModal,
  DisableExternalAPIClientModal,
  StatsExternalAPIClientModal,
} from 'common/modals'
import PageWrapper from 'common/pageWrapper'
import { ExternalAPIClientsTable } from 'common/tables'
import { AlertMessage, ExternalAPIClient } from 'common/types'
import { ALERT_DELAY, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { UPDATE_EXTERNAL_API_CLIENT } from 'graphql/externalApiClients/mutations'
import { EXTERNAL_API_CLIENTS_LIST } from 'graphql/externalApiClients/queries'
import { updateExternalApiClientsQuery } from 'graphql/externalApiClients/updaters'
import { getErrorMessage } from 'utils/Error'

import { Container } from './styles'

interface SortInput {
  column: string
  order: 'asc' | 'desc'
}

interface Params {
  page: number
  limit: number
  sort?: SortInput[]
}

const params: Params = {
  page: 0,
  limit: ROWS_PER_PAGE_DEFAULT,
  sort: [{ column: 'createdAt', order: 'desc' }],
}

function ExternalAPIClients() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const [confirmChangeStatusModal, setConfirmChangeStatusModal] = useState<{
    isOpen: boolean
    entity: ExternalAPIClient | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [createModal, setCreateModal] = useState<{
    isOpen: boolean
    entity: ExternalAPIClient | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [statsModal, setStatsModal] = useState<{
    isOpen: boolean
    entity: ExternalAPIClient | null
  }>({
    isOpen: false,
    entity: null,
  })

  const { data, loading, refetch } = useQuery(EXTERNAL_API_CLIENTS_LIST, {
    variables: {
      ...params,
    },
    context: {
      validate: true,
    },
  })

  const [updateExternalAPIClient, { loading: updatingLoading }] = useMutation(
    UPDATE_EXTERNAL_API_CLIENT,
  )

  const externalApiClientsListData: ExternalAPIClient[] = useMemo(
    () => data?.externalAPIClientList?.rows || [],
    [data],
  )

  const externalApiClientsListCount = useMemo(
    () => data?.externalAPIClientList?.count,
    [data],
  )

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ limit: rowsPerPage, page: page + 1 })
      setPage(page)
    },
    [refetch, rowsPerPage],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        limit: parseInt(event.target.value, 10),
        page: 1,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [refetch],
  )

  const handleCloseCreateModal = useCallback(() => {
    setCreateModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenCreateModal = useCallback(() => {
    setCreateModal({ isOpen: true, entity: null })
  }, [])

  const handleOpenConfirmChangeStatusModal = useCallback(
    (entity: ExternalAPIClient) => {
      setConfirmChangeStatusModal({ isOpen: true, entity })
    },
    [],
  )
  const handleCloseConfirmDisabledModal = useCallback(() => {
    setConfirmChangeStatusModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenStatsModal = useCallback((entity: ExternalAPIClient) => {
    setStatsModal({ isOpen: true, entity })
  }, [])
  const handleCloseStatsModal = useCallback(() => {
    setStatsModal({ isOpen: false, entity: null })
  }, [])

  const handleEditModal = useCallback((entity: ExternalAPIClient) => {
    setCreateModal({ isOpen: true, entity })
  }, [])

  const handleCloseAlert = useCallback(() => setAlert({ isOpen: false }), [])
  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  const handleDisableExternalApiClient = useCallback(
    async (entity: ExternalAPIClient | null) => {
      if (!entity) {
        setConfirmChangeStatusModal({ isOpen: false, entity: null })
        return
      }
      try {
        await updateExternalAPIClient({
          variables: { ...entity, enabled: !entity.enabled },
          context: {
            validate: true,
          },
          update(cache, { data }) {
            return updateExternalApiClientsQuery(cache, data, entity)
          },
        })
        setAlert({
          isOpen: true,
          text: 'Client was updated successfully',
          alertColor: 'success',
        })
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
      setConfirmChangeStatusModal({ isOpen: false, entity: null })
    },
    [updateExternalAPIClient],
  )

  const handleCopyKey = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey)
    setAlert({
      isOpen: true,
      text: 'The key was copied',
      alertColor: 'success',
    })
  }

  return (
    <>
      <PageWrapper
        buttonText="Create client"
        pageTitle="Clients"
        onClick={handleOpenCreateModal}
      >
        <Container>
          {loading ? (
            <LoaderHolder>
              <CircularProgress />
            </LoaderHolder>
          ) : (
            <ExternalAPIClientsTable
              count={externalApiClientsListCount ?? 0}
              data={externalApiClientsListData}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangeStatus={handleOpenConfirmChangeStatusModal}
              onCopyKey={handleCopyKey}
              onEdit={handleEditModal}
              onOpenStats={handleOpenStatsModal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Container>
      </PageWrapper>

      <CreateExternalAPIClientModal
        entity={createModal.entity}
        open={createModal.isOpen}
        onAlert={handleAlert}
        onClose={handleCloseCreateModal}
        onRefetch={refetch}
      />
      <DisableExternalAPIClientModal
        entity={confirmChangeStatusModal.entity}
        loading={updatingLoading}
        open={confirmChangeStatusModal.isOpen}
        onClose={handleCloseConfirmDisabledModal}
        onConfirm={handleDisableExternalApiClient}
      />
      <StatsExternalAPIClientModal
        entity={statsModal.entity}
        open={statsModal.isOpen}
        onClose={handleCloseStatsModal}
      />

      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={handleCloseAlert}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={handleCloseAlert}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ExternalAPIClients
