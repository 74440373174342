import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Row = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px',
})

export const Label = styled(Box)({
  fontWeight: '500',
  fontSize: '18px',
  width: '100%',
  color: '#a4a4ad',
})

export const Value = styled(Box)({
  color: '#403f54',
  fontWeight: '500',
  width: '100%',
})

export const CopyButton = styled('span')({
  cursor: 'pointer',
  color: '#000',
  margin: '0 10px 0 10px',
})
