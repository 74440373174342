import React from 'react'

import { Box } from '@mui/system'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Range } from 'common/types'
import { DEFAULT_DATE_FORMAT } from 'constants/params'
import { DateTime } from 'luxon'

type Props = {
  range?: Range
  onChangeFrom?: (date: DateTime | null) => void
  onChangeTo?: (date: DateTime | null) => void
}
const DateRange = ({ range, onChangeFrom, onChangeTo }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        format={DEFAULT_DATE_FORMAT}
        label="From"
        shouldDisableDate={day => (range?.to ? day > range?.to : false)}
        slotProps={{
          textField: {
            size: 'small',
            sx: { width: '130px' },
            variant: 'standard',
          },
        }}
        value={range?.from}
        onChange={onChangeFrom}
      />
      <Box mx={0.5}>-</Box>
      <DatePicker
        format={DEFAULT_DATE_FORMAT}
        label="To"
        shouldDisableDate={day => (range?.from ? day < range?.from : false)}
        slotProps={{
          textField: {
            size: 'small',
            sx: { width: '130px' },
            variant: 'standard',
          },
        }}
        value={range?.to}
        onChange={onChangeTo}
      />
    </LocalizationProvider>
  )
}

export default DateRange
