import React, {
  ChangeEventHandler,
  useCallback,
  useMemo,
  useState,
} from 'react'

import debounce from 'lodash/debounce'

interface Params {
  doOnSearch: Function
}

export default function useTableSearch({
  doOnSearch,
}: Params): [
  search: string,
  handleChangeSearch: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >,
] {
  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        doOnSearch(value)
      }, 700),
    [doOnSearch],
  )

  const handleChangeSearch: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    event => {
      const { value } = event.target
      debouncedSearch(value)
      setSearch(value)
    },
    [debouncedSearch],
  )

  return [search, handleChangeSearch]
}
