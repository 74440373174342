import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { SearchInput } from 'common'
import PageWrapper from 'common/pageWrapper'
import { QueriesTable } from 'common/tables'
import { Params, Query } from 'common/types'
import { DEFAULT_SORT, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { QUERIES_LIST } from 'graphql/queries/queries'
import useTableSearch from 'hooks/useTableSearch'

const params: Params = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  search: '',
  order: DEFAULT_SORT,
}

function Queries() {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)
  const { loading, data, refetch } = useQuery(QUERIES_LIST, {
    variables: { ...params },
  })

  const queriesData: Query[] = useMemo(
    () => data?.queriesList?.data || [],
    [data],
  )
  const queriesMeta = useMemo(() => data?.queriesList?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        page: 1,
      })
      setCurrentPage(0)
    },
  })

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ take: rowsPerPage, page: page + 1, search })
      setCurrentPage(page)
    },
    [refetch, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [refetch, search],
  )

  return (
    <PageWrapper pageTitle="Queries">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{ px: '30px' }}
      >
        <Box sx={{ my: '10px' }}>
          <SearchInput
            endAdornment={<SearchIcon />}
            placeholder="Search"
            value={search}
            onChange={handleChangeSearch}
          />
        </Box>
        {loading ? (
          <Box
            alignItems="center"
            display="flex"
            height={1}
            justifyContent="center"
            width={1}
          >
            <CircularProgress />
          </Box>
        ) : (
          <QueriesTable
            count={queriesMeta?.itemCount ?? 0}
            data={queriesData}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </PageWrapper>
  )
}

export default Queries
