import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '0 30px',
})

export const LoaderHolder = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})

export const Content = styled(Box)({
  width: '100%',
})
