import React, { useCallback, useState } from 'react'

import { Box } from '@mui/system'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ModalWrapper } from 'common'
import { AlertMessage, Range } from 'common/types'
import { API } from 'config'
import { DEFAULT_DATE_FORMAT } from 'constants/params'
import { DateTime } from 'luxon'
import { getToken } from 'services/token'
import { getErrorMessage } from 'utils/Error'

interface Props {
  open: boolean
  onClose: () => void
  onAlert: (alert: AlertMessage) => void
}

function ExportModal({ open, onClose, onAlert }: Props) {
  const [range, setRange] = useState<Range>({ from: null, to: null })

  const handleFrom = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, from: date }))
  }, [])

  const handleTo = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, to: date }))
  }, [])

  const handleDownloadFile = useCallback(async () => {
    try {
      const response = await fetch(
        `${API.URL}/validators/export?from=${range?.from?.toFormat(
          'yyyy-MM-dd',
        )}&to=${range?.to?.toFormat('yyyy-MM-dd')}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      )
      if (response?.status === 200) {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `morpheus-validators-${DateTime.now().toISODate()}.csv`
          a.click()
          onClose()
          setRange({ from: null, to: null })
        })
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      onAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }, [onAlert, onClose, range])

  return (
    <ModalWrapper
      buttonText={'Download'}
      disabled={!range?.to || !range?.from}
      open={open}
      title={'Export validators list'}
      onClose={onClose}
      onSubmit={handleDownloadFile}
    >
      <Box alignItems="center" display="flex" mx={2} my={2}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="From"
            shouldDisableDate={day => (range?.to ? day > range?.to : false)}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'standard',
              },
            }}
            value={range?.from}
            onChange={handleFrom}
          />
          <Box mx={2}> — </Box>
          <DatePicker
            format={DEFAULT_DATE_FORMAT}
            label="To"
            shouldDisableDate={day => (range?.from ? day < range?.from : false)}
            slotProps={{
              textField: {
                size: 'small',
                variant: 'standard',
              },
            }}
            value={range?.to}
            onChange={handleTo}
          />
        </LocalizationProvider>
      </Box>
    </ModalWrapper>
  )
}

export default ExportModal
