import { commonChartOptions } from 'constants/chartOptions'

import map from 'lodash/map'
import sumBy from 'lodash/sumBy'

import { calculatePercentage } from './Percentage'

interface Point {
  key: string
  value: number
}

interface NodeVersionsCount {
  count: number
  nodeVersion: string
}

export const createVersionsDonutChartOptions = () => ({
  ...commonChartOptions,
  pieHole: 0.5,
  pieSliceText: 'none',
})

export const generateVersionsTooltipBlock = (
  point: Point,
  percentage: number,
) =>
  `
		<div style="background-color: ${'#fff'}; padding: 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-size: 10px; padding: 5px; margin: 0;">Version: ${
    point?.key
  }</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">Users: ${
    point?.value
  } (${percentage}%)</p>
		</div>
		`

export function generateVersionDonutChartData(
  nodeVersions: NodeVersionsCount[],
) {
  const sumUsers = sumBy(nodeVersions, 'count')

  return [
    [
      'Version',
      'User count',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(nodeVersions, version => [
      version?.nodeVersion,
      version?.count,
      generateVersionsTooltipBlock(
        {
          key: version?.nodeVersion,
          value: version?.count,
        },
        calculatePercentage(version?.count ?? 0, sumUsers),
      ),
    ]),
  ]
}
