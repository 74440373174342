import React from 'react'

import { ReactComponent as GraphIcon } from 'assets/icons/graph.svg'
import { ReactComponent as ListIcon } from 'assets/icons/list-unordered.svg'
import { View } from 'constants/strings'

import { IconWrapper, TabWrapper } from './styles'

interface Props {
  activeTab: View
  onViewChange: (view: View) => void
}

function ViewTab({ activeTab, onViewChange }: Props) {
  return (
    <TabWrapper>
      <IconWrapper
        active={activeTab === View.GRAPH}
        onClick={() => onViewChange(View.GRAPH)}
      >
        <GraphIcon />
      </IconWrapper>
      <IconWrapper
        active={activeTab === View.LIST}
        onClick={() => onViewChange(View.LIST)}
      >
        <ListIcon />
      </IconWrapper>
    </TabWrapper>
  )
}

export default ViewTab
