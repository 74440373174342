import React, { MouseEvent } from 'react'

import LinkSharpIcon from '@mui/icons-material/LinkSharp'
import { TablePagination } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/system'
import { Link } from 'common/styles'
import { WEB3 } from 'config'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { truncateAddress } from 'constants/truncateAddress'
import { getFormattedDate } from 'utils/Date'

type TableRowData = {
  id: string
  transactionHash: string
  createdAt: string
  dataProviderRequestTime: number
  query: {
    queryUrl: string
  }
}

type Props = {
  count: number
  data?: TableRowData[]
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function BasicTable({
  data = [],
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: Props) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Query url</TableCell>
            <TableCell>Processing time</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Transaction hash</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.query?.queryUrl}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.dataProviderRequestTime || '--'}
                  </TableCell>
                  <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>
                  <TableCell component="th" scope="row">
                    <Box alignItems="center" display="flex">
                      <Box mr={1}>{truncateAddress(row?.transactionHash)}</Box>
                      {!!row?.transactionHash && (
                        <Link
                          href={`${WEB3.POLYGONSCAN_URL}/tx/${row.transactionHash}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <LinkSharpIcon sx={{ height: '18px' }} />
                        </Link>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
