import React from 'react'

import { Box } from '@mui/system'

import TotalFeeChart from './feeChart'
import { ChartsWrapper, ChartWrapper } from './styles'
import TotalUptimeChart from './uptimeCharts'
import VersionsCharts from './versionsCharts'

function Charts() {
  return (
    <ChartsWrapper px={2} py={2}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        width="100%"
      >
        <ChartWrapper>
          <TotalFeeChart />
        </ChartWrapper>
        <ChartWrapper>
          <TotalUptimeChart />
        </ChartWrapper>
        <ChartWrapper>
          <VersionsCharts />
        </ChartWrapper>
      </Box>
    </ChartsWrapper>
  )
}

export default Charts
