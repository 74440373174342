import { gql } from '@apollo/client'

export const GET_VALIDATORS = gql`
  query GetValidators($search: String, $page: Int, $order: String, $take: Int) {
    getValidators(search: $search, page: $page, order: $order, take: $take) {
      data {
        id
        createdAt
        name
        email
        wallet
        isApproved
        applicantId
        reviewAnswer
        reviewRejectType
        clientComment
        moderationComment
        rejectLabels
        reviewStatus
        connectedApp
        system
        nodePlatform
        nodeType
        nodeVersion
        nodeAppId
        apy
        nodePeriod
        workers
      }
      meta {
        page
        take
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_VALIDATOR = gql`
  query GetValidator($getValidatorId: String!) {
    getValidator(id: $getValidatorId) {
      id
      name
      email
      wallet
      applicantId
      isApproved
      createdAt
      reviewAnswer
      reviewRejectType
      clientComment
      moderationComment
      rejectLabels
      reviewStatus
      nodeAppId
      workers
    }
  }
`
