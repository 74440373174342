import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const ButtonWrapper = styled(Box)({
  margin: '0 auto',
  maxWidth: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
})
