import React, { useCallback, useMemo, useState } from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import InfoIcon from '@mui/icons-material/Info'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Drawer, Tooltip } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { Node, StakingTermsLabel } from 'common/types'
import {
  LOW_UPTIME_LINE,
  UPTIME_THRESHOLD,
  WARNING_UPTIME_LINE,
} from 'constants/params'
import { truncateAddress } from 'constants/truncateAddress'
import { NodeStatus } from 'pages/nodes/types'
import pluralize from 'pluralize'
import { getFormattedDate } from 'utils/Date'
import { ValidatorInfo } from 'validatorInfo'

interface Props {
  row: Node
  onCopy: (copyContent: string, filed: string) => void
  onOpenValidations: (nodeId: string) => void
  onOpenUptimeChart: (nodeId: string) => void
}
function TableRowReward({
  row,
  onCopy,
  onOpenValidations,
  onOpenUptimeChart,
}: Props) {
  const [viewDrawer, setViewDrawer] = useState<{
    isOpen: boolean
    entity: string | null
  }>({
    isOpen: false,
    entity: null,
  })

  const getUptimeColor = useCallback((totalUptime: number) => {
    if (totalUptime >= WARNING_UPTIME_LINE) {
      return '#26B568'
    }
    if (totalUptime < WARNING_UPTIME_LINE && totalUptime >= LOW_UPTIME_LINE) {
      return '#FFB406'
    }
    return '#E93940'
  }, [])

  const handleOpenView = useCallback((validatorId: string) => {
    setViewDrawer({ isOpen: true, entity: validatorId })
  }, [])

  const handleCloseView = useCallback(() => {
    setViewDrawer({ isOpen: false, entity: null })
  }, [])

  const penaltyType = useMemo(() => {
    if (row.earlyUnstaked && row.totalUptime >= UPTIME_THRESHOLD) {
      return 'Early unstake'
    }
    if (row.earlyUnstaked) {
      return 'Downtime'
    }
    return '--'
  }, [row])

  const paidNodeStatus = useMemo(() => {
    if (row?.status === NodeStatus.TERMINATED) return 'paid'
    return 'unpaid'
  }, [row])

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          <Box display="flex" flexDirection="column">
            <Box sx={{ whiteSpace: 'nowrap' }}>
              {row?.validator?.email}{' '}
              <ContentCopyIcon
                style={{ height: '14px', cursor: 'pointer' }}
                onClick={() => onCopy(row.validator.email, 'email')}
              />
              <InfoIcon
                style={{ height: '14px', cursor: 'pointer' }}
                onClick={() => handleOpenView(row.validatorId)}
              />
            </Box>
            <Box
              alignItems="center"
              display="flex"
              sx={{ mt: 1, whiteSpace: 'nowrap' }}
            >
              Wallet: {truncateAddress(row.validator.wallet)}
              {row.validator.wallet && (
                <ContentCopyIcon
                  style={{ height: '14px', cursor: 'pointer' }}
                  onClick={() => onCopy(row.validator.wallet, 'wallet')}
                />
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            {row.stakedAmount} MNW
          </Typography>
          <Typography sx={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
            {pluralize('node', row.workers ?? 0, true)}
          </Typography>
        </TableCell>
        <TableCell align="center">{getFormattedDate(row?.createdAt)}</TableCell>
        <TableCell align="center">
          {getFormattedDate(row?.unstakesAt)}
        </TableCell>
        <TableCell align="center">
          {row?.period && StakingTermsLabel[row?.period]}
        </TableCell>
        <TableCell align="center">
          {row.earnedRewards.earning?.toFixed(2)}/
          {row.projectedRewards.earning?.toFixed(2)} MNW
        </TableCell>
        <TableCell align="center">
          <Box alignItems="center" display="flex" justifyContent="center">
            {row.validationAmount} /{row?.receivedAmount || 0}
            {row.validationAmount ? (
              <ReadMoreIcon
                sx={{ height: '18px', cursor: 'pointer' }}
                onClick={() => onOpenValidations(row.id)}
              />
            ) : (
              ''
            )}
          </Box>
        </TableCell>
        <TableCell align="center">{penaltyType}</TableCell>
        <TableCell align="center">{row.penalty.toFixed(2)}</TableCell>
        <TableCell align="center">{paidNodeStatus}</TableCell>
        <TableCell align="center">
          <Box alignItems="center" display="flex" justifyContent="center">
            {row?.totalUptime < LOW_UPTIME_LINE && (
              <Tooltip
                sx={{ mt: '5px' }}
                title={`Node went below uptime of ${LOW_UPTIME_LINE}%`}
              >
                <ReportProblemIcon
                  color="error"
                  sx={{ height: '18px', mr: 0.5 }}
                />
              </Tooltip>
            )}
            <Typography sx={{ color: getUptimeColor(row?.totalUptime) }}>
              {row?.totalUptime}%{' '}
            </Typography>{' '}
            {row?.totalUptime ? (
              <ReadMoreIcon
                sx={{ height: '18px', ml: 0.5, cursor: 'pointer' }}
                onClick={() => onOpenUptimeChart(row.id)}
              />
            ) : null}
          </Box>
        </TableCell>
      </TableRow>
      <Drawer anchor="right" open={viewDrawer.isOpen} onClose={handleCloseView}>
        <ValidatorInfo
          validatorId={viewDrawer.entity}
          onClose={handleCloseView}
        />
      </Drawer>
    </>
  )
}

export default TableRowReward
