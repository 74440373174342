import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const BackupCodesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  border: 1px dotted #4d75b8;
  background-color: #f7f9ff;
  position: relative;
`

export const CodesContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  border-bottom: 1px dotted #4d75b8;
  padding: 10px 20px 0;
`

export const CodeItem = styled(Box)`
  width: 45%;
  padding: 5px;
  margin-bottom: 10px;
`

export const IconWrapper = styled(Box)`
  position: absolute;
  top: 40%;
  right: -14px;
  transform: rotate(-90deg);
`
