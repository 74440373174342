import React from 'react'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'

function ErrorChartPlaceholder() {
  return (
    <Box alignItems="center" display="flex" flex={1}>
      <Typography color={'colors.font.default'} fontSize={16} fontWeight={500}>
        Something went wrong
      </Typography>
    </Box>
  )
}

export default ErrorChartPlaceholder
