import { gql } from '@apollo/client'

export const PHASE_FIELDS = gql`
  fragment PhaseFields on Phase {
    id
    index
    startDate
    endDate
    status
    createdAt
    updatedAt
  }
`
