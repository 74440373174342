import React, { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { Alert as MUIAlert, Box } from '@mui/material'
import { Admin } from 'common/types'
import { IS_STAGING } from 'config'
import { AppContext } from 'context/AppContext'
import { ME_ADMIN } from 'graphql/auth/queries'
import { AuthContext } from 'index'
import jwtDecode from 'jwt-decode'
import AuthorizedRouter from 'router/authorizedRouter'
import NotAuthorizedRouter from 'router/notAuthorizedRouter'
import { getToken } from 'services/token'

function App() {
  const [authorized, setAuthorized] = useState<boolean>(false)

  useEffect(() => {
    const token = getToken()
    if (token) {
      const decodedToken = jwtDecode<any>(token)
      setAuthorized(
        !decodedToken?.verificationTOTPEnabled ||
          decodedToken?.checkedVerification,
      )
    } else {
      setAuthorized(false)
    }
  }, [])

  const { data, loading, refetch } = useQuery(ME_ADMIN, {
    skip: !getToken(),
  })

  const meAdmin: Admin = useMemo(() => data?.meAdmin, [data])

  const authContextValue = useMemo(
    () => ({
      authorized,
      setAuthorized: (value: boolean) => setAuthorized(value),
    }),
    [authorized],
  )

  const appContextValue = useMemo(
    () => ({
      meAdmin,
      refetchMeAdmin: refetch,
    }),
    [meAdmin, refetch],
  )

  if (loading) return null

  if (authorized) {
    return (
      <AuthContext.Provider value={authContextValue}>
        <AppContext.Provider value={appContextValue}>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            {IS_STAGING && (
              <MUIAlert
                severity="error"
                sx={{
                  p: 2,
                  fontSize: 18,
                  '& .MuiAlert-icon': {
                    fontSize: 24,
                  },
                }}
              >
                STAGING ENVIRONMENT
              </MUIAlert>
            )}

            <AuthorizedRouter />
          </Box>
        </AppContext.Provider>
      </AuthContext.Provider>
    )
  }
  return (
    <AuthContext.Provider value={authContextValue}>
      {IS_STAGING && (
        <MUIAlert
          severity="error"
          sx={{
            p: 2,
            fontSize: 18,
            '& .MuiAlert-icon': {
              fontSize: 24,
            },
          }}
        >
          STAGING ENVIRONMENT
        </MUIAlert>
      )}
      <NotAuthorizedRouter />
    </AuthContext.Provider>
  )
}

export default App
