import React from 'react'
import BaseFormInput from 'react-phone-number-input'

import {
  ErrorBox,
  LabelBox,
  VariantProps,
  Wrapper,
  WrapperProps,
} from './styles'

import 'react-phone-number-input/style.css'

type Props = WrapperProps &
  VariantProps & {
    caption?: string
    label?: string
    large?: boolean
    medium?: boolean
    placeholder?: string
    small?: boolean
    value?: string
    readOnly?: boolean
    error?: string
    onChange?: (value: string | undefined) => void
    style?: object
  }

function PhoneInput({
  disabled,
  error,
  label,
  large,
  placeholder,
  small,
  value,
  readOnly,
  onChange,
  style,
  ...rest
}: Props) {
  return (
    <Wrapper {...rest} large={large} small={small} style={style}>
      {label && <LabelBox> {label}</LabelBox>}
      <BaseFormInput
        disabled={disabled}
        international
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        onChange={value => onChange?.(value)}
      />
      <ErrorBox>{error}</ErrorBox>
    </Wrapper>
  )
}

export default PhoneInput
