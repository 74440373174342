import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const LoaderHolder = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})
