import React, { PropsWithChildren } from 'react'

import { Button, Modal } from '@mui/material'

import { Content, StyledPaper, Title } from './styles'

interface Props {
  open: boolean
  onClose?: () => void
  onSubmit?: () => void
  title?: string
  buttonText?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const ModalWrapper: React.FC<PropsWithChildren<Props>> = ({
  title,
  open,
  onClose,
  children,
  onSubmit,
  buttonText = 'Submit',
  disabled,
  style,
}) => {
  return (
    <Modal disableAutoFocus open={open} onClose={onClose}>
      <StyledPaper style={style}>
        {title && <Title>{title}</Title>}
        <Content>{children}</Content>
        {typeof onSubmit === 'function' && (
          <Button disabled={disabled} onClick={onSubmit}>
            {buttonText}
          </Button>
        )}
      </StyledPaper>
    </Modal>
  )
}

export default ModalWrapper
