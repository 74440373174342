import { gql } from '@apollo/client'

import { QUEST_FIELDS } from './fragments'

export const GET_QUESTS = gql`
  query getQuests(
    $filter: Object
    $order: String
    $page: Int
    $search: String
    $take: Int
  ) {
    getQuests(
      filter: $filter
      order: $order
      page: $page
      search: $search
      take: $take
    ) {
      data {
        ...QuestFields
      }
      meta {
        hasNextPage
        hasPreviousPage
        itemCount
        page
        pageCount
        take
      }
    }
  }
  ${QUEST_FIELDS}
`

export const GET_VALIDATOR_QUEST_ACTIVITY = gql`
  query validatorQuestActivity(
    $validatorId: String!
    $filter: Object
    $order: String
    $page: Int
    $phaseId: String
    $search: String
    $take: Int
  ) {
    validatorQuestActivity(
      validatorId: $validatorId
      filter: $filter
      order: $order
      page: $page
      phaseId: $phaseId
      search: $search
      take: $take
    ) {
      data {
        phaseId
        points
        quest {
          ...QuestFields
        }
        questId
        validatorId
      }
      meta {
        hasNextPage
        hasPreviousPage
        itemCount
        page
        pageCount
        take
      }
    }
  }
  ${QUEST_FIELDS}
`
