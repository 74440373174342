import { AlertColor } from '@mui/material'
import { DateTime } from 'luxon'

export enum AdminRole {
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  REGULAR_ADMIN = 'regularAdmin',
}

export interface Admin {
  id: string
  email: string
  name: string
  createdAt: string
  isOwner: boolean
  role?: AdminRole
  verificationTOTPEnabled?: boolean
}

export type TQP = {
  order?: 'ASC' | 'DESC'
  page?: number
  take?: number
  search?: string
  orderBy?: string
}

export interface Range {
  from: DateTime | null
  to: DateTime | null
}

export interface AlertMessage {
  isOpen: boolean
  text?: string
  alertColor?: AlertColor
}

export interface Provider {
  id: string
  createdAt: string
  name: string
  dailyQueriesCap: number
  queryConfirmationsCap: number
}

export interface Validator extends Admin {
  wallet: string
  isApproved: boolean
  count: string
  reviewAnswer: 'GREEN' | 'RED'
  applicantId: string
  reviewRejectType: string
  rejectLabels: string[]
  reviewStatus: string
  moderationComment: string
  clientComment: string
  system: boolean
  nodePlatform: string
  nodeType: string
  nodeVersion: string
  apy: number
  nodePeriod: StakingPeriod
  workers: number
}

type NodeInfo = {
  platform: string
  type: string
  version: string
}

export enum StakingPeriod {
  MONTH_3 = 'months3',
  MONTH_6 = 'months6',
  MONTH_12 = 'months12',
}

export type StakingTerms = {
  key: StakingPeriod
  value: number
}
export const StakingTermsLabel: Record<StakingTerms['key'], string> = {
  [StakingPeriod.MONTH_3]: '3 months',
  [StakingPeriod.MONTH_6]: '6 months',
  [StakingPeriod.MONTH_12]: '12 months',
}

type NodeEarning = {
  earning: number
  perDay: number
  apy: number
}

export interface Node {
  id: string
  active: boolean
  address: string
  createdAt: string
  closesAt: string
  unstakesAt: string
  lastActivity: DateTime
  stakedAmount: number
  workers: number
  status: string
  transactionHash: string
  updatedAt: string
  validatorId: string
  totalUptime: number
  nodeInfo: NodeInfo
  period: StakingPeriod
  validationAmount?: number
  earnedRewards: NodeEarning
  projectedRewards: NodeEarning
  reward: number
  earlyUnstaked: boolean
  penalty: number
  receivedAmount?: number
  validator: {
    wallet: string
    email: string
  }
}

export interface Phase {
  id: string
  startDate: string
  endDate: string
  status: PhaseStatus
  createdAt: string
  index: number
  updatedAt: string
  tokenPerNode: number
  yieldPercentage: number
}

export enum PhaseStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  NEW = 'new',
}

export interface NewsArticle {
  id: string
  title: string
  content: string
  createdAt: string
  createdById: string
  lastModifiedById: string
  published: boolean
  publishedAt: string
  updatedAt: string
}

export interface Query {
  id: string
  createdAt: string
  dataProvider: string
  hashedData: string
  processedAt: string
  providerId: string
  queryUrl: string
  trackingCode: string
  transactionHash: string
  updatedAt: string
  validationTransactionHash: string
  successValidationsCount: number
  validationsCount: number
  validationsLeft: number
  provider: Provider
  externalApiClient?: ExternalAPIClient
}

export interface Validation {
  id: string
  createdAt: string
  dataProviderRequestTime: number
  hashedData: string
  nodeId: string
  providerId: string
  queryId: string
  transactionHash: string
  updatedAt: string
  validatorId: string
  query: {
    queryUrl: string
    hashedData: string
    externalApiClient?: ExternalAPIClient
  }
}
export interface Params {
  page: number
  take: number
  search: string
  order?: 'DESC' | 'ASC'
}

export type EntityCountByPeriod = {
  count: number
  period: StakingPeriod
}
export interface MNWDailyStats {
  id: string
  availableNodesCount: number
  bridgedTokenAmount: number
  bridgedTokenDailyDiff: number
  bridgetTokenCirculationPercentage: number
  cliAppVersion: string
  closedValidatorsCount: number
  day: string
  registeredValidatorsCount: number
  stackedNodesCount: number
  stackedTokenAmount: number
  stackedTokenAmountCirculationPercentage: number
  stakedNodesDailyDiff: number
  tokenCapLeft: number
  tokenPrice: number
  totalActiveNodesCount: number
  totalActiveValidatorsCount: number
  totalLockedAmount: number
  unstakedNodesCount: number
  validatorsDailyDiff: number
  circulationSupply: number
  nodesCountByPeriod: EntityCountByPeriod[]
}

export interface Point {
  day: string
  value: number
}

export interface PointNodeByUser {
  key: string
  value: number
}

export type APIClientStats = {
  attempts: number
  date: string
  failures: number
  successes: number
  validations: number
}

export type ExternalAPIClient = {
  apiKey: string
  createdAt: string
  domains: string[]
  enabled: boolean
  id: string
  isSystem: boolean
  name: string
  updatedAt: string
  todayStats: APIClientStats
}

export type Quest = {
  id: string
  title: string
  phaseId: string
  createdAt: string
  expirationDate: string
  publishDate: string
  updatedAt: string
  content: string
  imageUrl: string
  twitterId: string
}

export type QuestActivity = {
  phaseId: string
  points: number
  quest: Quest
  questId: string
  validatorId: string
}

export type ValidationsPerDay = {
  day: string
  reward: number
  amount: number
}
