import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { AlertColor, Button, Snackbar } from '@mui/material'
import { Alert, Input } from 'common'
import { ALERT_DELAY } from 'constants/params'
import { useFormik } from 'formik'
import { RESET_PASSWORD } from 'graphql/auth/mutations'
import { LoginFormLayout } from 'layouts'
import qs from 'qs'
import { Routes } from 'router/routes'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { Title } from './styles'
import { ResetModalInitialValue } from './types'

const ResetPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [resetPassword] = useMutation(RESET_PASSWORD)

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const initialValues: ResetModalInitialValue = {
    email: '',
    password: '',
    confirmPassword: '',
  }

  const { token } = useMemo(
    () => qs.parse(location.search.substring(1)),
    [location],
  )

  const handleSubmit = async (values: ResetModalInitialValue) => {
    if (!token) return
    try {
      const result = await resetPassword({
        variables: {
          newPassword: values.password,
          token,
          email: values.email,
        },
      })
      if (result?.data?.resetPassword) {
        navigate(Routes.LOGIN)
      }
    } catch (error) {
      setAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }

  const validationSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Password must match'),
  })

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
  })
  return (
    <>
      <LoginFormLayout>
        <Title>Reset password</Title>
        <Input
          autocomplete="email"
          error={formik.errors.email}
          name="email"
          placeholder="Email"
          type="text"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Input
          autocomplete="new-password"
          error={formik.errors.password}
          name="password"
          placeholder="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <Input
          error={formik.errors.confirmPassword}
          name="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
        />
        <Button onClick={() => formik.handleSubmit()}>Submit</Button>
      </LoginFormLayout>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ResetPassword
