import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const TextCellWrapper = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 350px;
  cursor: pointer;
`
