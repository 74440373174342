import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { AlertColor, Snackbar } from '@mui/material'
import { Box } from '@mui/system'
import { Alert } from 'common'
import { PhaseInfo } from 'common/blocks'
import { CreatePhaseModal } from 'common/modals'
import PageWrapper from 'common/pageWrapper'
import { Phase } from 'common/types'
import { ALERT_DELAY } from 'constants/params'
import { CURRENT_PHASE } from 'graphql/phase/queries/currentPhase'
import { PHASES } from 'graphql/phase/queries/phases'

import { Container } from './styles'

function Phases() {
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null)

  const [createPhaseModal, setCreatePhaseModal] = useState<{
    isOpen: boolean
    phase?: null | Phase
  }>({
    isOpen: false,
    phase: null,
  })

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const { data: currentPhaseData, loading: currentPhaseLoading } =
    useQuery(CURRENT_PHASE)

  const { data: phasesData, loading: phasesLoading } = useQuery(PHASES)

  const currentPhase: Phase = useMemo(
    () => currentPhaseData?.currentPhase,
    [currentPhaseData],
  )

  const phases: Phase[] = useMemo(
    () => phasesData?.phases || [],
    [phasesData?.phases],
  )

  useEffect(() => {
    if (currentPhase) {
      setSelectedPhase(currentPhase)
    }
  }, [currentPhase])

  const handleOpenCreatePhaseModal = useCallback(
    (selectedPhase?: Phase | null) => {
      setCreatePhaseModal({ isOpen: true, phase: selectedPhase || null })
    },
    [],
  )

  const handleCloseCreatePhaseModal = useCallback(
    () => setCreatePhaseModal({ isOpen: false, phase: null }),
    [],
  )

  return (
    <>
      <PageWrapper pageTitle="Phases">
        <Container>
          <Box sx={{ my: '10px' }}>
            <PhaseInfo
              currentPhase={currentPhase}
              loading={phasesLoading || currentPhaseLoading}
              phases={phases}
              selectedPhase={selectedPhase}
              onOpenCreatePhaseModal={handleOpenCreatePhaseModal}
              onSelectPhase={setSelectedPhase}
            />
          </Box>
        </Container>
      </PageWrapper>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
      <CreatePhaseModal
        open={createPhaseModal.isOpen}
        phase={createPhaseModal.phase}
        onClose={handleCloseCreatePhaseModal}
        onUpdateSelectedPhase={setSelectedPhase}
      />
    </>
  )
}

export default Phases
