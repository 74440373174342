import { gql } from '@apollo/client'

export const UPDATE_VALIDATOR = gql`
  mutation UpdateValidator(
    $updateValidatorId: String!
    $updateValidatorData: UpdateValidator!
  ) {
    updateValidator(
      id: $updateValidatorId
      updateValidatorData: $updateValidatorData
    ) {
      id
      name
      email
      wallet
      isApproved
      createdAt
      nodeAppId
    }
  }
`

export const DELETE_VALIDATOR = gql`
  mutation DeleteValidator($deleteValidatorId: String!) {
    deleteValidator(id: $deleteValidatorId)
  }
`

export const RESET_FAILED_VERIFICATIONS = gql`
  mutation ResetFailedVerifications($validatorIds: [String!]) {
    resetFailedVerifications(validatorIds: $validatorIds) {
      ok
    }
  }
`
