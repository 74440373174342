import React, { useCallback, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { useApolloClient, useMutation } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Snackbar, TextField, Typography } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Alert, Input, ModalWrapper } from 'common'
import { TextFieldWrapper } from 'common/modals/createNews/styles'
import { AlertMessage, Quest } from 'common/types'
import { API } from 'config'
import { ALERT_DELAY, DEFAULT_DATE_TIME_FORMAT } from 'constants/params'
import { twitterIDValidationReg } from 'constants/validations'
import { FormikProps, useFormik } from 'formik'
import { QUEST_FIELDS } from 'graphql/quests/fragments'
import { CREATE_QUEST, UPDATE_QUEST } from 'graphql/quests/mutations'
import { updateQuestQueryUpdater } from 'graphql/quests/updaters'
import { DateTime } from 'luxon'
import { getToken } from 'services/token'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import {
  BrowseButtonContainer,
  DeleteButton,
  DropzoneOverlay,
  DropzoneWrapper,
} from './styles'

interface Props {
  entity?: Quest | null
  open: boolean
  onClose?: () => void
  onRefetch?: () => void
}

enum FIELDS {
  TITLE = 'title',
  CONTENT = 'content',
  EXPIRATION_DATE = 'expirationDate',
  IMAGE_URL = 'imageUrl',
  PUBLISH_DATE = 'publishDate',
  TWITTER_ID = 'twitterId',
}

interface InitialValues {
  [FIELDS.TITLE]: string
  [FIELDS.CONTENT]: string | null
  [FIELDS.EXPIRATION_DATE]: DateTime | null
  [FIELDS.IMAGE_URL]: string
  [FIELDS.PUBLISH_DATE]: DateTime | null
  [FIELDS.TWITTER_ID]: string
}

function CreateQuestModal({ entity, open, onClose, onRefetch }: Props) {
  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })
  const [loading, setLoading] = useState(false)

  const [createQuest, { loading: createLoading }] = useMutation(CREATE_QUEST)

  const client = useApolloClient()

  const [updateQuest, { loading: updateLoading }] = useMutation(UPDATE_QUEST)

  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [dragOver, setDragOver] = useState(false)

  const handleDragEnter = useCallback(() => {
    setDragOver(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setDragOver(false)
  }, [])

  const validationSchema = yup.object({
    [FIELDS.TITLE]: yup
      .string()
      .min(4, 'Title must be at least 4 characters long')
      .required('Title is required'),
    [FIELDS.CONTENT]: yup
      .string()
      .nullable()
      .min(4, 'Content must be at least 4 characters long'),
    [FIELDS.IMAGE_URL]: yup.string().nullable(),
    [FIELDS.EXPIRATION_DATE]: yup
      .string()
      .required('Expiration date is required')
      .nullable()
      .test(
        'is-future-date',
        'Expiration date must be in the future',
        value => {
          if (!value) return false
          const inputDate = DateTime.fromISO(value)
          return inputDate >= DateTime.now()
        },
      ),
    [FIELDS.PUBLISH_DATE]: yup
      .string()
      .required('Publish date is required')
      .nullable(),
    [FIELDS.TWITTER_ID]: yup
      .string()
      .required('Twitter ID is required')
      .matches(twitterIDValidationReg, 'Twitter ID must be a number'),
  })

  const initialValues: InitialValues = {
    [FIELDS.TITLE]: entity?.title || '',
    [FIELDS.CONTENT]: entity?.content || null,
    [FIELDS.IMAGE_URL]: entity?.imageUrl || '',
    [FIELDS.EXPIRATION_DATE]: entity?.expirationDate
      ? DateTime.fromISO(entity?.expirationDate)
      : null,
    [FIELDS.PUBLISH_DATE]: entity?.publishDate
      ? DateTime.fromISO(entity?.publishDate)
      : null,
    [FIELDS.TWITTER_ID]: entity?.twitterId || '',
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue,
  }: FormikProps<InitialValues> = useFormik<InitialValues>({
    initialValues,
    validationSchema,
    onSubmit: values => submit(values),
    validateOnChange: false,
    enableReinitialize: true,
  })

  const submit: (values: InitialValues) => Promise<void> = useCallback(
    async (values: InitialValues) => {
      setLoading(true)
      const alertText = entity?.id
        ? 'Quest updated successfully'
        : 'Quest created successfully'

      const questValues = {
        ...values,
        expirationDate: values?.expirationDate
          ? values.expirationDate.toUTC()
          : null,
        publishDate: values?.publishDate ? values.publishDate.toUTC() : null,
        imageUrl:
          !values?.[FIELDS.IMAGE_URL] ||
          (values?.[FIELDS.IMAGE_URL] &&
            !values?.[FIELDS.IMAGE_URL].startsWith('data:image/'))
            ? values?.[FIELDS.IMAGE_URL]
            : undefined,
      }
      try {
        let questId
        if (entity?.id) {
          const response = await updateQuest({
            variables: {
              id: entity.id,
              quest: questValues,
            },
            update(cache, { data }) {
              return updateQuestQueryUpdater(cache, data, entity)
            },
          })
          questId = response?.data?.updateQuest?.id
        } else {
          const response = await createQuest({
            variables: { createQuestData: questValues },
          })
          onRefetch?.()
          questId = response?.data?.createQuest?.id
        }

        if (
          values?.[FIELDS.IMAGE_URL] &&
          values?.[FIELDS.IMAGE_URL].startsWith('data:image/') &&
          selectedFile &&
          questId
        ) {
          const token = getToken()

          if (!token) return

          const formData = new FormData()
          formData.append('questId', questId)
          formData.append('file', selectedFile)
          formData.append('contentType', selectedFile.type)

          const response = await fetch(`${API.URL}/quest/upload-img`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          })

          if (response.ok) {
            const quest = await response.json()
            client.cache.writeFragment({
              id: `Quest:${questId}`,
              fragment: QUEST_FIELDS,
              fragmentName: 'QuestFields',
              data: { ...quest },
            })
          }
        }
        setAlert({
          isOpen: true,
          text: alertText,
          alertColor: 'success',
        })
        onClose?.()
        resetForm()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      } finally {
        setLoading(false)
      }
    },
    [
      client,
      createQuest,
      entity,
      onClose,
      onRefetch,
      resetForm,
      selectedFile,
      updateQuest,
    ],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]: File[]) => {
      if (!acceptedFile) {
        handleDragLeave()
        return
      }

      try {
        const reader = new FileReader()
        reader.onload = () => {
          setFieldValue(FIELDS.IMAGE_URL, reader.result as string)
        }
        setSelectedFile(acceptedFile)
        reader.readAsDataURL(acceptedFile)
        handleDragLeave()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: 'Unable to upload image',
          alertColor: 'error',
        })
      }
    },
    [handleDragLeave, setFieldValue],
  )

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
  } = useDropzone({
    onDrop: handleDrop,
    maxFiles: 1,
    disabled: createLoading || updateLoading,
    noClick: true,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
  })

  const handleCloseModal = useCallback(() => {
    onClose?.()
    resetForm()
  }, [onClose, resetForm])

  const isEdit = useMemo(() => !!entity?.id, [entity])

  const areDatesValid = useMemo(() => {
    const pubDateTime = values[FIELDS.PUBLISH_DATE]
    const expDateTime = values[FIELDS.EXPIRATION_DATE]

    if (!pubDateTime || !expDateTime) {
      return true
    }

    return expDateTime > pubDateTime
  }, [values])

  const handleRemoveImage = useCallback(() => {
    setFieldValue(FIELDS.IMAGE_URL, null)
    setSelectedFile(null)
  }, [setFieldValue])

  return (
    <>
      <ModalWrapper
        buttonText={isEdit ? 'Edit' : 'Create'}
        disabled={createLoading || updateLoading || loading || !areDatesValid}
        open={open}
        style={{ maxWidth: '780px' }}
        title={isEdit ? 'Update quest' : 'Create quest'}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      >
        <Box display="flex" gap={3} height="100%">
          <Box width={'70%'}>
            <DropzoneWrapper {...getRootProps()} p={2}>
              <input {...getInputProps()} />
              {dragOver && (
                <DropzoneOverlay>
                  <Typography>Drop the files here...</Typography>
                </DropzoneOverlay>
              )}
              {values?.[FIELDS.IMAGE_URL] ? (
                <Box height="100%" position="relative">
                  <Box
                    component="img"
                    loading="lazy"
                    src={values[FIELDS.IMAGE_URL]}
                    sx={{
                      height: '100%',
                      width: '100%',
                      maxHeight: '400px',
                      objectFit: 'cover',
                      overflow: 'hidden',
                    }}
                  />

                  <DeleteButton onClick={handleRemoveImage}>
                    <DeleteIcon color="primary" />
                  </DeleteButton>
                </Box>
              ) : (
                <BrowseButtonContainer>
                  <Box>
                    <Typography color="primary" mb={2}>
                      Drag & Drop or click
                    </Typography>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={openDropzone}
                    >
                      Browse
                    </Button>
                  </Box>
                </BrowseButtonContainer>
              )}
            </DropzoneWrapper>
          </Box>
          <Box>
            <Input
              error={errors[FIELDS.TITLE]}
              label="Title"
              name={FIELDS.TITLE}
              placeholder="Type your title for quest"
              value={values[FIELDS.TITLE]}
              onChange={handleChange}
            />
            <Input
              error={errors[FIELDS.TWITTER_ID]}
              label="Twitter ID"
              name={FIELDS.TWITTER_ID}
              placeholder="Type twitter ID"
              value={values[FIELDS.TWITTER_ID]}
              onChange={handleChange}
            />

            <TextFieldWrapper mt={2}>
              <Box mb={1}>Quest content</Box>
              <TextField
                error={Boolean(errors[FIELDS.CONTENT])}
                fullWidth
                helperText={errors[FIELDS.CONTENT]}
                multiline
                name={FIELDS.CONTENT}
                placeholder="Type your quest content"
                sx={{ mb: 2 }}
                value={values[FIELDS.CONTENT]}
                variant="standard"
                onChange={handleChange}
              />
            </TextFieldWrapper>

            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                label="Publish date"
                minDate={DateTime.now()}
                slotProps={{
                  textField: {
                    helperText: errors[FIELDS.PUBLISH_DATE],
                    error: !!errors[FIELDS.PUBLISH_DATE],
                    name: FIELDS.PUBLISH_DATE,
                    size: 'small',
                    sx: { mb: 1, width: '100%' },
                    variant: 'standard',
                    inputProps: {
                      inputFormat: DEFAULT_DATE_TIME_FORMAT,
                    },
                  },
                }}
                value={values[FIELDS.PUBLISH_DATE]}
                onChange={value => setFieldValue(FIELDS.PUBLISH_DATE, value)}
              />
              <DateTimePicker
                label="Expire date"
                minDate={DateTime.now()}
                slotProps={{
                  textField: {
                    helperText: errors[FIELDS.EXPIRATION_DATE],
                    error: !!errors[FIELDS.EXPIRATION_DATE],
                    name: FIELDS.EXPIRATION_DATE,
                    size: 'small',
                    sx: { mb: 1, width: '100%' },
                    variant: 'standard',
                    inputProps: {
                      inputFormat: DEFAULT_DATE_TIME_FORMAT,
                    },
                  },
                }}
                value={values[FIELDS.EXPIRATION_DATE]}
                onChange={value => setFieldValue(FIELDS.EXPIRATION_DATE, value)}
              />
            </LocalizationProvider>
            <Box>
              {!areDatesValid && (
                <Typography color="error" fontSize="12px" sx={{ mt: 1 }}>
                  Please check the publication date and expired date. The
                  expired date should be later than the publication date.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CreateQuestModal
