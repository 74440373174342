export interface TOTPFactor {
  factorId: string
  secret: string
  uri: string
}

export interface BackupCode {
  id: string
  adminId: string
  code: string
  createdAt: string
  updatedAt: string
}

export enum AuthVerification {
  BACKUPCODE = 'backupCode',
  TOTP = 'totp',
}
