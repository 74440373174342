import React, { useCallback, useMemo } from 'react'

import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Box } from '@mui/system'
import { LoaderHolder } from 'common'
import { Phase, PhaseStatus } from 'common/types'
import { PhaseStatusLabels } from 'constants/strings'
import { getFormattedDate } from 'utils/Date'

import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

import {
  PhaseFilterContainer,
  PhaseInfoContainer,
  PhaseWrapper,
} from './styles'

interface Props {
  currentPhase?: Phase
  selectedPhase: Phase | null
  phases: Phase[]
  loading: boolean
  onOpenCreatePhaseModal?: (phase?: Phase | null) => void
  onSelectPhase: (phase: Phase) => void
}

function PhaseInfo({
  selectedPhase,
  onSelectPhase,
  phases,
  loading,
  currentPhase,
  onOpenCreatePhaseModal,
}: Props) {
  const phasesByIndex = useMemo(() => keyBy(phases, 'index'), [phases])

  const handleSelectPhase = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const { value } = event.target
      onSelectPhase(phasesByIndex[value as number])
    },
    [onSelectPhase, phasesByIndex],
  )

  const phasesOptions = useMemo(() => {
    return map(phases, (phase: Phase) => (
      <MenuItem key={phase.id} value={phase.index}>
        {phase.index === currentPhase?.index
          ? `${phase.index} (Current phase)`
          : phase.index}
      </MenuItem>
    ))
  }, [currentPhase, phases])

  return (
    <Box>
      <PhaseWrapper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: 2,
          pt: 3,
        }}
      >
        {loading ? (
          <LoaderHolder sx={{ py: 4.5 }}>
            <CircularProgress size={37} />
          </LoaderHolder>
        ) : (
          <>
            <PhaseFilterContainer>
              <FormControl>
                <InputLabel id="select-phase">Phase</InputLabel>
                <Select
                  label="Phase"
                  size="small"
                  sx={{ borderRadius: '6px', mx: 0, mb: 1, width: '300px' }}
                  value={selectedPhase?.index ?? ''}
                  onChange={handleSelectPhase}
                >
                  {phasesOptions}
                </Select>
              </FormControl>
              <Button
                size="small"
                sx={{ height: 38, width: '180px' }}
                variant="contained"
                onClick={() => onOpenCreatePhaseModal?.()}
              >
                Create new phase
              </Button>
            </PhaseFilterContainer>
            <PhaseInfoContainer>
              <Box display="flex" flexDirection="column">
                <Box>
                  Start Date: {getFormattedDate(selectedPhase?.startDate)}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box>End Date: {getFormattedDate(selectedPhase?.endDate)}</Box>
              </Box>
              <Box>
                Status:{' '}
                {selectedPhase?.status
                  ? PhaseStatusLabels[selectedPhase?.status]
                  : '--'}
              </Box>
              {selectedPhase?.status !== PhaseStatus.COMPLETED && (
                <Box display="flex" justifyContent="end">
                  <Button
                    disabled={!selectedPhase || isEmpty(selectedPhase)}
                    size="small"
                    sx={{ height: 38, width: '180px' }}
                    variant="contained"
                    onClick={() => onOpenCreatePhaseModal?.(selectedPhase)}
                  >
                    Update phase
                  </Button>
                </Box>
              )}
            </PhaseInfoContainer>
            {!currentPhase && (
              <Box mt={2} mx={1}>
                There is no active phase at the moment
              </Box>
            )}
          </>
        )}
      </PhaseWrapper>
    </Box>
  )
}

export default PhaseInfo
