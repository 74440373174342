import { PhaseStatus } from 'common/types'
import { DateTime } from 'luxon'

export const PhaseStatusLabels: Record<PhaseStatus, string> = {
  [PhaseStatus.ACTIVE]: 'Active',
  [PhaseStatus.COMPLETED]: 'Completed',
  [PhaseStatus.NEW]: 'New',
}

export enum View {
  LIST = 'list',
  GRAPH = 'graph',
}

export const MIN_START_DATE_OF_CHARTS = DateTime.fromISO('2024-07-01')
