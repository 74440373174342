import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import { AlertColor, Button, Snackbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Alert, Input } from 'common'
import { ALERT_DELAY } from 'constants/params'
import { emailValidationReg } from 'constants/validations'
import { useFormik } from 'formik'
import { FORGOT_PASSWORD } from 'graphql/auth/mutations'
import { LoginFormLayout } from 'layouts'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import get from 'lodash/get'

import { Title } from './styles'

type InitialValues = {
  email: string
}

function ConfirmEmail() {
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD)
  const [submittedEmail, setSubmittedEmail] = useState<string>('')

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const initialValues: InitialValues = {
    email: '',
  }
  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Invalid email')
      .matches(emailValidationReg, 'Invalid email')
      .required('Email is required'),
  })

  const handleSubmit = useCallback(
    async (values: InitialValues) => {
      try {
        await forgotPassword({ variables: { ...values } })
        setSubmittedEmail(get(values, 'email'))
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [forgotPassword],
  )

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  if (submittedEmail) {
    return (
      <LoginFormLayout>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography sx={{ mb: 2, textAlign: 'center' }} variant="h6">
            Check your email
          </Typography>
          <Typography sx={{ textAlign: 'center', mb: 1 }}>
            {`We emailed a magic link to ${submittedEmail}.`}
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            Click the link to Log In or Sign Up
          </Typography>
        </Box>
      </LoginFormLayout>
    )
  }

  return (
    <>
      <LoginFormLayout>
        <Title>Confirm your email to reset password</Title>
        <Input
          error={formik.errors.email}
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Button disabled={loading} onClick={() => formik.handleSubmit()}>
          Confirm email
        </Button>
      </LoginFormLayout>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ConfirmEmail
