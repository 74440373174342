import { styled } from '@mui/system'

export const CodeWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 188px;
  height: 188px;
  padding: 10px;
  flex-shrink: 0;
  border: 1px solid #e5edfd;
  border-radius: 6px;
`

export const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  width: 100%;
`
