import React from 'react'

import Button from '@mui/material/Button'
import { ModalWrapper } from 'common'

import { ButtonWrapper } from './styles'

interface Props {
  isValid?: boolean
  loading: boolean
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

function ConfirmModal({ isValid, open, onClose, onConfirm, loading }: Props) {
  return (
    <ModalWrapper
      open={open}
      title={'Are you sure you want to save new settings?'}
      onClose={onClose}
    >
      <ButtonWrapper>
        <Button fullWidth variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={loading || !isValid}
          fullWidth
          variant="contained"
          onClick={onConfirm}
        >
          Save
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  )
}

export default ConfirmModal
