import React from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { CopyButton, Label, Row, Value } from './styles'

interface Props {
  label: string
  value: string | number
  copyValue?: string | number
}

const KeyValueLine = ({ label, value, copyValue }: Props) => {
  const onCopyHandler = () => navigator.clipboard.writeText(String(copyValue))

  return (
    <Row>
      <Label>{label}</Label>
      <Value>
        {value}
        {copyValue && (
          <CopyButton>
            <ContentCopyIcon onClick={onCopyHandler} />
          </CopyButton>
        )}
      </Value>
    </Row>
  )
}

export default KeyValueLine
