import React, { useCallback, useMemo, useState } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import { Range } from 'common/types'
import DateRange from 'common/ui/dateRange'
import { TRANSACTION_FEES_PER_DAY_GRAPH_DATA } from 'graphql/overview/queries'
import { DateTime } from 'luxon'
import {
  createTransactionFeeChartOptions,
  generateTransactionFeeChartData,
} from 'utils/transactionFeesChart'

export enum TransactionFeeChartKind {
  TOKEN = 'token',
  CURRENCY = 'currency',
}

function TransactionFeeChart() {
  const [chartKind, setChartKind] = useState(TransactionFeeChartKind.TOKEN)
  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ month: 1 }),
    to: DateTime.now().plus({ month: 1 }),
  })

  const { loading, data, error } = useQuery(
    TRANSACTION_FEES_PER_DAY_GRAPH_DATA,
    {
      variables: {
        from: range?.from?.toISO(),
        to: range?.to?.toISO(),
      },
      skip: !range?.to || !range?.from,
    },
  )

  const historicalChartData = useMemo(
    () => data?.transactionFeesPerDayGraphData?.historical || [],
    [data],
  )
  const forecastChartData = useMemo(
    () => data?.transactionFeesPerDayGraphData?.forecast || [],
    [data],
  )

  const hasHistoricalChartData = !!historicalChartData?.length
  const hasForecastChartData = !!forecastChartData?.length

  const chartData = useMemo(() => {
    if (!forecastChartData.length && !historicalChartData?.length) return []
    return generateTransactionFeeChartData(
      historicalChartData,
      forecastChartData,
      chartKind,
      hasHistoricalChartData,
      hasForecastChartData,
    )
  }, [
    chartKind,
    forecastChartData,
    hasForecastChartData,
    hasHistoricalChartData,
    historicalChartData,
  ])

  const chartColors = useMemo(() => {
    if (!hasForecastChartData) {
      return ['#26B568']
    }
    if (!hasHistoricalChartData) {
      return ['#91A7D4', '#E93940']
    }
    return ['#26B568', '#91A7D4', '#E93940']
  }, [hasForecastChartData, hasHistoricalChartData])

  const startHAxis = useMemo(() => {
    if (historicalChartData?.length) {
      return historicalChartData?.[0]?.day
    }
    return forecastChartData?.[0]?.day
  }, [historicalChartData, forecastChartData])

  const chartOptions = createTransactionFeeChartOptions(startHAxis)

  const handleChangeChartKind = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChartKind(
        (event.target as HTMLInputElement).value as TransactionFeeChartKind,
      )
    },
    [],
  )

  const handleFrom = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, from: date }))
  }, [])

  const handleTo = useCallback((date: DateTime | null) => {
    setRange(prevState => ({ ...prevState, to: date }))
  }, [])

  const noChartData = !historicalChartData?.length && !forecastChartData?.length

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <RadioGroup row value={chartKind} onChange={handleChangeChartKind}>
          <FormControlLabel
            control={<Radio size="small" />}
            label="POL"
            value={TransactionFeeChartKind.TOKEN}
          />
          <FormControlLabel
            control={<Radio size="small" />}
            label="USD"
            value={TransactionFeeChartKind.CURRENCY}
          />
        </RadioGroup>
        <Box alignItems="center" display="flex" justifyContent="end">
          <DateRange
            range={range}
            onChangeFrom={handleFrom}
            onChangeTo={handleTo}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Box height="300px" width="100%">
            <Chart
              chartType="ComboChart"
              data={chartData}
              height="100%"
              options={{ ...chartOptions, colors: chartColors }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default TransactionFeeChart
