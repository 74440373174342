import React from 'react'

import { ModalWrapper } from 'common'
import { AlertMessage, Validator } from 'common/types'
import ValidatorForm from 'pages/validators/forms/validatorForm'

interface Props {
  open: boolean
  onAlert: (alert: AlertMessage) => void
  onClose: () => void
  validator: Validator
}

function EditModal({ open, onClose, validator, onAlert }: Props) {
  return (
    <ModalWrapper
      open={open}
      title={`Updating ${validator.name || 'validator'}?`}
      onClose={onClose}
    >
      <ValidatorForm
        initialValues={validator}
        onAlert={onAlert}
        onClose={onClose}
      />
    </ModalWrapper>
  )
}

export default EditModal
