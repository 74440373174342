import { Box } from '@mui/material'
import { styled } from '@mui/system'
import theme from 'theme'

export const CustomLogoutButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  margin: '0',
  padding: '12px',
  color: theme.palette.error.main,
  cursor: 'pointer',
  fontSize: '14px',
  height: '48px',
  '&:hover': {
    color: 'red',
  },
})
