import { HttpLink, split } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMainDefinition } from '@apollo/client/utilities'
import { API, VALIDATE_API } from 'config'

import WebSocketLink from './WebSocketLink'

export default function requestLink() {
  return split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    new WebSocketLink('v2/graphql'),
    split(
      operation => operation.getContext().validate === true,
      new HttpLink({
        uri: `${VALIDATE_API.URL}/graphql`,
      }),
      new HttpLink({ uri: `${API.URL}/v2/graphql` }),
    ),
  )
}
