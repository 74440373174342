import { HeadCell } from 'common/ui/tableHeader/types'

enum RewardsColumn {
  Email = 'email',
  Staked = 'staked',
  StakedAt = 'stakedAt',
  UnstakedAt = 'unstakedAt',
  Terms = 'terms',
  Rewards = 'rewards',
  Transactions = 'transactions',
  Penalty = 'penalty',
  PenaltyAmount = 'penaltyAmount',
  Status = 'status',
  Uptime = 'uptime',
}
export const HEADER_CELLS: HeadCell[] = [
  {
    id: RewardsColumn.Email,
    label: 'Email/Address',
  },
  {
    id: RewardsColumn.Staked,
    label: 'Staked',
  },
  {
    id: RewardsColumn.StakedAt,
    label: 'Staked At',
    align: 'center',
  },
  {
    id: RewardsColumn.UnstakedAt,
    label: 'Unstaked At',
    sortable: true,
    align: 'center',
  },
  {
    id: RewardsColumn.Terms,
    label: 'Terms',
    align: 'center',
  },
  {
    id: RewardsColumn.Rewards,
    label: 'Earned/Projected rewards',
    align: 'center',
  },
  {
    id: RewardsColumn.Transactions,
    label: 'Validated /Received transactions',
    align: 'center',
  },
  {
    id: RewardsColumn.Penalty,
    label: 'Penalty type',
    align: 'center',
  },
  {
    id: RewardsColumn.PenaltyAmount,
    label: 'Penalty amount',
    align: 'center',
  },
  {
    id: RewardsColumn.Status,
    label: 'Status',
    align: 'center',
  },
  {
    id: RewardsColumn.Uptime,
    label: 'Uptime',
    align: 'center',
  },
]
