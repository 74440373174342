import { gql } from '@apollo/client'

export const NODE_REWARD_STATS = gql`
  query NodeRewardStats($from: DateTime!, $to: DateTime!) {
    nodeRewardStats(from: $from, to: $to) {
      paid
      penalized
      toBePaid
    }
  }
`
