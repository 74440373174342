import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { LoaderHolder } from 'common'
import ViewQuestModal from 'common/modals/viewQuest'
import { Phase, Quest, QuestActivity } from 'common/types'
import { MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { GET_VALIDATOR_QUEST_ACTIVITY } from 'graphql/quests/queries'

import Table from './table'

type Props = {
  phase?: Phase | null
  validatorId: string
}

export default function QuestsActivitiesTable({ phase, validatorId }: Props) {
  const [page, setPage] = useState(0)

  const [viewQuestModal, setViewQuestModal] = useState<{
    isOpen: boolean
    entity: Quest | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [loadQuestActivity, { data, loading, refetch }] = useLazyQuery(
    GET_VALIDATOR_QUEST_ACTIVITY,
  )

  const fetchQuestActivity = useCallback(
    () =>
      loadQuestActivity({
        variables: {
          validatorId,
          take: MIN_ROWS_PER_PAGE_DEFAULT,
          phaseId: phase?.id,
          page: 1,
        },
      }),
    [loadQuestActivity, phase, validatorId],
  )

  useEffect(() => {
    if (validatorId && phase?.id) {
      fetchQuestActivity().then()
    }
  }, [validatorId, fetchQuestActivity, phase])

  const questActivities: QuestActivity[] = useMemo(
    () => data?.validatorQuestActivity?.data || [],
    [data],
  )

  const questActivitiesMeta = useMemo(
    () => data?.validatorQuestActivity?.meta || {},
    [data],
  )

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ page: page + 1 })
      setPage(page)
    },
    [refetch],
  )

  const handleOpenViewQuestModal = useCallback((entity: Quest) => {
    setViewQuestModal({ isOpen: true, entity })
  }, [])

  const handleCloseViewQuestModal = useCallback(() => {
    setViewQuestModal({ isOpen: false, entity: null })
  }, [])

  if (loading)
    return (
      <LoaderHolder sx={{ height: '70px' }}>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Table
        count={questActivitiesMeta?.itemCount ?? 0}
        data={questActivities}
        page={page}
        onOpenViewQuestModal={handleOpenViewQuestModal}
        onPageChange={handlePageChange}
      />
      {viewQuestModal?.entity && (
        <ViewQuestModal
          open={viewQuestModal.isOpen}
          quest={viewQuestModal.entity}
          onClose={handleCloseViewQuestModal}
        />
      )}
    </>
  )
}
