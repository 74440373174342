import React, { useCallback, useMemo } from 'react'

import { useMutation } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Button, IconButton, Switch } from '@mui/material'
import { Input, ModalWrapper } from 'common'
import { AlertMessage, ExternalAPIClient } from 'common/types'
import { FormikProps, useFormik } from 'formik'
import {
  CREATE_EXTERNAL_API_CLIENT,
  UPDATE_EXTERNAL_API_CLIENT,
} from 'graphql/externalApiClients/mutations'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { compact, isArray } from 'lodash'

import { ErrorBox } from './styles'

const isFQDN = require('is-fqdn')

export interface ExternalAPIClientForm {
  id?: string
  name: string
  enabled: boolean
  domains: string[]
}

interface Props {
  open: boolean
  onClose?: () => void
  entity?: ExternalAPIClient | null
  onRefetch?: () => void
  onAlert?: (alert: AlertMessage) => void
}

enum FIELDS {
  ID = 'id',
  NAME = 'name',
  ENABLED = 'enabled',
  DOMAINS = 'domains',
}

interface InitialValues {
  [FIELDS.ID]: string | undefined
  [FIELDS.NAME]: string
  [FIELDS.ENABLED]: boolean
  [FIELDS.DOMAINS]: string[]
}

const CHARACTER_LIMIT_NAME = 200

function CreateExternalAPIClientModal({
  open,
  onClose,
  entity,
  onAlert,
  onRefetch,
}: Props) {
  const [createExternalAPIClient, { loading: createLoading }] = useMutation(
    CREATE_EXTERNAL_API_CLIENT,
  )

  const [updateExternalAPIClient, { loading: updateLoading }] = useMutation(
    UPDATE_EXTERNAL_API_CLIENT,
  )

  const validationSchema = yup.object({
    [FIELDS.NAME]: yup
      .string()
      .required('Name is required')
      .max(
        CHARACTER_LIMIT_NAME,
        `Title can be at most ${CHARACTER_LIMIT_NAME} characters long`,
      ),
    [FIELDS.DOMAINS]: yup.array().of(
      yup.string().test('is_FQDN', 'Domain must be a valid URL', value => {
        if (!value) return true
        return isFQDN(value)
      }),
    ),
  })

  const initialValues: InitialValues = {
    [FIELDS.ID]: entity?.id,
    [FIELDS.NAME]: entity?.name || '',
    [FIELDS.ENABLED]: entity?.enabled || false,
    [FIELDS.DOMAINS]: entity?.domains || [''],
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    resetForm,
    dirty,
    setFieldValue,
  }: FormikProps<ExternalAPIClientForm> = useFormik<ExternalAPIClientForm>({
    initialValues,
    validationSchema,
    onSubmit: values => submit(values),
    validateOnChange: false,
    enableReinitialize: true,
  })

  const submit: (values: ExternalAPIClientForm) => Promise<void> = useCallback(
    async (values: ExternalAPIClientForm) => {
      const alertText = values?.id
        ? 'Client updated successfully'
        : 'Client created successfully'
      const mutationHandler = values.id
        ? updateExternalAPIClient
        : createExternalAPIClient
      try {
        await mutationHandler({
          variables: {
            ...values,
            domains: compact(values.domains),
          },
          context: {
            validate: true,
          },
        })
        await onRefetch?.()
        onAlert?.({
          isOpen: true,
          text: alertText,
          alertColor: 'success',
        })
        onClose?.()
        resetForm()
      } catch (error) {
        onAlert?.({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [
      createExternalAPIClient,
      onAlert,
      onClose,
      onRefetch,
      resetForm,
      updateExternalAPIClient,
    ],
  )

  const isEdit = useMemo(() => !!entity?.id, [entity])

  return (
    <ModalWrapper
      buttonText={isEdit ? 'Update' : 'Create'}
      disabled={createLoading || updateLoading || (isEdit && !dirty)}
      open={open}
      title={isEdit ? 'Update client' : 'Create client'}
      onClose={() => {
        onClose?.()
        resetForm()
      }}
      onSubmit={handleSubmit}
    >
      <Box>
        <Input
          error={errors[FIELDS.NAME]}
          label="Client name"
          name={FIELDS.NAME}
          placeholder="Name"
          value={values[FIELDS.NAME]}
          onChange={handleChange}
        />
        <label>Enabled/disabled:</label>
        <Switch
          checked={values[FIELDS.ENABLED]}
          name={FIELDS.ENABLED}
          onChange={handleChange}
        />
        <Box mt={3}>
          <label>Allowed domains:</label>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            maxHeight="200px"
            mt={2}
            overflow="auto"
          >
            {values?.[FIELDS.DOMAINS]?.map((domain, index) => (
              <Box
                alignItems="center"
                display="flex"
                gap={1}
                justifyContent="space-between"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Box sx={{ width: '100%' }}>
                  <Input
                    error={
                      isArray(errors?.domains)
                        ? errors?.domains?.[index]
                        : undefined
                    }
                    label={`Domain ${index + 1}`}
                    name={`${FIELDS.DOMAINS}[${index}]`}
                    placeholder="Domain"
                    value={domain}
                    onChange={handleChange}
                  />
                </Box>

                <IconButton
                  size="small"
                  onClick={() => {
                    const spliced = values?.[FIELDS.DOMAINS].slice()
                    spliced.splice(index, 1)
                    setFieldValue(FIELDS.DOMAINS, spliced)
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </Box>
            ))}
          </Box>

          <Box>
            <Button
              startIcon={<AddIcon />}
              sx={{ my: 1 }}
              onClick={() =>
                setFieldValue(FIELDS.DOMAINS, [...values[FIELDS.DOMAINS], ''])
              }
            >
              Add domain
            </Button>
            {!compact(values?.[FIELDS.DOMAINS]).length && (
              <ErrorBox color="error">
                If you dont add any domains, your queries can be run from
                anywhere
              </ErrorBox>
            )}
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  )
}

export default CreateExternalAPIClientModal
