import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'

import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material/styles'
import BrowserRouter from 'CustomBrowserRoute'
import history from 'historyConfig'
import theme from 'theme'

import createApolloClient from './graphql/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

export const AuthContext = createContext({
  authorized: false,
  setAuthorized: (values: boolean) => {},
})

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <BrowserRouter history={history}>
      <ApolloProvider client={createApolloClient()}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </ThemeProvider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
