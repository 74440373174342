import React from 'react'

import { ModalWrapper } from 'common'
import { BackupCode } from 'common/types/verification'

import { BackupCodesForm } from '../Forms'

interface Props {
  backupCodes: BackupCode[]
  open: boolean
  onClose: () => void
}

function UpdateBackupCodes({ backupCodes, open, onClose }: Props) {
  return (
    <ModalWrapper open={open} onClose={onClose}>
      <BackupCodesForm backupCodes={backupCodes} onNextStep={onClose} />
    </ModalWrapper>
  )
}

export default UpdateBackupCodes
