import { gql } from '@apollo/client'

import { NEWS_FIELDS } from './fragments'

export const CREATE_NEWS = gql`
  mutation CreateNews($title: String, $content: String!) {
    createNews(title: $title, content: $content) {
      ...NewsFields
    }
  }
  ${NEWS_FIELDS}
`

export const UPDATE_NEWS = gql`
  mutation UpdateNews($id: ID!, $title: String, $content: String!) {
    updateNews(id: $id, title: $title, content: $content) {
      ...NewsFields
    }
  }
  ${NEWS_FIELDS}
`

export const DELETE_NEWS = gql`
  mutation DeleteNews($id: ID!) {
    deleteNews(id: $id) {
      ok
    }
  }
`

export const PUBLISH_NEWS = gql`
  mutation PublishNews($id: ID!) {
    publishNews(id: $id) {
      ...NewsFields
    }
  }
  ${NEWS_FIELDS}
`
