import { Box, IconButton } from '@mui/material'
import { styled } from '@mui/system'

export const DropzoneWrapper = styled(Box)`
  height: 100%;
  background-color: #ebebeb;
  border: 1px dashed #a2a1a1;
  border-radius: 6px;
  position: relative;
`

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const BrowseButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
`

export const DropzoneOverlay = styled(Box)`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #adabab;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  line-height: 1.1em;
  text-align: center;
`
