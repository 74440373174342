import { Typography } from '@mui/material'
import { Box, styled } from '@mui/system'

export const Title = styled(Typography)`
  color: rgba(114, 114, 114, 1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const DiffBlock = styled(Box)`
  align-items: baseline;
  p:first-of-type {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
  }
  p:last-of-type {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  svg {
    height: 32px;
    width: 32px;
  }
`

export const NumberStats = styled(Typography)`
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
`
