import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  ChartLegend,
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import { getXTicks } from 'utils/Charts'
import {
  createTokenStakedBarChartOptions,
  generateTokenPriceChartData,
} from 'utils/TokenStakedBarChart'

const LegendItems = [
  { title: 'Staked', color: '#91A7D4' },
  { title: 'Unstaked', color: '#D92E35' },
]
function TokenStakedBarChart() {
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const stackedNodesCount = useMemo(
    () => data?.systemDailyStatsGraphData?.stackedNodesCount || [],
    [data],
  )

  const unstakedNodesCount = useMemo(
    () => data?.systemDailyStatsGraphData?.unstakedNodesCount || [],
    [data],
  )

  const statsData = useMemo(
    () =>
      stackedNodesCount.length && unstakedNodesCount.length
        ? generateTokenPriceChartData(stackedNodesCount, unstakedNodesCount)
        : [],
    [stackedNodesCount, unstakedNodesCount],
  )

  const xTicks = useMemo(
    () => getXTicks(stackedNodesCount),
    [stackedNodesCount],
  )

  const startHAxis = useMemo(
    () => data?.systemDailyStatsGraphData?.stackedNodesCount[0]?.day,
    [data],
  )

  const options = createTokenStakedBarChartOptions(xTicks, startHAxis)

  const noChartData = !stackedNodesCount.length && !unstakedNodesCount.length

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mt={1}
        width="100%"
      >
        <ChartLegend legendItems={LegendItems} />
        <Box display="flex">
          <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Chart
            chartType="ColumnChart"
            data={statsData}
            height="100%"
            options={options}
          />
        )}
      </Box>
    </>
  )
}

export default TokenStakedBarChart
