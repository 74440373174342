import React, { MouseEvent } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Quest, QuestActivity } from 'common/types'
import { MIN_ROWS_PER_PAGE_DEFAULT } from 'constants/params'

import map from 'lodash/map'

import QuestActivityRow from './questActivityRow'

type Props = {
  count: number
  data?: QuestActivity[]
  page: number
  onOpenViewQuestModal: (quest: Quest) => void
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
}

export default function BasicTable({
  count,
  data = [],
  page,
  onOpenViewQuestModal,
  onPageChange,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="Validators table" size="small" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Twitter ID</TableCell>
            <TableCell align="left">Publish At</TableCell>
            <TableCell align="center">Expired At</TableCell>
            <TableCell align="center">Points</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={6}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {map(data, (row: QuestActivity) => (
                <QuestActivityRow
                  key={row.questId}
                  row={row}
                  onOpenViewQuestModal={() => onOpenViewQuestModal(row.quest)}
                />
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={MIN_ROWS_PER_PAGE_DEFAULT}
                rowsPerPageOptions={[]}
                onPageChange={onPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
