import React, { ChangeEventHandler, KeyboardEventHandler } from 'react'

import { Input, InputBaseComponentProps, InputProps } from '@mui/material'

import { ErrorBox, InputWrapper, LabelBox } from './styles'

type Props = {
  type?: 'text' | 'password' | 'number' | 'textarea' | 'date'
  value: string | number | null
  onChange: ChangeEventHandler<HTMLInputElement>
  onKeyDown?:
    | KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
  error?: string
  placeholder?: string
  name: string
  autocomplete?: InputProps['autoComplete']
  defaultValue?: string | number
  label?: string
  inputProps?: InputBaseComponentProps | undefined
}
const EnhancedInput = (props: Props) => {
  const {
    type,
    value,
    onChange,
    error,
    placeholder,
    name,
    autocomplete,
    defaultValue,
    label,
    inputProps,
    onKeyDown,
  } = props

  return (
    <InputWrapper>
      {label && <LabelBox> {label}</LabelBox>}
      <Input
        autoComplete={autocomplete}
        defaultValue={defaultValue}
        inputProps={inputProps}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <ErrorBox color="error"> {error}</ErrorBox>
    </InputWrapper>
  )
}

export default EnhancedInput
