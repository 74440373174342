import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled(Box)`
  padding: 15px 30px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: 10px;
`
export const Subtitle = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 4px;
`

export const Text = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`
