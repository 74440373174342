import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const ChartWrapper = styled(Box)`
  border: 1px solid #e5edfd;
  border-radius: 8px;
  background-color: #f7f9ff;
  height: 100%;

  div.google-visualization-tooltip {
    border: none;
    pointer-events: none;
  }
`
export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const SubTitle = styled(Typography)`
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
`
