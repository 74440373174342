import React, { useCallback } from 'react'
import QRCode from 'react-qr-code'

import { useMutation } from '@apollo/client'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'
import { Input } from 'common'
import { AlertMessage } from 'common/types'
import { BackupCode, TOTPFactor } from 'common/types/verification'
import { VALID_KEYS_FOR_CODE } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { FormikProps, useFormik } from 'formik'
import { ENABLE_TOTP_VERIFICATION } from 'graphql/auth/mutations'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { CodeWrapper, FormWrapper } from './styles'

enum FIELDS {
  CODE = 'code',
}
interface Props {
  factor?: TOTPFactor
  loading?: boolean
  onAlert: (alert: AlertMessage) => void
  onNextStep: () => void
  onPrevStep: () => void
  onSaveBackupCodes: (codes: BackupCode[]) => void
}
interface InitialValues {
  [FIELDS.CODE]: string
}
function QrCodeForm({
  onNextStep,
  onAlert,
  onPrevStep,
  factor,
  loading,
  onSaveBackupCodes,
}: Props) {
  const { refetchMeAdmin } = useAppContext()

  const [enableTOTPVerification, { loading: enableTOTPVerificationLoading }] =
    useMutation(ENABLE_TOTP_VERIFICATION)

  const initialValues = { [FIELDS.CODE]: '' }

  const validationSchema = yup.object().shape({
    [FIELDS.CODE]: yup
      .string()
      .length(6, 'Code must be a 6-digit number')
      .required('Code is required'),
  })

  const {
    errors,
    handleSubmit,
    values,
    resetForm,
    handleChange,
  }: FormikProps<InitialValues> = useFormik<InitialValues>({
    initialValues,
    validationSchema,
    onSubmit: (values): Promise<void> => submit(values),
  })

  const submit = useCallback(
    async (values: InitialValues) => {
      try {
        const result = await enableTOTPVerification({
          variables: {
            [FIELDS.CODE]: values[FIELDS.CODE]?.toString(),
            factorId: factor?.factorId,
          },
        })

        if (result?.data?.enableTOTPVerification) {
          onSaveBackupCodes(result?.data?.enableTOTPVerification)
        }
        await refetchMeAdmin?.()
        resetForm()
        onNextStep()
      } catch (error) {
        onAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [
      enableTOTPVerification,
      factor?.factorId,
      onAlert,
      onNextStep,
      onSaveBackupCodes,
      refetchMeAdmin,
      resetForm,
    ],
  )

  return (
    <form onSubmit={handleSubmit}>
      <Typography fontSize={18} mb={1.25} textAlign={'center'}>
        Scan the QR code using the app
      </Typography>
      <Box display="flex">
        <CodeWrapper>
          {!!factor?.uri && <QRCode value={factor.uri} />}
        </CodeWrapper>
        <FormWrapper>
          <Box mt={2}>
            <Input
              error={errors[FIELDS.CODE]}
              name={FIELDS.CODE}
              placeholder="Code"
              value={values[FIELDS.CODE]}
              onChange={handleChange}
              onKeyDown={e => {
                if (!VALID_KEYS_FOR_CODE.includes(e.key)) {
                  e.preventDefault()
                }
              }}
            />
          </Box>

          <Box>
            {' '}
            <Button
              color="primary"
              disabled={loading || enableTOTPVerificationLoading}
              fullWidth
              type={'submit'}
              variant="contained"
            >
              Set up
            </Button>
            <Button
              color="primary"
              fullWidth
              sx={{ mt: '10px' }}
              variant="outlined"
              onClick={onPrevStep}
            >
              Cancel
            </Button>
          </Box>
        </FormWrapper>
      </Box>
      {!!factor?.secret && (
        <Box mt={3}>
          <Typography>
            Trouble scanning? Enter this key in your app instead:
          </Typography>
          <Typography color="primary">{factor.secret}</Typography>
        </Box>
      )}
    </form>
  )
}

export default QrCodeForm
