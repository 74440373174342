import { PointNodeByUser } from 'common/types'
import { ChartType, commonChartOptions } from 'constants/chartOptions'
import pluralize from 'pluralize'

import map from 'lodash/map'
import sumBy from 'lodash/sumBy'

import { calculatePercentage } from './Percentage'

export const createNodeByUserChartOptions = (
  chartType: ChartType,
  chartColors: Array<string>,
) => ({
  ...commonChartOptions,
  chartArea: { width: '95%', height: 150 },
  colors: chartColors,
  pieSliceText: 'none',
})

export const generateTooltipBlock = (
  point: PointNodeByUser,
  percentage?: number,
) =>
  `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 50px;" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;"> Nodes: ${
    point.key
  }</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">Users: <span style="color: ${'#5674B3'}; font-weight: bold; padding: 0; margin: 0;">${
    point?.value
  }</span> <span>${percentage ? `(${percentage}%)` : ''}</span></p>
		</div>
		`

export function generateNodeByUserChartData(
  points: PointNodeByUser[],
  chartType: ChartType,
) {
  if (chartType === ChartType.PIE_CHART) {
    const sumUsers = sumBy(points, 'value')
    return [
      [
        'Nodes',
        'Users',
        { role: 'tooltip', type: 'string', p: { html: true } },
      ],
      ...map(points, point => [
        `${pluralize('node', Number(point?.key) ?? 0, true)}`,
        point?.value,
        generateTooltipBlock(
          point,
          calculatePercentage(point?.value ?? 0, sumUsers),
        ),
      ]),
    ]
  }

  return [
    ['Nodes', 'Users', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...map(points, point => {
      return [point?.key, point?.value, generateTooltipBlock(point)]
    }),
  ]
}
