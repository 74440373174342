import React, { MouseEvent, useCallback, useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { StakingTermsLabel, Validator } from 'common/types'
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { truncateAddress } from 'constants/truncateAddress'
import { getFormattedDate } from 'utils/Date'
import { ValidatorInfo } from 'validatorInfo'

import map from 'lodash/map'

type Props = {
  count: number
  data?: Validator[]
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onDeleteModal: (value: Validator) => void
  onCopyWallet: (wallet: string) => void
  onEditModal: (value: Validator) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

interface ValidatorsRowProps {
  row: Validator
  onDeleteModal: (value: Validator) => void
  onEditModal: (value: Validator) => void
  onCopyWallet: (wallet: string) => void
}

function ValidatorsRow({
  row,
  onDeleteModal,
  onEditModal,
  onCopyWallet,
}: ValidatorsRowProps) {
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false)
  const [isModeratorPopupOpen, setIsModeratorPopupOpen] =
    useState<HTMLButtonElement | null>(null)
  const [isClientPopupOpen, setIsClientPopupOpen] =
    useState<HTMLButtonElement | null>(null)

  const [viewDrawer, setViewDrawer] = useState<{
    isOpen: boolean
    entity: string | null
  }>({
    isOpen: false,
    entity: null,
  })

  const openModeratorPopup = (event: React.MouseEvent<HTMLButtonElement>) =>
    setIsModeratorPopupOpen(event.currentTarget)
  const openClientPopup = (event: React.MouseEvent<HTMLButtonElement>) =>
    setIsClientPopupOpen(event.currentTarget)

  const handleCloseModerator = () => setIsModeratorPopupOpen(null)
  const handleCloseClient = () => setIsClientPopupOpen(null)

  const handleOpenView = useCallback((validatorId: string) => {
    setViewDrawer({ isOpen: true, entity: validatorId })
  }, [])

  const handleCloseView = useCallback(() => {
    setViewDrawer({ isOpen: false, entity: null })
  }, [])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsCollapseOpen(!isCollapseOpen)}
          >
            {isCollapseOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Typography
            sx={{
              maxWidth: '100px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">
          <Box alignItems="center" display="flex">
            {truncateAddress(row.wallet)}
            {row.wallet && (
              <Tooltip arrow placement="top" title="Copy address to clipboard">
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => onCopyWallet?.(row.wallet)}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">{row.workers}</TableCell>
        <TableCell align="left">{((row.apy || 0) * 100)?.toFixed(2)}</TableCell>
        <TableCell align="left">{row.isApproved ? 'YES' : 'No'}</TableCell>
        <TableCell align="left">
          {row?.nodePeriod ? StakingTermsLabel[row?.nodePeriod] : '--'}
        </TableCell>
        <TableCell align="left">{getFormattedDate(row.createdAt)}</TableCell>
        <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
          <InfoIcon
            className="itemIcon"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleOpenView(row.id)}
          />
          <EditIcon
            className="itemIcon"
            sx={{ ml: '10px', cursor: 'pointer' }}
            onClick={() => onEditModal(row)}
          />
          <ClearIcon
            className="itemIcon"
            sx={{ ml: '10px', cursor: 'pointer' }}
            onClick={() => onDeleteModal(row)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={10}
          style={{
            padding: 0,
            backgroundColor: '#f1f1f1',
          }}
        >
          <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
            <Box sx={{ mb: 1 }}>
              <Table aria-label="purchases" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Review Status</TableCell>
                    {row.reviewStatus === 'completed' && (
                      <>
                        <TableCell align="left">Application Id</TableCell>
                        <TableCell align="left">Review reject type</TableCell>
                        <TableCell align="left">Review answer</TableCell>
                        <TableCell align="left">Reject labels</TableCell>
                        <TableCell align="left">Moderation comment</TableCell>
                        <TableCell align="left">Client comment</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.reviewStatus === 'completed' ? (
                    <TableRow>
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {capitalizeFirstLetter(row.reviewStatus)}
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {row.applicantId || '—'}
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {row.reviewRejectType || '—'}
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {row.reviewAnswer || '—'}
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {row?.rejectLabels?.length ? (
                          <ul>
                            {map(row.rejectLabels, (item: string) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          '—'
                        )}
                      </TableCell>
                      {/* Moderator Popup */}
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {row.moderationComment ? (
                          <Button
                            aria-describedby={row.moderationComment}
                            size="small"
                            variant="text"
                            onClick={openModeratorPopup}
                          >
                            Show
                          </Button>
                        ) : (
                          '—'
                        )}

                        <Popover
                          anchorEl={isModeratorPopupOpen}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          id={row.moderationComment}
                          open={Boolean(isModeratorPopupOpen)}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          onClose={handleCloseModerator}
                        >
                          <Typography sx={{ p: 2 }}>
                            {row.moderationComment}
                          </Typography>
                        </Popover>
                      </TableCell>
                      {/* Client Popup */}
                      <TableCell align="left" sx={{ borderBottom: 'none' }}>
                        {row.clientComment ? (
                          <Button
                            aria-describedby={row.clientComment}
                            size="small"
                            variant="text"
                            onClick={openClientPopup}
                          >
                            Show
                          </Button>
                        ) : (
                          '—'
                        )}
                        <Popover
                          anchorEl={isClientPopupOpen}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          id={row.clientComment}
                          open={Boolean(isClientPopupOpen)}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          onClose={handleCloseClient}
                        >
                          <Typography sx={{ p: 2 }}>
                            {row.clientComment}
                          </Typography>
                        </Popover>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        Sumsub verification is pending
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Drawer anchor="right" open={viewDrawer.isOpen} onClose={handleCloseView}>
        <ValidatorInfo
          validatorId={viewDrawer.entity}
          onClose={handleCloseView}
        />
      </Drawer>
    </>
  )
}

// todo return nodes column => should be added on backend side workers
function BasicTable({
  count,
  data,
  page,
  rowsPerPage,
  onPageChange,
  onDeleteModal,
  onEditModal,
  onRowsPerPageChange,
  onCopyWallet,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="Validators table" size="small" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Sumsub</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Wallet</TableCell>
            <TableCell align="left">Nodes</TableCell>
            <TableCell align="left">Projected Gross APY</TableCell>
            <TableCell align="left">Approved</TableCell>
            <TableCell align="left">Term(actual)</TableCell>
            <TableCell align="left">Created at</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {map(data, (row: Validator) => (
                <ValidatorsRow
                  key={row.id}
                  row={row}
                  onCopyWallet={onCopyWallet}
                  onDeleteModal={onDeleteModal}
                  onEditModal={onEditModal}
                />
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
