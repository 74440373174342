import { ApolloLink } from '@apollo/client'
import { getToken } from 'services/token'

export default function createAuthLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = getToken()

      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null,
        },
      }
    })

    return forward(operation)
  })
}
