import { ME_ADMIN } from 'graphql/auth/queries'
import Shared from 'services/Shared'

import { setRefreshToken } from './refreshToken'
import { setToken } from './token'

function notifyLinks(token: string | null) {
  const subscriptionLink = Shared.getWebSocketLink()
  if (subscriptionLink) subscriptionLink.onTokenChanged(token)
}

async function logOut() {
  localStorage.clear()

  await Shared.getApolloClient().resetStore()

  notifyLinks(null)
}

async function handleAuth(accessToken: string, refreshToken: string) {
  setToken(accessToken)
  setRefreshToken(refreshToken)

  const client = Shared.getApolloClient()
  await client.query({
    query: ME_ADMIN,
  })

  notifyLinks(accessToken)
}

const Auth = { handleAuth, logOut }

export default Auth
