import { gql } from '@apollo/client'

import { QUEST_FIELDS } from './fragments'

export const CREATE_QUEST = gql`
  mutation CreateQuest($createQuestData: CreateQuest!) {
    createQuest(createQuestData: $createQuestData) {
      ...QuestFields
    }
  }
  ${QUEST_FIELDS}
`

export const UPDATE_QUEST = gql`
  mutation UpdateQuest($id: String!, $quest: UpdateQuest!) {
    updateQuest(id: $id, quest: $quest) {
      ...QuestFields
    }
  }
  ${QUEST_FIELDS}
`

export const DELETE_QUEST = gql`
  mutation DeleteQuest($id: String!) {
    deleteQuest(id: $id)
  }
`
