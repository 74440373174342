import { Box, Paper } from '@mui/material'
import { styled } from '@mui/system'

export const StyledPaper = styled(Paper)({
  background: 'white',
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto',
  padding: '15px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const Title = styled(Box)`
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
  max-width: 100%;
  text-overflow: ellipsis;
`
export const Content = styled(Box)({
  width: '100%',
  margin: '10px auto',
  height: '100%',
  overflow: 'hidden',
})
