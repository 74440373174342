import React from 'react'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'

interface Props {
  onNextStep: () => void
  authMethod: string
}

function SuccessForm({ onNextStep, authMethod }: Props) {
  return (
    <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
      <CheckCircleOutlineIcon color="success" style={{ fontSize: 72 }} />
      <Typography fontSize={18} fontWeight={600} mt={2}>
        Two-factor authentication activated
      </Typography>
      <Typography mt={0.5}>{`Method:${authMethod}`}</Typography>
      <Button
        color="primary"
        fullWidth
        sx={{ mt: 3 }}
        variant="contained"
        onClick={onNextStep}
      >
        Got it
      </Button>
    </Box>
  )
}

export default SuccessForm
