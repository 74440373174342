import styled from '@emotion/styled'
import { Box, Paper } from '@mui/material'

export const PageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

export const FormWrapper = styled(Paper)`
  width: 100%;
  max-width: 500px;
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
`
