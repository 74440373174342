import React, { ReactNode, useCallback, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined'
import LineAxisOutlinedIcon from '@mui/icons-material/LineAxisOutlined'
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import { AccordionDetails, Box, Divider } from '@mui/material'
import MorpheusFormIcon from 'assets/icons/morpheus_form_icon_dark.svg'
import { LogoutButton } from 'common'
import { AdminRole } from 'common/types'
import { useAppContext } from 'context/AppContext'
import { Routes } from 'router/routes'

import { some } from 'lodash'

import {
  MenuItem,
  MenuWrapper,
  StyledContainer,
  StyledLink,
  StyledNavBar,
} from './styles'

type Route = {
  id: string
  label: string
  path?: string
  icon: ReactNode
}

type NavMenuItem = Route & {
  submenuItems?: Route[]
}

const COMMON_ROUTES: NavMenuItem[] = [
  {
    id: '1-Overview',
    label: 'Overview',
    path: Routes.ROOT,
    icon: <LineAxisOutlinedIcon />,
  },
  {
    id: '2-System Info',
    label: 'System Info',
    path: Routes.STATS,
    icon: <QueryStatsIcon />,
  },
  {
    id: '3-Users',
    label: 'Users',
    icon: <GroupAddOutlinedIcon />,
    submenuItems: [
      {
        id: '3-1-Admins',
        label: 'Admins',
        path: Routes.ADMINS,
        icon: <AdminPanelSettingsOutlinedIcon />,
      },
      {
        id: '3-2-Moderators',
        label: 'Moderators',
        path: Routes.MODERATORS,
        icon: <LocalPoliceOutlinedIcon />,
      },
      {
        id: '3-3-Regular Admins',
        label: 'Regular Admins',
        path: Routes.REGULAR_ADMINS,
        icon: <PeopleAltOutlinedIcon />,
      },
      {
        id: '3-4-Validators',
        label: 'Validators',
        path: Routes.VALIDATORS,
        icon: <SupervisorAccountOutlinedIcon />,
      },
    ],
  },

  {
    id: '4-Validation',
    label: 'Validation',
    icon: <CloudSyncOutlinedIcon />,
    submenuItems: [
      {
        id: '4-1-Clients',
        label: 'Clients',
        path: Routes.CLIENTS,
        icon: <VpnKeyOutlinedIcon />,
      },
      {
        id: '4-2-Nodes',
        label: 'Nodes',
        path: Routes.NODES,
        icon: <MemoryOutlinedIcon />,
      },
      {
        id: '4-3-Providers',
        label: 'Providers',
        path: Routes.PROVIDERS,
        icon: <DnsOutlinedIcon />,
      },
      {
        id: '4-4-Validations',
        label: 'Validations',
        path: Routes.VAIDATIONS,
        icon: <VerifiedUserOutlinedIcon />,
      },
      {
        id: '4-5-Queries',
        label: 'Queries',
        path: Routes.QUERIES,
        icon: <TrackChangesOutlinedIcon />,
      },
    ],
  },

  {
    id: '5-Rewards',
    label: 'Rewards',
    icon: <PaymentsOutlinedIcon />,
    submenuItems: [
      {
        id: '5-1-Clients',
        label: 'Overview',
        path: Routes.REWARDS,
        icon: <LineAxisOutlinedIcon />,
      },
      {
        id: '5-2-Nodes',
        label: 'Calendar',
        path: Routes.CALENDAR,
        icon: <CalendarMonthOutlined />,
      },
    ],
  },
  {
    id: '6-Announcements',
    label: 'Announcements',
    path: Routes.ANNOUNCEMENTS,
    icon: <NewspaperIcon />,
  },
  {
    id: '7-Quests',
    label: 'Quests',
    path: Routes.QUESTS,
    icon: <QuestionAnswerIcon />,
  },
  {
    id: '8-Settings',
    label: 'Settings',
    path: Routes.SETTINGS,
    icon: <SettingsSuggestOutlinedIcon />,
  },
]

const MODERATOR_ROUTES = [
  {
    id: '1-Quests',
    label: 'Quests',
    path: Routes.QUESTS,
    icon: <QuestionAnswerIcon />,
  },
]

const REGULAR_ADMIN_ROUTES: NavMenuItem[] = [
  {
    id: '1-Overview',
    label: 'Overview',
    path: Routes.ROOT,
    icon: <LineAxisOutlinedIcon />,
  },
  {
    id: '2-System Info',
    label: 'System Info',
    path: Routes.STATS,
    icon: <QueryStatsIcon />,
  },
  {
    id: '3-Validators',
    label: 'Validators',
    path: Routes.VALIDATORS,
    icon: <GroupAddOutlinedIcon />,
  },
  {
    id: '4-Validation',
    label: 'Validation',
    icon: <CloudSyncOutlinedIcon />,
    submenuItems: [
      {
        id: '4-1-Nodes',
        label: 'Nodes',
        path: Routes.NODES,
        icon: <MemoryOutlinedIcon />,
      },
      {
        id: '4-2-Providers',
        label: 'Providers',
        path: Routes.PROVIDERS,
        icon: <DnsOutlinedIcon />,
      },
      {
        id: '4-3-Validations',
        label: 'Validations',
        path: Routes.VAIDATIONS,
        icon: <VerifiedUserOutlinedIcon />,
      },
      {
        id: '4-4-Queries',
        label: 'Queries',
        path: Routes.QUERIES,
        icon: <TrackChangesOutlinedIcon />,
      },
    ],
  },
  {
    id: '5-Rewards',
    label: 'Rewards',
    icon: <PaymentsOutlinedIcon />,
    submenuItems: [
      {
        id: '5-1-Clients',
        label: 'Overview',
        path: Routes.REWARDS,
        icon: <LineAxisOutlinedIcon />,
      },
      {
        id: '5-2-Nodes',
        label: 'Calendar',
        path: Routes.CALENDAR,
        icon: <CalendarMonthOutlined />,
      },
    ],
  },
  {
    id: '6-Announcements',
    label: 'Announcements',
    path: Routes.ANNOUNCEMENTS,
    icon: <NewspaperIcon />,
  },
  {
    id: '7-Quests',
    label: 'Quests',
    path: Routes.QUESTS,
    icon: <QuestionAnswerIcon />,
  },
  {
    id: '8-Settings',
    label: 'Settings',
    path: Routes.SETTINGS,
    icon: <SettingsSuggestOutlinedIcon />,
  },
]

function MainLayout() {
  const { meAdmin } = useAppContext()
  const location = useLocation()

  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const navigationMenuItems: NavMenuItem[] = useMemo(() => {
    if (meAdmin?.role === AdminRole.MODERATOR) {
      return MODERATOR_ROUTES
    }
    if (meAdmin?.role === AdminRole.REGULAR_ADMIN) {
      return REGULAR_ADMIN_ROUTES
    }
    return COMMON_ROUTES
  }, [meAdmin])

  const renderMenuItem = useCallback(
    (menuItem: Route) =>
      menuItem?.path ? (
        <StyledLink key={menuItem.id} to={menuItem.path}>
          {menuItem.icon}
          <Box sx={{ mb: '2px' }}>{menuItem.label}</Box>
        </StyledLink>
      ) : (
        <StyledContainer key={menuItem.label}>
          {menuItem.icon}
          <Box sx={{ mb: '2px' }}>{menuItem.label}</Box>
        </StyledContainer>
      ),
    [],
  )

  return (
    <Box display="flex" flexGrow={3} height="100%">
      <StyledNavBar>
        <Box sx={{ my: '20px', display: 'flex', justifyContent: 'center' }}>
          <img alt="MNW" src={MorpheusFormIcon} width={30} />
        </Box>
        {navigationMenuItems?.map(menuItem => (
          <MenuWrapper
            disableGutters
            expanded={expanded === menuItem.label}
            key={menuItem.id}
            sx={{ my: 0, mx: 0 }}
            onChange={handleChange(menuItem.label)}
          >
            <MenuItem
              active={some(
                menuItem?.submenuItems,
                subMenuItem => subMenuItem.path === location.pathname,
              )}
              expandIcon={!!menuItem?.submenuItems && <ExpandMoreIcon />}
            >
              {renderMenuItem(menuItem)}
            </MenuItem>
            {menuItem?.submenuItems && (
              <AccordionDetails sx={{ m: 0, p: 0 }}>
                {menuItem.submenuItems.map(menuItem =>
                  renderMenuItem(menuItem),
                )}
              </AccordionDetails>
            )}
          </MenuWrapper>
        ))}
        <Divider />
        <LogoutButton />
      </StyledNavBar>
      <Outlet />
    </Box>
  )
}

export default MainLayout
