import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import { Box } from '@mui/system'
import { Input, LoaderHolder } from 'common'
import { AlertMessage } from 'common/types'
import { FormikProps, useFormik } from 'formik'
import { UPDATE_SYSTEM_SETTINGS } from 'graphql/settings/mutations'
import { SYSTEM_SETTINGS } from 'graphql/settings/queries'
import ConfirmModal from 'pages/settings/Modals/confirmModal'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { Content } from './styles'
import { SystemSettings } from './types'

enum FIELDS {
  CLI_VERSION = 'cliVersion',
  DAILY_VALIDATIONS_CAP_FROM = 'dailyValidationsCapFrom',
  DAILY_VALIDATIONS_CAP_TO = 'dailyValidationsCapTo',
  GAS_EMAILS_LIST = 'gasEmailsList',
}

interface Props {
  onAlert: (alert: AlertMessage) => void
  openConfirmModal: boolean
  onCloseConfirmModal: () => void
}

const MIN_DAILY_VALIDATIONS_CAP = 10000
const MAX_DAILY_VALIDATIONS_CAP = 30000

function SettingsForm({
  onAlert,
  onCloseConfirmModal,
  openConfirmModal,
}: Props) {
  const [initialValues, setInitialValues] = useState<SystemSettings>({
    [FIELDS.DAILY_VALIDATIONS_CAP_FROM]: 0,
    [FIELDS.DAILY_VALIDATIONS_CAP_TO]: 0,
    [FIELDS.CLI_VERSION]: '',
    [FIELDS.GAS_EMAILS_LIST]: [],
  })

  const [loadSystemSettings, { loading, data }] = useLazyQuery(SYSTEM_SETTINGS)

  const currentDailyValidationsCap = useMemo(
    () => data?.systemSettings?.currentDailyValidationsCap || 0,
    [data],
  )

  const fetchSystemSettings = useCallback(async () => {
    try {
      const response = await loadSystemSettings()
      if (response?.data?.systemSettings) {
        const dailyValidationsCapFrom =
          response?.data?.systemSettings?.dailyValidationsCap?.split(
            ',',
          )?.[0] || 0
        const dailyValidationsCapTo =
          response?.data?.systemSettings?.dailyValidationsCap?.split(
            ',',
          )?.[1] || 0
        setInitialValues({
          ...response?.data?.systemSettings,
          [FIELDS.DAILY_VALIDATIONS_CAP_FROM]: dailyValidationsCapFrom,
          [FIELDS.DAILY_VALIDATIONS_CAP_TO]: dailyValidationsCapTo,
          [FIELDS.GAS_EMAILS_LIST]: response?.data?.systemSettings
            ?.gasEmailsList
            ? response?.data?.systemSettings?.gasEmailsList
                ?.split(',')
                .map((email: string) => email.trim())
            : [],
        })
      }
    } catch (error) {
      onAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }, [loadSystemSettings, onAlert])

  useEffect(() => {
    fetchSystemSettings().then()
  }, [fetchSystemSettings])

  const [saveSettings, { loading: saveLoading }] = useMutation(
    UPDATE_SYSTEM_SETTINGS,
  )

  const validationSchema = yup.object({
    [FIELDS.DAILY_VALIDATIONS_CAP_FROM]: yup
      .number()
      .integer('Must be an integer')
      .min(
        MIN_DAILY_VALIDATIONS_CAP,
        `Must be greater than or equal to ${MIN_DAILY_VALIDATIONS_CAP}`,
      )
      .max(
        MAX_DAILY_VALIDATIONS_CAP,
        `Must be less than or equal to ${MAX_DAILY_VALIDATIONS_CAP}`,
      )
      .required('Field is required'),
    [FIELDS.DAILY_VALIDATIONS_CAP_TO]: yup
      .number()
      .integer('Must be an integer')
      .min(
        MIN_DAILY_VALIDATIONS_CAP,
        `Must be greater than or equal to ${MIN_DAILY_VALIDATIONS_CAP}`,
      )
      .max(
        MAX_DAILY_VALIDATIONS_CAP,
        `Must be less than or equal to ${MAX_DAILY_VALIDATIONS_CAP}`,
      )
      .required('Field is required')
      .test(
        'is-greater-or-equal',
        "'To' value must be greater than or equal to 'From' value",
        (value, context) => {
          const { [FIELDS.DAILY_VALIDATIONS_CAP_FROM]: fromValue } =
            context.parent
          if (fromValue !== undefined && value !== undefined) {
            return value >= fromValue
          }
          return true
        },
      ),
    [FIELDS.CLI_VERSION]: yup
      .string()
      .required('CLI version field is required'),
    [FIELDS.GAS_EMAILS_LIST]: yup
      .array()
      .of(yup.string().email('Invalid email address'))
      .min(1, 'At least one email is required')
      .required('Required'),
  })

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    isValid,
    setFieldValue,
    resetForm,
  }: FormikProps<SystemSettings> = useFormik<SystemSettings>({
    initialValues,
    onSubmit: (values): Promise<void> => submit(values),
    validationSchema,
    enableReinitialize: true,
  })

  const submit = useCallback(
    async (values: SystemSettings) => {
      try {
        const response = await saveSettings({
          variables: {
            ...values,
            dailyValidationsCap: [
              values[FIELDS.DAILY_VALIDATIONS_CAP_FROM],
              values[FIELDS.DAILY_VALIDATIONS_CAP_TO],
            ]?.join(','),
            [FIELDS.GAS_EMAILS_LIST]: values[FIELDS.GAS_EMAILS_LIST]?.join(','),
          },
          refetchQueries: [{ query: SYSTEM_SETTINGS }],
        })

        const dailyValidationsCapFrom =
          response?.data?.updateSystemSettings?.dailyValidationsCap?.split(
            ',',
          )?.[0] || 0
        const dailyValidationsCapTo =
          response?.data?.updateSystemSettings?.dailyValidationsCap?.split(
            ',',
          )?.[1] || 0

        setInitialValues({
          ...response?.data?.updateSystemSettings,
          [FIELDS.DAILY_VALIDATIONS_CAP_FROM]: dailyValidationsCapFrom,
          [FIELDS.DAILY_VALIDATIONS_CAP_TO]: dailyValidationsCapTo,
          [FIELDS.GAS_EMAILS_LIST]: response?.data?.updateSystemSettings
            ?.gasEmailsList
            ? response?.data?.updateSystemSettings.gasEmailsList
                ?.split(',')
                .map((email: string) => email.trim())
            : [],
        })
        resetForm()
        onAlert({
          isOpen: true,
          text: 'Settings saved successfully',
          alertColor: 'success',
        })
      } catch (error) {
        onAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
      onCloseConfirmModal()
    },
    [onAlert, onCloseConfirmModal, resetForm, saveSettings],
  )

  const [inputValue, setInputValue] = useState('')

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        const email = inputValue.trim()
        if (email && !values?.[FIELDS.GAS_EMAILS_LIST]?.includes(email)) {
          setFieldValue(FIELDS.GAS_EMAILS_LIST, [
            ...(values?.[FIELDS.GAS_EMAILS_LIST] || []),
            email,
          ])
          setInputValue('')
        }
        event.preventDefault()
      }
    },
    [inputValue, setFieldValue, values],
  )

  const handleDelete = useCallback(
    (emailToDelete: string) => {
      setFieldValue(
        FIELDS.GAS_EMAILS_LIST,
        values[FIELDS.GAS_EMAILS_LIST]?.filter(
          (email: string) => email !== emailToDelete,
        ),
      )
    },
    [setFieldValue, values],
  )

  const helperText = useMemo(() => {
    const fieldErrors = errors?.[FIELDS.GAS_EMAILS_LIST] as
      | string[]
      | string
      | undefined

    if (fieldErrors) {
      if (typeof fieldErrors === 'string') {
        return fieldErrors
      }
      if (Array.isArray(fieldErrors)) {
        return fieldErrors?.length > 0 ? 'Some emails are invalid' : ''
      }
    }
    return ''
  }, [errors])

  return (
    <>
      {loading ? (
        <LoaderHolder my={19}>
          <CircularProgress />
        </LoaderHolder>
      ) : (
        <Content>
          <Typography>
            Daily validations cap for today is:
            <Typography component="span" fontWeight="bold">
              {' '}
              {currentDailyValidationsCap.toLocaleString()}
            </Typography>{' '}
            based on yesterday range
          </Typography>
          <Typography sx={{ my: 2 }}>
            Enter range for tomorrow&apos;s cap and save
          </Typography>
          <Box
            alignItems="flex-start"
            display={'flex'}
            gap={2}
            sx={{ mb: 1, mt: 2 }}
          >
            <Box width="230px">
              <Input
                error={errors[FIELDS.DAILY_VALIDATIONS_CAP_FROM]}
                label="From"
                name={FIELDS.DAILY_VALIDATIONS_CAP_FROM}
                type="number"
                value={values[FIELDS.DAILY_VALIDATIONS_CAP_FROM]}
                onChange={handleChange}
              />
            </Box>

            <Box mt={2}>—</Box>
            <Box width="230px">
              <Input
                error={errors[FIELDS.DAILY_VALIDATIONS_CAP_TO]}
                label="To"
                name={FIELDS.DAILY_VALIDATIONS_CAP_TO}
                type="number"
                value={values[FIELDS.DAILY_VALIDATIONS_CAP_TO]}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Typography color="textSecondary" sx={{ mb: 2, fontSize: '12px' }}>
            *The system will randomize a value between these inputs for
            tomorrow.
          </Typography>
          <Box sx={{ mb: 4, mt: 3 }}>
            <Input
              error={errors[FIELDS.CLI_VERSION]}
              label="CLI version"
              name={FIELDS.CLI_VERSION}
              value={values[FIELDS.CLI_VERSION]}
              onChange={handleChange}
            />
          </Box>

          <Autocomplete
            freeSolo
            inputValue={inputValue}
            multiple
            options={[]}
            renderInput={params => (
              <TextField
                {...params}
                error={Boolean(errors[FIELDS.GAS_EMAILS_LIST])}
                helperText={helperText}
                label="Emails (Enter email and press Enter)"
                placeholder="Enter email and press Enter"
                variant="standard"
                onKeyDown={handleKeyDown}
              />
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index })
                const hasError =
                  errors[FIELDS.GAS_EMAILS_LIST] &&
                  Array.isArray(errors[FIELDS.GAS_EMAILS_LIST]) &&
                  !!errors?.[FIELDS.GAS_EMAILS_LIST]?.[index]
                return (
                  <Chip
                    key={key}
                    label={option}
                    variant="outlined"
                    {...tagProps}
                    color={hasError ? 'error' : 'default'}
                    onDelete={() => handleDelete(option)}
                  />
                )
              })
            }
            sx={{ mb: 3 }}
            value={values[FIELDS.GAS_EMAILS_LIST]}
            onChange={(_, __: string[], reason: AutocompleteChangeReason) =>
              reason === 'clear' && setFieldValue(FIELDS.GAS_EMAILS_LIST, [])
            }
            onInputChange={(event, newInputValue) =>
              setInputValue(newInputValue)
            }
          />
        </Content>
      )}
      <ConfirmModal
        isValid={isValid}
        loading={saveLoading}
        open={openConfirmModal}
        onClose={onCloseConfirmModal}
        onConfirm={handleSubmit}
      />
    </>
  )
}

export default SettingsForm
