import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { Routes } from 'router/routes'
import Auth from 'services/Auth'

import { CustomLogoutButton } from './styles'

import { AuthContext } from '../../index'

const LogoutButton = () => {
  const navigate = useNavigate()
  const { setAuthorized } = useContext(AuthContext)

  const onLogoutHandler = () => {
    Auth.logOut()
    setAuthorized(false)
    navigate(Routes.LOGIN)
  }

  return (
    <CustomLogoutButton onClick={onLogoutHandler}>
      <ExitToAppIcon style={{ fontSize: '18px', width: '30px' }} />
      Exit
    </CustomLogoutButton>
  )
}

export default LogoutButton
