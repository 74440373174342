import React, { MouseEvent, useCallback } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { Box, TablePagination, Tooltip } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Quest } from 'common/types'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/params'
import { DateTime } from 'luxon'
import { getFormattedDate } from 'utils/Date'

import { noop } from 'lodash'

import { TextEllipsis } from './styles'

type Props = {
  count: number
  data?: Quest[]
  onEdit: (rowData: Quest) => void
  onDelete: (id: string) => void
  onView?: (rowData: Quest) => void
  page: number
  rowsPerPage: number
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => void
  onRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

export default function BasicTable({
  data = [],
  onEdit,
  onDelete,
  onView,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: Props) {
  const canChange = useCallback(
    (publishDate: string) => DateTime.fromISO(publishDate) >= DateTime.now(),
    [],
  )

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '12px',
      }}
    >
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Content</TableCell>
            <TableCell>Twitter ID</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Publish at</TableCell>
            <TableCell>Expired at</TableCell>
            <TableCell align={'center'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data?.length ? (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                The list is empty
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.map(row => (
                <TableRow
                  key={row?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <TextEllipsis
                      noWrap
                      style={{
                        width: '100px',
                      }}
                    >
                      {row?.title}
                    </TextEllipsis>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextEllipsis
                      noWrap
                      style={{
                        width: '150px',
                      }}
                    >
                      {row?.content}
                    </TextEllipsis>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.twitterId}
                  </TableCell>
                  <TableCell>{getFormattedDate(row?.createdAt)}</TableCell>
                  <TableCell>
                    {row?.publishDate
                      ? getFormattedDate(row?.publishDate)
                      : '__'}
                  </TableCell>
                  <TableCell>
                    {row?.expirationDate
                      ? getFormattedDate(row?.expirationDate)
                      : '__'}
                  </TableCell>

                  <TableCell align="center">
                    <Box display="flex" justifyContent="center" width="100%">
                      <Tooltip title="View details">
                        <InfoIcon
                          className="itemIcon"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => onView?.(row)}
                        />
                      </Tooltip>
                      <Tooltip title={'Edit'}>
                        <EditIcon
                          className="itemIcon"
                          sx={{
                            ml: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => onEdit(row)}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          canChange(row?.publishDate)
                            ? 'Delete'
                            : 'Function unavailable.Quest was published.'
                        }
                      >
                        <ClearIcon
                          className="itemIcon"
                          color={
                            canChange(row?.publishDate) ? 'inherit' : 'disabled'
                          }
                          sx={{
                            ml: '10px',
                            cursor: canChange(row?.publishDate)
                              ? 'pointer'
                              : 'not-allowed',
                          }}
                          onClick={
                            canChange(row?.publishDate)
                              ? () => onDelete(row?.id)
                              : noop
                          }
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TablePagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
