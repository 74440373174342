import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { LoaderHolder } from 'common'
import { MnwDailyStatsTable } from 'common/tables'
import { Range } from 'common/types'
import { SYSTEM_DAILY_STATS } from 'graphql/systemDailyStats/queries'

interface Props {
  range: Range
}
function StatsTableContainer({ range }: Props) {
  const { data, loading } = useQuery(SYSTEM_DAILY_STATS, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const systemStatsData = useMemo(
    () => data?.systemDailyStats?.data || [],
    [data],
  )

  return (
    <>
      {loading ? (
        <LoaderHolder>
          <CircularProgress />
        </LoaderHolder>
      ) : (
        <MnwDailyStatsTable data={systemStatsData} />
      )}
    </>
  )
}

export default StatsTableContainer
