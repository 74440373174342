import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material'
import { Alert } from 'common'
import { ALERT_DELAY } from 'constants/params'
import { truncateAddress } from 'constants/truncateAddress'
import { UPDATE_VALIDATOR } from 'graphql/validators/mutations'
import { GET_VALIDATOR } from 'graphql/validators/queries'

import { Container, InfoContainer } from './styles'
import ValidatorTables from './tables'

interface Props {
  validatorId: string | null
  onClose?: () => void
}
function ValidatorInfo({ validatorId, onClose }: Props) {
  const [loadValidator, { data, loading }] = useLazyQuery(GET_VALIDATOR)

  const fetchValidator = useCallback(
    (getValidatorId: string) =>
      loadValidator({ variables: { getValidatorId } }),
    [loadValidator],
  )

  const [updateValidator, updateValidatorResult] = useMutation(UPDATE_VALIDATOR)

  const handleResetNode = useCallback(async () => {
    await updateValidator({
      variables: {
        updateValidatorId: validatorId,
        updateValidatorData: { nodeAppId: null },
      },
      refetchQueries: [
        {
          query: GET_VALIDATOR,
          variables: { getValidatorId: validatorId },
        },
      ],
    })
  }, [updateValidator, validatorId])

  useEffect(() => {
    if (validatorId) {
      fetchValidator(validatorId).then()
    }
  }, [fetchValidator, validatorId])

  const validatorData = useMemo(() => data?.getValidator || {}, [data])

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const handleCopyField = (field: string, text: string) => {
    navigator.clipboard.writeText(field)
    setAlert({
      isOpen: true,
      text,
      alertColor: 'success',
    })
  }

  return (
    <Box width={'95vw'}>
      <Container>
        <Box mb={3} position="relative">
          <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
            Validator details
          </Typography>

          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 0,
              color: '#A9C1D6',
            }}
            onClick={onClose}
          >
            <CancelRoundedIcon fontSize="inherit" />
          </IconButton>
        </Box>

        {loading ? (
          <Box
            alignItems="center"
            display="flex"
            height={1}
            justifyContent="center"
            width={1}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <InfoContainer>
              <Box alignItems="center" display="flex">
                <Box display="flex" flexDirection="column" mr={6}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: '#727272',
                      lineHeight: '16px',
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, fontSize: 14, lineHeight: '20px' }}
                  >
                    {validatorData?.email || '--'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: 12,
                      color: '#727272',
                      lineHeight: '16px',
                    }}
                  >
                    Wallet
                  </Typography>
                  <Box alignItems="center" display="flex">
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 14, lineHeight: '20px' }}
                    >
                      {truncateAddress(validatorData?.wallet)}
                    </Typography>
                    {validatorData?.wallet && (
                      <ContentCopyIcon
                        sx={{ height: 12, cursor: 'pointer', color: '#666666' }}
                        onClick={() =>
                          handleCopyField(
                            validatorData?.wallet,
                            'The wallet was copied',
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box alignItems="center" display="flex">
                {validatorData?.nodeAppId && (
                  <Button
                    color="error"
                    disabled={updateValidatorResult.loading}
                    variant="outlined"
                    onClick={handleResetNode}
                  >
                    Reset Node
                  </Button>
                )}
              </Box>
            </InfoContainer>
            <Divider sx={{ mt: 3 }} />
            <ValidatorTables
              validatorId={validatorData?.id}
              onCopyAddress={handleCopyField}
            />
          </>
        )}
      </Container>

      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ValidatorInfo
