import { gql } from '@apollo/client'
import { PHASE_FIELDS } from 'graphql/phase/fragments/phase'

export const CREATE_PHASE = gql`
  mutation CreatePhase($endDate: DateTime!, $startDate: DateTime!) {
    createPhase(endDate: $endDate, startDate: $startDate) {
      ...PhaseFields
    }
  }
  ${PHASE_FIELDS}
`

export const UPDATE_PHASE = gql`
  mutation UpdatePhase($id: ID!, $endDate: DateTime!, $startDate: DateTime!) {
    updatePhase(id: $id, endDate: $endDate, startDate: $startDate) {
      ...PhaseFields
    }
  }
  ${PHASE_FIELDS}
`
