import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const VerificationOptionWrapper = styled(Box)`
  display: flex;
  position: relative;
  padding: 20px;
  cursor: pointer;
  margin: auto -19px;
  svg {
    margin-right: 15px;
  }
  :hover {
    background-color: #e5edfd;
  }
  :after {
    bottom: 0;
    border-bottom: 1px solid rgb(218, 220, 224);
    content: '';
    height: 0;
    left: 20px;
    right: 20px;
    position: absolute;
  }
`
