import React, { useCallback } from 'react'

import ContentCutIcon from '@mui/icons-material/ContentCut'
import DownloadIcon from '@mui/icons-material/Download'
import PrintIcon from '@mui/icons-material/Print'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'
import { BackupCode } from 'common/types/verification'
import { getFormattedDate } from 'utils/Date'

import map from 'lodash/map'

import {
  BackupCodesWrapper,
  CodeItem,
  CodesContainer,
  IconWrapper,
} from './styles'

interface Props {
  backupCodes: BackupCode[]
  onNextStep: () => void
}

function BackupCodesForm({ onNextStep, backupCodes }: Props) {
  const handlePrintCodes = useCallback(() => window.print(), [])

  const handleDownloadCodes = useCallback(() => {
    const codesText = `SAVE YOUR BACKUP CODES
Keep your backup codes in a secure place.
${map(backupCodes, (code, index) => `${index + 1}. ${code.code}`).join('\n')}

* Each backup code can be used only once.
* Date created: ${getFormattedDate(backupCodes[0]?.createdAt)}.`

    const blob = new Blob([codesText], { type: 'text/plain' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'backup-codes.txt'
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }, [backupCodes])

  return (
    <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
      <Typography fontSize={18} mb={1.25} textAlign={'center'}>
        Keep these backup codes
      </Typography>
      <Typography fontWeight={600}>Backup codes are shown only once</Typography>
      <Typography fontWeight={600}>Please save them securely</Typography>
      <BackupCodesWrapper>
        <IconWrapper>
          <ContentCutIcon />
        </IconWrapper>
        <CodesContainer>
          {map(backupCodes, (code: BackupCode) => (
            <CodeItem key={code.id}>{code.code}</CodeItem>
          ))}
        </CodesContainer>
        <Box p={2.5}>
          <Typography textAlign="center">
            You can only use each backup code once
          </Typography>
          {backupCodes[0]?.createdAt && (
            <Typography pb={1} textAlign="center">
              These codes were generated on:{' '}
              {getFormattedDate(backupCodes[0]?.createdAt)}
            </Typography>
          )}

          <Box display={'flex'} gap={2} mt={2.5}>
            <Button
              color="primary"
              fullWidth
              startIcon={<PrintIcon />}
              variant="contained"
              onClick={handlePrintCodes}
            >
              Print
            </Button>
            <Button
              color="primary"
              fullWidth
              startIcon={<DownloadIcon />}
              variant="contained"
              onClick={handleDownloadCodes}
            >
              Download
            </Button>
          </Box>
        </Box>
      </BackupCodesWrapper>

      <Button
        color="primary"
        fullWidth
        sx={{ mt: 3 }}
        variant="contained"
        onClick={onNextStep}
      >
        Got it
      </Button>
    </Box>
  )
}

export default BackupCodesForm
