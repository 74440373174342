import React, { useCallback, useState } from 'react'

import { useMutation } from '@apollo/client'
import RefreshIcon from '@mui/icons-material/Refresh'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import { Box, Switch, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { AlertMessage } from 'common/types'
import { BackupCode } from 'common/types/verification'
import { useAppContext } from 'context/AppContext'
import { UPDATE_BACKUP_CODES } from 'graphql/auth/mutations'
import { getErrorMessage } from 'utils/Error'

import {
  ConnectTOTPAuth,
  DisconnectTOTPAuth,
  UpdateBackupCodes,
} from './Modals'
import {
  AuthOptionsWrapper,
  AuthOptionWrapper,
  Container,
  OptionWrapper,
  Separator,
} from './styles'

interface Props {
  onAlert: (alert: AlertMessage) => void
}

function TwoFactorAuth({ onAlert }: Props) {
  const { meAdmin } = useAppContext()

  const [TOTPAuthModal, setTOTPAuthModal] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })
  const [disconnectTOTPAuthModal, setDisconnectTOTPAuthModal] = useState<{
    isOpen: boolean
  }>({
    isOpen: false,
  })

  const [updateBackupCodesModal, setUpdateBackupCodes] = useState<{
    isOpen: boolean
    entity: BackupCode[] | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [updateBackupCodes, { loading }] = useMutation(UPDATE_BACKUP_CODES)

  const handleConnectVerificationEmail = useCallback(
    () => setTOTPAuthModal({ isOpen: true }),
    [],
  )

  const handleDisconnectVerificationEmail = useCallback(
    () => setDisconnectTOTPAuthModal({ isOpen: true }),
    [],
  )

  const handleCloseTOTPModal = useCallback(() => {
    setTOTPAuthModal({ isOpen: false })
  }, [])

  const handleCloseDisconnectTOTPModal = useCallback(() => {
    setDisconnectTOTPAuthModal({ isOpen: false })
  }, [])

  const handleCloseUpdateBackupCodesModal = useCallback(() => {
    setUpdateBackupCodes({ isOpen: false, entity: null })
  }, [])

  const handleTOTPClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      event.preventDefault()
      if (meAdmin?.verificationTOTPEnabled) {
        handleDisconnectVerificationEmail()
      } else {
        handleConnectVerificationEmail()
      }
    },
    [
      handleConnectVerificationEmail,
      handleDisconnectVerificationEmail,
      meAdmin,
    ],
  )

  const handleUpdateBackupCodes = useCallback(async () => {
    try {
      const result = await updateBackupCodes()
      if (result?.data?.updateBackupCodes)
        setUpdateBackupCodes({
          isOpen: true,
          entity: result?.data?.updateBackupCodes,
        })
    } catch (error) {
      onAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }, [onAlert, updateBackupCodes])

  return (
    <>
      <Container px={2} py={2}>
        <Typography fontSize={18} fontWeight={600} mb={1}>
          Two-factor authentication (2FA)
        </Typography>
        <Typography fontSize={14}>
          Keep your account secure by enabling 2FA using a temporary one-time
          passcode (TOTP) from an authenticator app
        </Typography>
        <AuthOptionsWrapper>
          <AuthOptionWrapper>
            <VerifiedUserIcon />
            <Separator />
            <OptionWrapper>
              <Box>
                <Typography fontWeight={600}>
                  Authenticator App (TOTP)
                </Typography>
                <Typography>
                  Use an app to receive a passcode each time you login
                </Typography>
              </Box>
              <Box onClick={handleTOTPClick}>
                {' '}
                <Switch checked={meAdmin?.verificationTOTPEnabled} />
              </Box>
            </OptionWrapper>
          </AuthOptionWrapper>
        </AuthOptionsWrapper>
        {meAdmin?.verificationTOTPEnabled && (
          <Button
            color="primary"
            disabled={loading}
            startIcon={<RefreshIcon />}
            sx={{ mt: 2 }}
            variant="contained"
            onClick={handleUpdateBackupCodes}
          >
            Update Backup Codes
          </Button>
        )}
      </Container>
      <ConnectTOTPAuth
        open={TOTPAuthModal.isOpen}
        onAlert={onAlert}
        onClose={handleCloseTOTPModal}
      />
      <DisconnectTOTPAuth
        open={disconnectTOTPAuthModal.isOpen}
        onAlert={onAlert}
        onClose={handleCloseDisconnectTOTPModal}
      />
      {updateBackupCodesModal.entity && (
        <UpdateBackupCodes
          backupCodes={updateBackupCodesModal.entity}
          open={updateBackupCodesModal.isOpen}
          onClose={handleCloseUpdateBackupCodesModal}
        />
      )}
    </>
  )
}

export default TwoFactorAuth
