import * as yup from 'yup'

const schemaPattern = (value: string) => {
  switch (value) {
    case 'name':
      return yup.string().nullable().min(2, 'Name is to short')
    case 'wallet':
      return yup
        .string()
        .nullable()
        .min(42, 'Wallet must be valid')
        .max(42, 'Wallet must bi valid')
    case 'dailyQueriesCap':
      return yup
        .number()
        .required('Required field')
        .min(1, 'Total queries cap at least must be 1')
    case 'queryConfirmationsCap':
      return yup
        .number()
        .required('Required field')
        .min(1, 'Queries per node cap at least must be 1')
    case 'stakedAmount':
      return yup
        .number()
        .required('Required field')
        .min(1, 'Staked amount at least must be 1')
    case 'credentials':
      return yup.string().min(1)
    default:
      return yup.string()
  }
}

export default schemaPattern
