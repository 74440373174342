import React from 'react'

import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { Box } from '@mui/system'

import { DownloadLink } from './styles'

interface Props {
  onNextStep: () => void
}

function DownloadLinks({ onNextStep }: Props) {
  return (
    <Box>
      <Typography fontSize={18} mb={1.25} textAlign={'center'}>
        Install an authentication app on your phone
      </Typography>
      <Typography>
        You&lsquo;ll need to install a Google Authenticator app on your phone to
        set up Two-Factor Authentication
      </Typography>
      <DownloadLink
        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
        target="_blank"
      >
        <Box alignItems={'center'} display={'flex'}>
          <AppleIcon style={{ fontSize: '32px' }} />
          <Box display={'flex'} flexDirection={'column'} ml={2}>
            <Typography fontWeight={600}>
              Google Authenticator for iOS
            </Typography>
            <Typography fontSize={12} mt={'2px'}>
              Get the app on the App Store
            </Typography>
          </Box>
        </Box>

        <ChevronRightIcon style={{ fontSize: '28px' }} />
      </DownloadLink>
      <DownloadLink
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
        target="_blank"
      >
        <Box alignItems={'center'} display={'flex'}>
          <AndroidIcon style={{ fontSize: '32px' }} />
          <Box display={'flex'} flexDirection={'column'} ml={2}>
            <Typography fontWeight={600}>
              Google Authenticator for Android
            </Typography>
            <Typography fontSize={12} mt={'2px'}>
              Get the app on the Play Store
            </Typography>
          </Box>
        </Box>

        <ChevronRightIcon style={{ fontSize: '28px' }} />
      </DownloadLink>
      <Button
        color="primary"
        fullWidth
        sx={{ mt: '30px' }}
        variant="contained"
        onClick={onNextStep}
      >
        I opened the app and ready to scan
      </Button>
    </Box>
  )
}

export default DownloadLinks
