import React from 'react'

import { useMutation } from '@apollo/client'
import { Button, Switch } from '@mui/material'
import { Input } from 'common'
import { AlertMessage, Validator } from 'common/types'
import schemaPattern from 'constants/schemaPattern'
import { useFormik } from 'formik'
import { UPDATE_VALIDATOR } from 'graphql/validators/mutations'
import { GET_VALIDATOR } from 'graphql/validators/queries'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { ValidatorInitialValues } from './types'

interface Props {
  initialValues: Validator
  onAlert: (alert: AlertMessage) => void
  onClose: () => void
}

function ValidatorForm({ initialValues, onClose, onAlert }: Props) {
  const [updateValidator, { loading }] = useMutation(UPDATE_VALIDATOR)
  const validationSchema = yup.object({
    name: schemaPattern('name'),
    wallet: schemaPattern('wallet'),
    nodesLimit: schemaPattern('nodesLimit'),
  })

  const onSubmit = async (values: ValidatorInitialValues) => {
    try {
      await updateValidator({
        variables: {
          updateValidatorId: initialValues?.id,
          updateValidatorData: {
            name: values.name || null,
            isApproved: values.isApproved,
            wallet: values.wallet ? values.wallet : null,
          },
        },
        refetchQueries: [
          {
            query: GET_VALIDATOR,
            variables: { getValidatorId: initialValues?.id },
          },
        ],
      })

      onAlert({
        isOpen: true,
        text: 'Validator successfully changed',
        alertColor: 'success',
      })
      onClose()
    } catch (error) {
      onAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
      onClose()
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })

  const { handleSubmit, handleChange, values, errors, setFieldValue } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Input
        error={errors.name}
        name="name"
        placeholder="Name"
        value={values.name}
        onChange={handleChange}
      />
      <Input
        error={errors.wallet}
        name="wallet"
        placeholder="Wallet"
        value={values.wallet}
        onChange={handleChange}
      />
      <label>Approve:</label>
      <Switch
        checked={values.isApproved}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('isApproved', e.target.checked)
        }
      />
      <Button disabled={loading} type={'submit'}>
        Save
      </Button>
    </form>
  )
}

export default ValidatorForm
