import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  ChartLegend,
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import { getXTicks } from 'utils/Charts'
import {
  createValidationsChartOptions,
  generateValidationsChartData,
} from 'utils/ValidationsChart'

const LegendItems = [
  { title: 'Cap', color: '#4D75B8' },
  { title: 'Amount of fulfilled validation per day', color: '#26B568' },
]

function ValidationsChart() {
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const validationsAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.validationsAmount || [],
    [data],
  )

  const validationsCap = useMemo(
    () => data?.systemDailyStatsGraphData?.validationsCap || [],
    [data],
  )

  const xTicks = useMemo(
    () => getXTicks(validationsAmount),
    [validationsAmount],
  )

  const startHAxis = useMemo(
    () => data?.systemDailyStatsGraphData?.validationsAmount[0]?.day,
    [data],
  )

  const chartOptions = createValidationsChartOptions(xTicks, startHAxis)

  const statsChartData = useMemo(
    () =>
      validationsAmount?.length && validationsCap?.length
        ? generateValidationsChartData(validationsCap, validationsAmount)
        : [],
    [validationsAmount, validationsCap],
  )

  const noChartData = statsChartData?.length < 2

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mt={1}
        width="100%"
      >
        <ChartLegend legendItems={LegendItems} />
        <Box display="flex">
          <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        )}
      </Box>
    </>
  )
}

export default ValidationsChart
