import { gql } from '@apollo/client'

export const GET_ADMINS = gql`
  query GetAdmins(
    $search: String
    $order: String
    $page: Int
    $take: Int
    $role: AdminRole
  ) {
    getAdmins(
      search: $search
      order: $order
      page: $page
      take: $take
      role: $role
    ) {
      data {
        id
        name
        email
        createdAt
        isOwner
      }
      meta {
        hasNextPage
        hasPreviousPage
        itemCount
        page
        pageCount
        take
      }
    }
  }
`

export const GET_ADMIN = gql`
  query GetAdmin($getAdminId: String!) {
    getAdmin(id: $getAdminId) {
      id
      name
      createdAt
      email
      isOwner
    }
  }
`
