import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'

export const ButtonWrapper = styled(Box)({
  margin: '0 auto',
  maxWidth: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
})

export const Text = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
`

export const Amount = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`

export const ChartWrapper = styled(Box)`
  height: 150px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  background-color: #f1f1f1;
  div.google-visualization-tooltip {
    border: none;
  }
`

export const GridTitle = styled(Typography)`
  line-height: 11px;
  padding: 0 1px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 2px;
  background-color: #f1f1f1;
`

export const GridLimitsContainer = styled(Box)`
  top: 31px;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 10px;
  height: 150px;
  background-color: transparent;
  z-index: 3;
`
