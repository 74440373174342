import { gql } from '@apollo/client'

export const EXTERNAL_API_CLIENT_FIELDS = gql`
  fragment ExternalAPIClientFields on ExternalAPIClient {
    id
    apiKey
    isSystem
    createdAt
    domains
    name
    enabled
    updatedAt
    todayStats {
      date
      attempts
      failures
      successes
      validations
    }
  }
`
