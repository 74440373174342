import { gql } from '@apollo/client'

export const QUEST_FIELDS = gql`
  fragment QuestFields on Quest {
    id
    title
    content
    createdAt
    updatedAt
    publishDate
    expirationDate
    phaseId
    imageUrl
    twitterId
  }
`
