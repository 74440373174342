import React, { MouseEvent, useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { AlertColor, CircularProgress, Snackbar } from '@mui/material'
import { Box } from '@mui/system'
import { Alert, SearchInput, StyledButton } from 'common'
import PageWrapper from 'common/pageWrapper'
import { ValidatorsTable } from 'common/tables'
import { AlertMessage, Validator } from 'common/types'
import { ALERT_DELAY, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import {
  DELETE_VALIDATOR,
  RESET_FAILED_VERIFICATIONS,
} from 'graphql/validators/mutations'
import { GET_VALIDATORS } from 'graphql/validators/queries'
import useTableSearch from 'hooks/useTableSearch'
import { getErrorMessage } from 'utils/Error'

import DeleteConfirmModal from './modals/deleteConfirmModal'
import EditModal from './modals/editModal'
import ExportModal from './modals/exportModal'
import KycConfirmModal from './modals/kycConfirmModal'
import { Params } from './types'

const params: Params = {
  page: 1,
  take: ROWS_PER_PAGE_DEFAULT,
  search: '',
  order: 'DESC',
}

function Validators() {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)

  const [editModal, setEditModal] = useState<{
    isOpen: boolean
    entity: Validator | null
  }>({
    isOpen: false,
    entity: null,
  })
  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean
    entity: Validator | null
  }>({
    isOpen: false,
    entity: null,
  })
  const [isOpenExport, setIsOpenExport] = useState<boolean>(false)
  const [isOpenKycConfirm, setIsOpenKycConfirm] = useState<boolean>(false)
  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const { data, loading, refetch } = useQuery(GET_VALIDATORS, {
    variables: params,
  })

  const [deleteValidator, { loading: deleteLoading }] =
    useMutation(DELETE_VALIDATOR)

  const [resetFailedVerifications, { loading: resetLoading }] = useMutation(
    RESET_FAILED_VERIFICATIONS,
  )

  const validatorsData: Validator[] = useMemo(
    () => data?.getValidators.data || [],
    [data],
  )
  const validatorsMeta = useMemo(() => data?.getValidators?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        page: 1,
      })
      setCurrentPage(0)
    },
  })

  const handleCloseEditModal = useCallback(
    () => setEditModal({ isOpen: false, entity: null }),
    [],
  )

  const handleOpenEditModal = useCallback(
    (entity: Validator) => setEditModal({ isOpen: true, entity }),
    [],
  )

  const handleCloseConfirmModal = useCallback(
    () => setConfirmModal({ isOpen: false, entity: null }),
    [],
  )

  const handleOpenConfirmModal = useCallback(
    (entity: Validator) => setConfirmModal({ isOpen: true, entity }),
    [],
  )

  const handleKycConfirmModal = useCallback(
    () => setIsOpenKycConfirm(!isOpenKycConfirm),
    [isOpenKycConfirm],
  )

  const handleDelete = async () => {
    try {
      await deleteValidator({
        variables: {
          deleteValidatorId: confirmModal?.entity?.id,
        },
      })
      await refetch({ ...params, take: rowsPerPage })
      setAlert({
        isOpen: true,
        text: 'Validator successfully removed',
        alertColor: 'success',
      })
      handleCloseConfirmModal()
    } catch (error) {
      setAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }

  const handleFailedKyc = async () => {
    try {
      await resetFailedVerifications()
      await refetch({ ...params, take: rowsPerPage })
      setAlert({
        isOpen: true,
        text: 'The Verification Reset Process Initiated. Please refresh the page after approximately 15 minutes to check the results.',
        alertColor: 'success',
      })
      setIsOpenKycConfirm(false)
    } catch (error) {
      setAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({
        take: rowsPerPage,
        page: page + 1,
        search,
      })
      setCurrentPage(page)
    },
    [refetch, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    },
    [refetch, search],
  )

  const handleOpenExportModal = useCallback(() => {
    setIsOpenExport(true)
  }, [])

  const handleCloseExportModal = useCallback(() => {
    setIsOpenExport(false)
  }, [])

  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  const handleCopyWallet = (wallet: string) => {
    navigator.clipboard.writeText(wallet)
    setAlert({
      isOpen: true,
      text: 'The wallet was copied',
      alertColor: 'success',
    })
  }

  return (
    <>
      <PageWrapper
        buttonText="EXPORT"
        pageTitle="Validators"
        onClick={handleOpenExportModal}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          sx={{ mb: '10px', mx: '30px' }}
        >
          <Box sx={{ my: '10px' }}>
            <SearchInput
              endAdornment={<SearchIcon />}
              placeholder="Search"
              sx={{ mr: 2 }}
              value={search}
              onChange={handleChangeSearch}
            />
            <StyledButton variant="contained" onClick={handleKycConfirmModal}>
              Reset Failed Verifications
            </StyledButton>
          </Box>
          {loading ? (
            <Box
              alignItems="center"
              display="flex"
              height={1}
              justifyContent="center"
              width={1}
            >
              <CircularProgress />
            </Box>
          ) : (
            <ValidatorsTable
              count={validatorsMeta?.itemCount ?? 0}
              data={validatorsData}
              page={currentPage}
              rowsPerPage={rowsPerPage}
              onCopyWallet={handleCopyWallet}
              onDeleteModal={handleOpenConfirmModal}
              onEditModal={handleOpenEditModal}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>
        <Snackbar
          autoHideDuration={ALERT_DELAY}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
        >
          <Alert
            severity={alert.alertColor}
            sx={{ width: '100%' }}
            onClose={() => setAlert({ isOpen: false })}
          >
            {alert.text}
          </Alert>
        </Snackbar>
      </PageWrapper>
      <KycConfirmModal
        loading={resetLoading}
        open={isOpenKycConfirm}
        onClose={handleKycConfirmModal}
        onConfirm={handleFailedKyc}
      />
      {confirmModal?.entity && (
        <DeleteConfirmModal
          loading={deleteLoading}
          open={confirmModal?.isOpen}
          validator={confirmModal?.entity}
          onClose={handleCloseConfirmModal}
          onConfirm={handleDelete}
        />
      )}
      {editModal?.entity && (
        <EditModal
          open={editModal?.isOpen}
          validator={editModal?.entity}
          onAlert={handleAlert}
          onClose={handleCloseEditModal}
        />
      )}
      <ExportModal
        open={isOpenExport}
        onAlert={handleAlert}
        onClose={handleCloseExportModal}
      />
    </>
  )
}

export default Validators
