import React from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { TableCell, TableRow } from '@mui/material'
import { QuestActivity } from 'common/types'
import { getFormattedDate } from 'utils/Date'

interface Props {
  row: QuestActivity
  onOpenViewQuestModal: () => void
}

function QuestActivityRow({ row, onOpenViewQuestModal }: Props) {
  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell align="left">{row.quest.title}</TableCell>
      <TableCell align="left">{row.quest?.twitterId}</TableCell>
      <TableCell align="left">
        {getFormattedDate(row.quest?.publishDate)}
      </TableCell>
      <TableCell align="center">
        {getFormattedDate(row.quest?.expirationDate)}
      </TableCell>
      <TableCell align="center">{row.points}</TableCell>
      <TableCell align="center">
        <InfoIcon
          className="itemIcon"
          sx={{ cursor: 'pointer' }}
          onClick={onOpenViewQuestModal}
        />
      </TableCell>
    </TableRow>
  )
}

export default QuestActivityRow
