import React, { useCallback } from 'react'

import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { LoaderHolder } from 'common'
import { DateTime } from 'luxon'
import numeral from 'numeral'

function getDayStyles(day: DateTime, month: DateTime) {
  if (day.hasSame(DateTime.now(), 'day'))
    return {
      day: {
        border: '1px solid #b6c1d8',
        backgroundColor: '#5674b308',
      },
      date: {
        color: '#5674b3',
      },
    }

  if (day.month === month.month)
    return {
      day: {
        borderRight: '1px solid #f2f3f5',
        borderBottom: '1px solid #f2f3f5',
      },
      date: {
        color: '#000000',
      },
    }

  return {
    day: {
      borderRight: '1px solid #f2f3f5',
      borderBottom: '1px solid #f2f3f5',
    },
    date: {
      color: '#a2a2a2',
    },
  }
}

export type nodePrincipalAndRewardsByDays = {
  day: string
  principal: number | null
  reward: number | null
}

type CalendarDaysProps = {
  data?: nodePrincipalAndRewardsByDays[]
  month: DateTime
  loading?: boolean
}

function CalendarDays({ data, month, loading }: CalendarDaysProps) {
  const startMonth = month.startOf('month')
  const endMonth = month.endOf('month')
  const startDate = startMonth.startOf('week')
  const endDate = endMonth.endOf('week')

  const generateDays = useCallback(() => {
    const days = []
    let currentDate = startDate

    while (currentDate <= endDate) {
      days.push(currentDate)
      currentDate = currentDate.plus({ days: 1 })
    }

    return days
  }, [endDate, startDate])

  const getDayData = useCallback(
    (day: DateTime) => {
      if (!data) return { principal: 0, reward: 0 }

      const dayData = data.find(
        item => DateTime.fromISO(item.day).day === day.day,
      )

      return dayData
        ? { principal: dayData.principal ?? 0, reward: dayData.reward ?? 0 }
        : { principal: 0, reward: 0 }
    },
    [data],
  )

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <Grid container spacing={1}>
      {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
        <Grid height="36px" item key={day} xs={1.7}>
          <Typography
            color="#727272"
            fontSize="14px"
            fontWeight={500}
            sx={{
              borderRight: '1px solid #F2F3F5',
              borderBottom: '1px solid #F2F3F5',
              padding: '8px 12px',
            }}
          >
            {day}
          </Typography>
        </Grid>
      ))}

      {generateDays().map(day => {
        const styles = getDayStyles(day, month)

        const dayData = getDayData(day)

        const dayIsInMonth = day.month === month.month

        return (
          <Grid item key={day.toMillis()} sx={{ padding: '0' }} xs={1.7}>
            <Box
              display="flex"
              flexDirection="column"
              height="128px"
              justifyContent="space-between"
              sx={{
                padding: '8px 12px',
                ...styles.day,
              }}
            >
              <Typography
                fontSize="16px"
                fontWeight={700}
                sx={{ ...styles.date }}
              >
                {day.day}
              </Typography>

              {dayIsInMonth && (
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb="4px"
                    sx={{
                      padding: '2px 4px',
                      backgroundColor: '#edb0121a',
                      opacity: dayData.principal ? '1' : '0',
                    }}
                  >
                    <Typography
                      color="#000000"
                      fontSize="12px"
                      fontWeight={400}
                      lineHeight="16px"
                    >
                      Principal
                    </Typography>

                    <Typography
                      color="#000000"
                      fontSize="14px"
                      fontWeight={300}
                      lineHeight="20px"
                    >
                      {dayData.principal
                        ? numeral(dayData.principal).format('0.0 a')
                        : '-'}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      padding: '2px 4px',
                      backgroundColor: '#24db621a',
                      opacity: dayData.reward ? '1' : '0',
                    }}
                  >
                    <Typography
                      color="#000000"
                      fontSize="12px"
                      fontWeight={400}
                      lineHeight="16px"
                    >
                      Rewards
                    </Typography>

                    <Typography
                      color="#000000"
                      fontSize="14px"
                      fontWeight={300}
                      lineHeight="20px"
                    >
                      {dayData.reward
                        ? numeral(dayData.reward).format('0.0 a')
                        : '-'}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default CalendarDays
