import { Paper } from '@mui/material'
import { styled } from '@mui/system'

export const TableWrapper = styled(Paper)`
  overflow: hidden;
  width: 100%;
  table {
    border-radius: 12px;
    min-width: 650px;
    th,
    td {
      background-color: white;
    }
    & tr:hover {
      background-color: #f0f0f0;
      th,
      td {
        background-color: #f0f0f0;
      }
    }
  }
`
