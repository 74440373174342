import React from 'react'

import { KeyValueLine, ModalWrapper } from 'common'
import { Node } from 'common/types'
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter'
import { truncateAddress } from 'constants/truncateAddress'
import { getFormattedDate } from 'utils/Date'

interface Props {
  open: boolean
  onClose: () => void
  nodeData: Node
}
const ViewModal = ({ open, onClose, nodeData }: Props) => {
  const { id, address, status, createdAt } = nodeData

  return (
    <ModalWrapper open={open} title="Node details" onClose={onClose}>
      <KeyValueLine label="Id" value={id} />
      <KeyValueLine
        copyValue={address}
        label="Address"
        value={truncateAddress(address)}
      />
      <KeyValueLine label="Status" value={capitalizeFirstLetter(status)} />
      <KeyValueLine label="Created at" value={getFormattedDate(createdAt)} />
    </ModalWrapper>
  )
}

export default ViewModal
