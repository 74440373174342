import { gql } from '@apollo/client'

import { EXTERNAL_API_CLIENT_FIELDS } from './fragments'

export const EXTERNAL_API_CLIENTS_LIST = gql`
  query ExternalAPIClientList(
    $limit: Int
    $name: String
    $page: Int
    $sort: [SortInput!]
  ) {
    externalAPIClientList(
      limit: $limit
      name: $name
      page: $page
      sort: $sort
    ) {
      rows {
        ...ExternalAPIClientFields
      }
      count
      pages
    }
  }
  ${EXTERNAL_API_CLIENT_FIELDS}
`

export const EXTERNAL_API_CLIENT_STATS = gql`
  query externalAPIClientStats(
    $externalApiClientId: ID!
    $from: DateTime!
    $to: DateTime!
  ) {
    externalAPIClientStats(
      externalApiClientId: $externalApiClientId
      from: $from
      to: $to
    ) {
      date
      attempts
      failures
      successes
      validations
    }
  }
`
