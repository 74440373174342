import React, { useCallback, useState } from 'react'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ViewTab } from 'common'
import PageWrapper from 'common/pageWrapper'
import { Range } from 'common/types'
import { View } from 'constants/strings'
import { DateTime } from 'luxon'

import StatsGraphContainer from './statsGraphContainer'
import StatsTableContainer from './statsTableContainer'

export const DEFAULT_PERIOD_DAYS = 30
function MnwDailyStats() {
  const [currentView, setCurrentView] = useState(View.GRAPH)

  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ days: DEFAULT_PERIOD_DAYS }),
    to: DateTime.now(),
  })

  const handleToggleCurrentView = useCallback(
    (view: View) => {
      setCurrentView(view)
    },
    [setCurrentView],
  )

  // TODO ADD FILTERS
  return (
    <PageWrapper>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        minHeight="80px"
        sx={{ py: '15px', px: '30px' }}
      >
        <Box alignItems="center" display="flex">
          <Typography fontSize="18px" fontWeight="700" mr={3}>
            System Info
          </Typography>
          <ViewTab
            activeTab={currentView}
            onViewChange={handleToggleCurrentView}
          />
        </Box>
        {/* <Box alignItems="center" display="flex" justifyContent="end">
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              inputFormat={DEFAULT_DATE_FORMAT}
              readOnly
              renderInput={props => (
                <TextField
                  {...props}
                  label="From"
                  size="small"
                  style={{ width: 130 }}
                  variant="standard"
                />
              )}
              shouldDisableDate={day => (range?.to ? day > range?.to : false)}
              value={range?.from}
              onChange={() => {}}
            />
            <Box mx={0.5}>-</Box>
            <DatePicker
              inputFormat={DEFAULT_DATE_FORMAT}
              label="To"
              readOnly
              renderInput={props => (
                <TextField
                  {...props}
                  size="small"
                  style={{ width: 130 }}
                  variant="standard"
                />
              )}
              shouldDisableDate={day =>
                range?.from ? day < range?.from : false
              }
              value={range?.to}
              onChange={() => {}}
            />
          </LocalizationProvider>
        </Box> */}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{ mb: '10px', mx: '30px' }}
      >
        {currentView === View.LIST ? (
          <StatsTableContainer range={range} />
        ) : (
          <StatsGraphContainer />
        )}
      </Box>
    </PageWrapper>
  )
}

export default MnwDailyStats
