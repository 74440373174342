import React, { useCallback, useState } from 'react'

import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import {
  Box,
  Divider,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { DomainsTooltip } from 'common'
import { ExternalAPIClient } from 'common/types'
import { truncateAPIKey } from 'constants/truncateAPIKey'
import { getFormattedDate } from 'utils/Date'

type Props = {
  row: ExternalAPIClient
  onChangeStatus?: () => void
  onCopyKey?: () => void
  onEdit?: () => void
  onOpenStats?: () => void
}

export default function TableDataRow({
  row,
  onChangeStatus,
  onCopyKey,
  onEdit,
  onOpenStats,
}: Props) {
  const [showDomains, setShowDomains] = useState(false)

  const handleCloseDomainsTooltip = useCallback(() => {
    setShowDomains(false)
  }, [])

  const handleOpenDomainsTooltip = useCallback(() => {
    setShowDomains(true)
  }, [])

  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Box alignItems="center" display="flex">
          <Typography mr={1}>{row.name}</Typography>
          {row.isSystem && (
            <Tooltip
              arrow
              placement="top"
              title={'System key is read-only and cannot be edited'}
            >
              <LockOutlinedIcon
                color="primary"
                sx={{ mr: 1, fontSize: '16px' }}
              />
            </Tooltip>
          )}
          {!!row.domains?.length && (
            <ClickAwayListener onClickAway={handleCloseDomainsTooltip}>
              <div>
                <DomainsTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  arrow
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={showDomains}
                  title={
                    <Box gap={2} width="200px">
                      <Typography color="#787878" fontWeight={500}>
                        DOMAINS
                      </Typography>
                      <Divider sx={{ color: 'white' }} />
                      <Box gap={2} maxHeight="200px" overflow="auto">
                        {row.domains.map(domain => (
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {domain}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  }
                  onClose={handleCloseDomainsTooltip}
                >
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleOpenDomainsTooltip}
                  >
                    <KeyOutlinedIcon
                      fontSize="inherit"
                      sx={{ transform: 'rotate(45deg)' }}
                    />
                  </IconButton>
                </DomainsTooltip>
              </div>
            </ClickAwayListener>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box alignItems="center" display="flex">
          {truncateAPIKey(row.apiKey)}
          {row.apiKey && (
            <Tooltip arrow placement="top" title="Copy key to clipboard">
              <IconButton
                color="primary"
                size="small"
                sx={{ ml: 1 }}
                onClick={() => onCopyKey?.()}
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box alignItems="center" display="flex" gap={1}>
          <CircleIcon
            color={row.enabled ? 'success' : 'disabled'}
            sx={{
              width: '12px',
            }}
          />
          <Typography>{row.enabled ? 'Active' : 'Disabled'}</Typography>
        </Box>
      </TableCell>
      <TableCell>{getFormattedDate(row.createdAt)}</TableCell>
      <TableCell>
        <Box display="flex" flexDirection="column">
          <Typography fontSize="inherit">
            {row.todayStats?.attempts || 0}
          </Typography>
          {!!row.todayStats?.attempts && (
            <Typography fontSize="inherit">
              ({row.todayStats?.successes || 0}/{row.todayStats?.failures || 0})
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell>{row.todayStats?.validations || 0}</TableCell>
      <TableCell align="right">
        <Box
          alignItems="center"
          display="flex"
          justifyContent="end"
          width="100%"
        >
          <Switch
            checked={row.enabled}
            disabled={row.isSystem}
            size="small"
            onChange={() => onChangeStatus?.()}
          />
          <Tooltip arrow placement="top" title="Client settings">
            <IconButton
              color="primary"
              disabled={row.isSystem}
              size="small"
              onClick={() => onEdit?.()}
            >
              <SettingsOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <IconButton
            color="primary"
            size="small"
            onClick={() => onOpenStats?.()}
          >
            <AutoGraphOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )
}
