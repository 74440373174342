import { InputBase } from '@mui/material'
import { styled } from '@mui/system'

export const SearchInput = styled(InputBase)({
  background: '#f1f1f1',
  borderRadius: '6px',
  width: '200px',
  padding: '0 5px',
  height: '35px',
  '& .MuiSvgIcon-root': {
    fill: '',
  },
})
