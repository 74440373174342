import map from 'lodash/map'

interface Point {
  key: string
  value: number
}

interface NodeVersionsCount {
  count: number
  nodeVersion: string
}

export const createVersionsChartOptions = () => ({
  fontFamily: 'Archivo',
  curveType: 'function',
  baselineColor: 'transparent',
  tooltip: { isHtml: true, trigger: 'visible' },
  colors: ['#4D75B8'],
  legend: { position: 'none' },
})

export const generateVersionsTooltipBlock = (point: Point) =>
  `
		<div style="background-color: ${'#fff'}; padding: 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-size: 10px; padding: 5px; margin: 0;">Version: ${
    point?.key
  }</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">Users: ${
    point?.value
  }</p>
		</div>
		`

export function generateVersionChartData(nodeVersions: NodeVersionsCount[]) {
  return [
    [
      'Version',
      'User count',
      { role: 'tooltip', type: 'string', p: { html: true } },
    ],
    ...map(nodeVersions, version => [
      version?.nodeVersion,
      version?.count,
      generateVersionsTooltipBlock({
        key: version?.nodeVersion,
        value: version?.count,
      }),
    ]),
  ]
}
