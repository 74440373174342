import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useMutation, useQuery } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import { AlertColor, CircularProgress, Snackbar } from '@mui/material'
import { Box } from '@mui/system'
import { Alert, LoaderHolder, SearchInput } from 'common'
import { PhaseInfo } from 'common/blocks'
import {
  ConfirmDeleteQuestModal,
  CreatePhaseModal,
  CreateQuestModal,
} from 'common/modals'
import ViewQuestModal from 'common/modals/viewQuest'
import PageWrapper from 'common/pageWrapper'
import { QuestsTable } from 'common/tables'
import { Phase, Quest } from 'common/types'
import { ALERT_DELAY, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import { CURRENT_PHASE } from 'graphql/phase/queries/currentPhase'
import { PHASES } from 'graphql/phase/queries/phases'
import { DELETE_QUEST } from 'graphql/quests/mutations'
import { GET_QUESTS } from 'graphql/quests/queries'
import useTableSearch from 'hooks/useTableSearch'
import { getErrorMessage } from 'utils/Error'

import { Container } from './styles'

function Quests() {
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROWS_PER_PAGE_DEFAULT)
  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean
    id: string | null
  }>({
    isOpen: false,
    id: null,
  })

  const [createCodeModal, setCreateCodeModal] = useState<{
    isOpen: boolean
    entity: Quest | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [viewModal, setViewModal] = useState<{
    isOpen: boolean
    entity: Quest | null
  }>({
    isOpen: false,
    entity: null,
  })

  const [createPhaseModal, setCreatePhaseModal] = useState<{
    isOpen: boolean
    phase?: null | Phase
  }>({
    isOpen: false,
    phase: null,
  })

  const { data: currentPhaseData, loading: currentPhaseLoading } =
    useQuery(CURRENT_PHASE)

  const { data: phasesData, loading: phasesLoading } = useQuery(PHASES)

  const { data, loading, refetch } = useQuery(GET_QUESTS, {
    variables: {
      take: ROWS_PER_PAGE_DEFAULT,
      page: 1,
      search: '',
      order: 'DESC',
    },
  })

  const currentPhase: Phase = useMemo(
    () => currentPhaseData?.currentPhase,
    [currentPhaseData],
  )

  const phases: Phase[] = useMemo(
    () => phasesData?.phases || [],
    [phasesData?.phases],
  )

  useEffect(() => {
    if (currentPhase) {
      setSelectedPhase(currentPhase)
    }
  }, [currentPhase])

  const [deleteQuest, { loading: deleteLoading }] = useMutation(DELETE_QUEST)

  const questsData: Quest[] = useMemo(() => data?.getQuests?.data || [], [data])
  const questsMeta = useMemo(() => data?.getQuests?.meta || {}, [data])

  const [search, handleChangeSearch] = useTableSearch({
    doOnSearch: (value: string) => {
      refetch({
        take: rowsPerPage,
        search: value,
        page: 1,
      })
      setPage(0)
    },
  })

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      refetch({ take: rowsPerPage, page: page + 1, search })
      setPage(page)
    },
    [refetch, rowsPerPage, search],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      refetch({
        take: parseInt(event.target.value, 10),
        page: 1,
        search,
      })
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    },
    [refetch, search],
  )

  const handleDelete = useCallback(
    async (id: string | null) => {
      if (!id) {
        setConfirmModal({ isOpen: false, id: null })
        return
      }

      try {
        await deleteQuest({
          variables: { id },
        })
        await refetch({ page: 1, take: rowsPerPage, search })
        setAlert({
          isOpen: true,
          text: 'Quest deleted successfully',
          alertColor: 'success',
        })
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
      setConfirmModal({ isOpen: false, id: null })
    },
    [deleteQuest, refetch, rowsPerPage, search],
  )

  const handleCloseCreateModal = useCallback(() => {
    setCreateCodeModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenCreateModal = useCallback(() => {
    setCreateCodeModal({ isOpen: true, entity: null })
  }, [])

  const handleOpenViewModal = useCallback((entity: Quest) => {
    setViewModal({ isOpen: true, entity })
  }, [])

  const handleCloseViewModal = useCallback(() => {
    setViewModal({ isOpen: false, entity: null })
  }, [])

  const handleRefetch = useCallback(() => {
    refetch({ page: 1 })
    setPage(0)
  }, [refetch])

  const handleOpenCreatePhaseModal = useCallback(
    (selectedPhase?: Phase | null) => {
      setCreatePhaseModal({ isOpen: true, phase: selectedPhase || null })
    },
    [],
  )

  const handleCloseCreatePhaseModal = useCallback(
    () => setCreatePhaseModal({ isOpen: false, phase: null }),
    [],
  )

  return (
    <>
      <PageWrapper
        buttonText={'Create'}
        pageTitle="Quests"
        onClick={handleOpenCreateModal}
      >
        <Container>
          <Box sx={{ my: '10px' }}>
            <PhaseInfo
              currentPhase={currentPhase}
              loading={phasesLoading || currentPhaseLoading}
              phases={phases}
              selectedPhase={selectedPhase}
              onOpenCreatePhaseModal={handleOpenCreatePhaseModal}
              onSelectPhase={setSelectedPhase}
            />

            <SearchInput
              endAdornment={<SearchIcon />}
              placeholder="Search"
              value={search}
              onChange={handleChangeSearch}
            />
          </Box>
          {loading ? (
            <LoaderHolder>
              <CircularProgress />
            </LoaderHolder>
          ) : (
            <QuestsTable
              count={questsMeta?.itemCount ?? 0}
              data={questsData}
              page={page}
              rowsPerPage={rowsPerPage}
              onDelete={id => setConfirmModal({ isOpen: true, id })}
              onEdit={entity => setCreateCodeModal({ isOpen: true, entity })}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onView={handleOpenViewModal}
            />
          )}
        </Container>
      </PageWrapper>
      <CreateQuestModal
        entity={createCodeModal.entity}
        open={createCodeModal.isOpen}
        onClose={handleCloseCreateModal}
        onRefetch={handleRefetch}
      />

      <ConfirmDeleteQuestModal
        id={confirmModal.id}
        loading={deleteLoading}
        open={confirmModal.isOpen}
        onClose={() => setConfirmModal({ isOpen: false, id: null })}
        onConfirm={handleDelete}
      />
      {viewModal?.entity && (
        <ViewQuestModal
          open={viewModal.isOpen}
          quest={viewModal.entity}
          onClose={handleCloseViewModal}
        />
      )}

      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
      <CreatePhaseModal
        open={createPhaseModal.isOpen}
        phase={createPhaseModal.phase}
        onClose={handleCloseCreatePhaseModal}
        onUpdateSelectedPhase={setSelectedPhase}
      />
    </>
  )
}

export default Quests
