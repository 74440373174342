import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import {
  createBridgedChartOptions,
  generateBridgetChartData,
} from 'utils/BridgetChart'
import { getXTicks } from 'utils/Charts'

function BridgedTokenChart() {
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const bridgedTokenAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.bridgedTokenAmount || [],
    [data],
  )

  const bridgedTokenDailyDiff = useMemo(
    () => data?.systemDailyStatsGraphData?.bridgedTokenDailyDiff || [],
    [data],
  )

  const xTicks = useMemo(
    () => getXTicks(bridgedTokenAmount),
    [bridgedTokenAmount],
  )

  const startHAxis = useMemo(
    () => data?.systemDailyStatsGraphData?.bridgedTokenAmount[0]?.day,
    [data],
  )

  const chartOptions = createBridgedChartOptions(xTicks, startHAxis)

  const statsChartData = useMemo(
    () =>
      bridgedTokenAmount?.length && bridgedTokenDailyDiff?.length
        ? generateBridgetChartData(bridgedTokenAmount, bridgedTokenDailyDiff)
        : [],
    [bridgedTokenAmount, bridgedTokenDailyDiff],
  )

  const noChartData = bridgedTokenAmount?.length < 2

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="end">
        <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
      </Box>

      {error && <ErrorChartPlaceholder />}
      {!error && noChartData && <EmptyChartPlaceholder />}
      {!error && !noChartData && (
        <Box height="400px" width="100%">
          <Chart
            chartType="AreaChart"
            data={statsChartData}
            height="100%"
            options={chartOptions}
          />
        </Box>
      )}
    </>
  )
}

export default BridgedTokenChart
