import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { AlertColor, Snackbar } from '@mui/material'
import { Alert } from 'common'
import {
  VerificationCodeForm,
  VerificationOptions,
} from 'common/form/verificationForm'
import { AuthVerification } from 'common/types/verification'
import { ALERT_DELAY } from 'constants/params'
import { LOGIN_ADMIN } from 'graphql/auth/mutations'
import { useStepper } from 'hooks'
import { AuthContext } from 'index'
import { LoginFormLayout } from 'layouts'
import { Routes } from 'router/routes'
import Auth from 'services/Auth'
import { getErrorMessage } from 'utils/Error'

function Verification() {
  const navigate = useNavigate()
  const location = useLocation()

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  useEffect(() => {
    if (!location?.state?.email || !location?.state?.password) {
      navigate(Routes.LOGIN)
    }
  }, [location, navigate])

  const { step, next, prev, reset } = useStepper([1, 2])
  const { setAuthorized } = useContext(AuthContext)

  const [verification, setVerification] = useState<AuthVerification>()

  const [loginAdmin, { loading }] = useMutation(LOGIN_ADMIN)

  const handleCheckOption = useCallback(
    (option: AuthVerification) => {
      setVerification(option)
      next()
    },
    [next],
  )
  const handleVerification = useCallback(
    async (code: string) => {
      try {
        const {
          state: { email, password },
        } = location
        const result = await loginAdmin({
          variables: {
            password,
            email,
            code,
            verification,
          },
        })

        if (result?.data?.loginAdmin) {
          Auth.handleAuth(
            result?.data.loginAdmin?.access,
            result?.data.loginAdmin?.refresh,
          )
          setAuthorized(true)
          navigate(Routes.ROOT)
        }
        reset()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [location, loginAdmin, navigate, reset, setAuthorized, verification],
  )

  const renderContent = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <VerificationOptions
            title={
              'To help keep your account safe, we want to make sure it’s really you trying to sign in'
            }
            onCheckOption={handleCheckOption}
          />
        )
      case 2:
        return (
          <VerificationCodeForm
            authVerificationOption={verification}
            loading={loading}
            onFinish={handleVerification}
            onPrevStep={prev}
          />
        )
      default:
        return ''
    }
  }, [handleCheckOption, handleVerification, loading, prev, step, verification])

  return (
    <LoginFormLayout>
      <>{renderContent}</>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </LoginFormLayout>
  )
}

export default Verification
