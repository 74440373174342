import { createContext, useContext } from 'react'

import { Admin } from 'common/types'

interface AppContextValue {
  meAdmin?: Admin
  refetchMeAdmin?: (variables?: any) => Promise<any>
}

export const AppContext = createContext<AppContextValue>({})

export function useAppContext() {
  return useContext(AppContext)
}
