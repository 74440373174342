import React from 'react'

import Checkbox from '@mui/material/Checkbox'

import { Label, Row, Value } from './styles'

interface Props {
  label: string
  value: boolean
}

function ApproveValueLine({ label, value }: Props) {
  return (
    <Row>
      <Label>{label}</Label>
      <Value>
        <Checkbox checked={value} disabled style={{ padding: '0px' }} />
      </Value>
    </Row>
  )
}

export default ApproveValueLine
