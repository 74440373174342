import { gql } from '@apollo/client'

export const BACKEND_BALANCE = gql`
  mutation BackendBalance {
    backendBalance {
      mainAddress
      rewardBalance
      rewardGasBalance
      validateAddress
      validateGasBalance
    }
  }
`
