import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Row = styled(Box)({
  display: 'flex',
  gap: '30px',
  marginBottom: '15px',
  width: '100%',
})

export const Label = styled(Box)({
  fontWeight: '500',
  fontSize: '18px',
  width: '30%',
  color: '#a4a4ad',
})

export const Value = styled(Box)({
  color: '#403f54',
  fontWeight: '500',
  width: '70%',
})
