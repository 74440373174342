import { ApolloCache } from '@apollo/client'
import { ExternalAPIClient } from 'common/types'

import { EXTERNAL_API_CLIENT_FIELDS } from './fragments'

export function updateExternalApiClientsQuery(
  cache: ApolloCache<any>,
  data: any,
  externalAPIClient: ExternalAPIClient,
) {
  cache.updateFragment(
    {
      id: `ExternalAPIClient:${externalAPIClient.id}`,
      fragment: EXTERNAL_API_CLIENT_FIELDS,
    },
    (data: any) => ({
      ...data,
      enabled: !externalAPIClient.enabled,
    }),
  )
}
