import { TransactionFeeChartKind } from 'common/blocks/transactionFeeChart'
import { Point } from 'common/types'
import { commonChartOptions } from 'constants/chartOptions'
import { DateTime } from 'luxon'

import { compact } from 'lodash'

import { getFormattedNumber } from './Number'

export interface TransactionFeePoint {
  day: string
  tokenAmount: number
  currencyAmount: number
}

export const createTransactionFeeChartOptions = (startHAxis?: string) => {
  const startHAxisDate = startHAxis ? new Date(startHAxis) : null

  return {
    ...commonChartOptions,
    chartArea: { left: 40, right: 7, width: '100%', height: 250 },
    seriesType: 'area',
    series: {
      0: { areaOpacity: 0.1 },
      1: {
        type: 'area',
        lineDashStyle: [4, 4],
      },
      2: {
        type: 'area',
        lineDashStyle: [4, 4],
      },
    },
    annotations: {
      stem: {
        color: '#E93940',
      },
      textStyle: {
        fontSize: 12,
        bold: true,
        color: '#E93940',
        auraColor: 'none',
      },
      alwaysOutside: true,
      style: 'line',
    },
    legend: 'none',

    hAxis: {
      ...commonChartOptions?.hAxis,
      ...(startHAxisDate && {
        viewWindow: { min: startHAxisDate },
        baseline: startHAxisDate,
      }),
    },
  }
}

export const generateTooltipBlock = (point: Point, isToday?: boolean) => {
  return `
		<div style="background-color: ${'#fff'}; padding: 10px 5px; margin: 0; border-radius: 8px; min-width: 100px" >
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 0 5px 5px; margin: 0;">${DateTime.fromISO(
    point?.day,
  ).toFormat('LLL dd')}</p>
			<p style="color: ${'rgba(0, 0, 33, 1)'}; font-weight: bold; padding: 5px; margin: 0;">${getFormattedNumber(
    point.value,
  )} ${isToday ? '(Today)' : ''}</p>
		</div>
		`
}

type DataMap = { [key: string]: number | null }

export function generateTransactionFeeChartData(
  historicalPoints: TransactionFeePoint[],
  forecastPoints: TransactionFeePoint[],
  chartKind: TransactionFeeChartKind,
  hasHistoricalChartData: boolean,
  hasForecastChartData: boolean,
) {
  const combinedData: any =
    chartKind === TransactionFeeChartKind.TOKEN
      ? [
          compact([
            'Date',
            hasForecastChartData ? { role: 'annotation' } : null,
            hasHistoricalChartData ? 'Fee Tokens' : null,
            hasHistoricalChartData
              ? { role: 'tooltip', type: 'string', p: { html: true } }
              : null,
            hasForecastChartData ? 'Forecast Fee Tokens(positive)' : null,
            hasForecastChartData
              ? { role: 'tooltip', type: 'string', p: { html: true } }
              : null,
            hasForecastChartData ? 'Forecast Fee Tokens' : null,
            hasForecastChartData
              ? { role: 'tooltip', type: 'string', p: { html: true } }
              : null,
          ]),
        ]
      : [
          compact([
            'Date',
            hasForecastChartData ? { role: 'annotation' } : null,
            hasHistoricalChartData ? 'Fee USD' : null,
            hasHistoricalChartData
              ? { role: 'tooltip', type: 'string', p: { html: true } }
              : null,
            hasForecastChartData ? 'Forecast Fee USD(positive)' : null,
            hasForecastChartData
              ? { role: 'tooltip', type: 'string', p: { html: true } }
              : null,
            hasForecastChartData ? 'Forecast Fee USD' : null,
            hasForecastChartData
              ? { role: 'tooltip', type: 'string', p: { html: true } }
              : null,
          ]),
        ]

  const allDates = new Set([
    ...historicalPoints.map(item => item.day),
    ...forecastPoints.map(item => item.day),
  ])

  const historicalMap: DataMap = historicalPoints.reduce((acc, item) => {
    // @ts-ignore
    acc[item.day] =
      chartKind === TransactionFeeChartKind.TOKEN
        ? item.tokenAmount
        : item.currencyAmount
    return acc
  }, {})

  const forecastMap: DataMap = forecastPoints.reduce((acc, item) => {
    // @ts-ignore
    acc[item.day] =
      chartKind === TransactionFeeChartKind.TOKEN
        ? item.tokenAmount
        : item.currencyAmount
    return acc
  }, {})

  const allDatesArr = Array.from(allDates)
  allDatesArr.forEach((date, index) => {
    const prevForecastPoint = forecastMap[allDatesArr[index - 1]]

    const timeToCurrentDay =
      DateTime.now().startOf('day').toMillis() -
      DateTime.fromISO(date).toMillis()

    const isToday = timeToCurrentDay === 0
    const isPastOrToday = timeToCurrentDay >= 0

    const historicalPoint =
      isPastOrToday && historicalMap[date] === undefined
        ? 0
        : historicalMap[date] ?? null

    let forecastPointPositive

    if (forecastMap[date]) {
      forecastPointPositive = forecastMap[date]
    } else if (forecastMap[date] === 0) {
      if (prevForecastPoint === 0) {
        forecastPointPositive = null
      } else {
        forecastPointPositive = 0
      }
    } else if (isToday) {
      forecastPointPositive = historicalPoint
    }

    const forecastPoint = forecastPointPositive
      ? null
      : forecastMap[date] ?? null

    const estimatedFundsExhaustionAnnotation =
      forecastMap[date] === 0 && prevForecastPoint !== 0
        ? 'Estimated funds exhaustion date'
        : null

    if (!hasHistoricalChartData) {
      return combinedData.push([
        new Date(date),
        estimatedFundsExhaustionAnnotation,
        forecastPointPositive,
        generateTooltipBlock(
          {
            day: date,
            value: forecastPointPositive || 0,
          },
          isToday,
        ),
        forecastPoint,
        generateTooltipBlock(
          {
            day: date,
            value: forecastPoint || 0,
          },
          isToday,
        ),
      ])
    }
    if (!hasForecastChartData) {
      return combinedData.push([
        new Date(date),
        historicalPoint,
        generateTooltipBlock(
          {
            day: date,
            value: historicalPoint || 0,
          },
          isToday,
        ),
      ])
    }

    return combinedData.push([
      new Date(date),
      estimatedFundsExhaustionAnnotation,
      historicalPoint,
      generateTooltipBlock(
        {
          day: date,
          value: historicalPoint || 0,
        },
        isToday,
      ),
      forecastPointPositive,
      generateTooltipBlock(
        {
          day: date,
          value: forecastPointPositive || 0,
        },
        isToday,
      ),
      forecastPoint,
      generateTooltipBlock(
        {
          day: date,
          value: forecastPoint || 0,
        },
        isToday,
      ),
    ])
  })

  return combinedData
}
