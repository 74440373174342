import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import { getXTicks } from 'utils/Charts'
import {
  createTokenStakedChartOptions,
  generateTokenStakedChartData,
} from 'utils/TokenStakedChart'

function TokenStakedChart() {
  const { range, setFrom, setTo, formattedFrom, formattedTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: formattedFrom,
      to: formattedTo,
    },
  })

  const stackedTokenAmount = useMemo(
    () => data?.systemDailyStatsGraphData?.stackedTokenAmount || [],
    [data],
  )

  const xTicks = useMemo(
    () => getXTicks(stackedTokenAmount),
    [stackedTokenAmount],
  )

  const startHAxis = useMemo(
    () => data?.systemDailyStatsGraphData?.stackedTokenAmount[0]?.day,
    [data],
  )

  const chartOptions = createTokenStakedChartOptions(xTicks, startHAxis)

  const statsChartData = useMemo(
    () =>
      stackedTokenAmount?.length
        ? generateTokenStakedChartData(stackedTokenAmount)
        : [],
    [stackedTokenAmount],
  )

  const noChartData = stackedTokenAmount?.length < 2

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="end">
        <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
      </Box>

      {error && <ErrorChartPlaceholder />}
      {!error && noChartData && <EmptyChartPlaceholder />}
      {!error && !noChartData && (
        <Chart
          chartType="AreaChart"
          data={statsChartData}
          height="100%"
          options={chartOptions}
        />
      )}
    </>
  )
}

export default TokenStakedChart
