import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { Snackbar } from '@mui/material'
import { Alert, Input, ModalWrapper } from 'common'
import { AlertMessage, Provider } from 'common/types'
import { ALERT_DELAY, ROWS_PER_PAGE_DEFAULT } from 'constants/params'
import schemaPattern from 'constants/schemaPattern'
import { FormikProps, useFormik } from 'formik'
import { CREATE_PROVIDER, UPDATE_PROVIDER } from 'graphql/providers/mutations'
import { GET_PROVIDERS } from 'graphql/providers/queries'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { InitialProviderFormValues, ProviderForm } from '../types'

interface Props {
  open: boolean
  onClose: () => void
  entity: Provider | null
}

function CreateModal({ open, onClose, entity }: Props) {
  const [createProvider, { loading: createLoading }] =
    useMutation(CREATE_PROVIDER)
  const [updateProvider, { loading: updateLoading }] =
    useMutation(UPDATE_PROVIDER)

  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const isEdit = useMemo(() => !!entity?.id, [entity])

  const initialValues: InitialProviderFormValues = {
    name: '',
    dailyQueriesCap: 0,
    queryConfirmationsCap: 3,
    credentials: '',
  }

  const createProviderSchema = useMemo(
    () => ({
      name: schemaPattern('name'),
      dailyQueriesCap: schemaPattern('dailyQueriesCap'),
      queryConfirmationsCap: schemaPattern('queryConfirmationsCap'),
      credentials: schemaPattern('credentials'),
    }),
    [],
  )

  const updateProviderSchema = useMemo(
    () => ({
      dailyQueriesCap: schemaPattern('dailyQueriesCap'),
      queryConfirmationsCap: schemaPattern('queryConfirmationsCap'),
    }),
    [],
  )

  const validationSchema = useMemo(
    () => yup.object(isEdit ? createProviderSchema : updateProviderSchema),
    [createProviderSchema, isEdit, updateProviderSchema],
  )

  const formik: FormikProps<ProviderForm> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => handleSubmit(values),
    validateOnChange: false,
  })

  // need for update initialState after getting entity!
  useEffect(() => {
    if (entity) {
      formik.setValues({
        name: entity?.name,
        dailyQueriesCap: entity?.dailyQueriesCap,
        queryConfirmationsCap: entity?.queryConfirmationsCap,
        credentials: '',
      })
    }
  }, [entity])

  const handleSubmit = useCallback(
    async (values: InitialProviderFormValues) => {
      const alertText = isEdit
        ? 'Provider updated successfully'
        : 'Provider created successfully'
      const mutationHandler = isEdit ? updateProvider : createProvider
      try {
        await mutationHandler({
          variables: isEdit
            ? {
                updateProviderId: entity?.id,
                updateProviderData: {
                  dailyQueriesCap: values.dailyQueriesCap,
                  queryConfirmationsCap: values.queryConfirmationsCap,
                },
              }
            : {
                createProviderData: {
                  name: values.name,
                  dailyQueriesCap: values.dailyQueriesCap,
                  queryConfirmationsCap: values.queryConfirmationsCap,
                  credentials: values.credentials
                    ? JSON.stringify(JSON.parse(values.credentials))
                    : JSON.stringify({}),
                },
              },
          refetchQueries: [
            {
              query: GET_PROVIDERS,
              variables: {
                take: ROWS_PER_PAGE_DEFAULT,
                page: 1,
                search: '',
              },
            },
          ],
        })
        setAlert({
          isOpen: true,
          text: alertText,
          alertColor: 'success',
        })
        onClose()
        formik.resetForm()
      } catch (error) {
        setAlert({
          isOpen: true,
          text: getErrorMessage(error),
          alertColor: 'error',
        })
      }
    },
    [createProvider, entity?.id, formik, isEdit, onClose, updateProvider],
  )

  return (
    <>
      <ModalWrapper
        buttonText={isEdit ? 'Edit' : 'Create'}
        disabled={createLoading || updateLoading}
        open={open}
        title={
          isEdit ? `Edit ${entity?.name || 'provider'}?` : `Create provider`
        }
        onClose={() => {
          onClose()
          formik.resetForm()
        }}
        onSubmit={formik.handleSubmit}
      >
        {!isEdit && (
          <Input
            error={formik.errors.name}
            name="name"
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        )}
        {!isEdit && (
          <Input
            error={formik.errors.credentials}
            name="credentials"
            placeholder="Credentials"
            type={'textarea'}
            value={formik.values.credentials}
            onChange={formik.handleChange}
          />
        )}
        <label>Daily queries cap</label>
        <Input
          error={formik.errors.dailyQueriesCap}
          name="dailyQueriesCap"
          placeholder="Daily queries cap"
          type={'number'}
          value={formik.values.dailyQueriesCap}
          onChange={formik.handleChange}
        />
        <label>Query confirmations cap</label>
        <Input
          error={formik.errors.queryConfirmationsCap}
          name="queryConfirmationsCap"
          placeholder="Query confirmations cap"
          type={'number'}
          value={formik.values.queryConfirmationsCap}
          onChange={formik.handleChange}
        />
      </ModalWrapper>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CreateModal
