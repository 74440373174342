import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { NODE_VERSIONS_WITH_COUNT } from 'graphql/overview/queries'
import {
  createVersionsChartOptions,
  generateVersionChartData,
} from 'utils/VersionsChart'

import { ChartContainer, ChartTitle, LoadingHolder, Text } from './styles'

function VersionsCharts() {
  const { loading, data } = useQuery(NODE_VERSIONS_WITH_COUNT)

  const versionsCliData = useMemo(
    () =>
      data?.nodeVersionsWithCount?.cliData?.length
        ? generateVersionChartData(data?.nodeVersionsWithCount?.cliData)
        : [],
    [data],
  )

  const cliOptions = createVersionsChartOptions()

  return (
    <>
      <Text mt={2} mx={2}>
        Node versions
      </Text>
      <ChartContainer display="flex">
        {loading ? (
          <LoadingHolder width="100%">
            <CircularProgress size={20} />
          </LoadingHolder>
        ) : (
          <Box width="100%">
            <ChartTitle fontSize="12px" mx={2}>
              CLI Versions
            </ChartTitle>
            {versionsCliData.length ? (
              <Chart
                chartType="ColumnChart"
                data={versionsCliData}
                height="100%"
                options={cliOptions}
                width="100%"
              />
            ) : (
              <Box alignItems="center" display="flex" height={1}>
                <Text color={'colors.font.default'} mx={2}>
                  No Data
                </Text>
              </Box>
            )}
          </Box>
        )}
      </ChartContainer>
    </>
  )
}

export default VersionsCharts
