import React, { useMemo } from 'react'
import { Chart } from 'react-google-charts'

import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import {
  ChartLegend,
  EmptyChartPlaceholder,
  ErrorChartPlaceholder,
  LoaderHolder,
} from 'common'
import DateRange from 'common/ui/dateRange'
import { SYSTEM_DAILY_STATS_GRAPH_DATA } from 'graphql/systemDailyStats/queries'
import { useDateRange } from 'hooks'
import { getXTicks } from 'utils/Charts'
import {
  createValidatorsBarChartOptions,
  generateValidatorsBarChartData,
} from 'utils/ValidatorsBarChart'

const LegendItems = [
  { title: 'Registered', color: '#91A7D4' },
  { title: 'Closed', color: '#D92E35' },
]

function ValidatorsBarChart() {
  const { range, setFrom, setTo } = useDateRange()

  const { data, loading, error } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const closedValidatorsCount = useMemo(
    () => data?.systemDailyStatsGraphData?.closedValidatorsCount || [],
    [data],
  )

  const registeredValidatorsCount = useMemo(
    () => data?.systemDailyStatsGraphData?.registeredValidatorsCount || [],
    [data],
  )

  const statsData = useMemo(
    () =>
      registeredValidatorsCount.length && closedValidatorsCount.length
        ? generateValidatorsBarChartData(
            registeredValidatorsCount,
            closedValidatorsCount,
          )
        : [],
    [registeredValidatorsCount, closedValidatorsCount],
  )

  const xTicks = useMemo(
    () => getXTicks(registeredValidatorsCount),
    [registeredValidatorsCount],
  )

  const startHAxis = useMemo(
    () => data?.systemDailyStatsGraphData?.registeredValidatorsCount[0]?.day,
    [data],
  )

  const options = createValidatorsBarChartOptions(xTicks, startHAxis)

  const noChartData =
    !closedValidatorsCount.length && !registeredValidatorsCount.length

  if (loading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mt={1}
        width="100%"
      >
        <ChartLegend legendItems={LegendItems} />
        <Box display="flex">
          <DateRange range={range} onChangeFrom={setFrom} onChangeTo={setTo} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="end"
      >
        {error && <ErrorChartPlaceholder />}
        {!error && noChartData && <EmptyChartPlaceholder />}
        {!error && !noChartData && (
          <Chart
            chartType="ColumnChart"
            data={statsData}
            height="100%"
            options={options}
          />
        )}
      </Box>
    </>
  )
}

export default ValidatorsBarChart
