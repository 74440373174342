import { ApolloClient } from '@apollo/client'

export enum SharedService {
  ApolloClient = 'apolloClient',
  WebSocketLink = 'webSocketLink',
}

type Client = ApolloClient<any>
type WebSocketLink = any

type Services = {
  [SharedService.ApolloClient]?: Client
  [SharedService.WebSocketLink]?: WebSocketLink
}

const services = {} as Services

function checkHasValue<T>(serviceName: SharedService) {
  if (!services[serviceName])
    throw new Error(`${serviceName} not found in Shared`)

  return services[serviceName] as unknown as T
}

// Setters
function setApolloClient(client: Client) {
  services[SharedService.ApolloClient] = client
}

function setWebSocketLink(webSocketLink: WebSocketLink) {
  services[SharedService.WebSocketLink] = webSocketLink
}

// Getters
function getApolloClient() {
  return checkHasValue<Client>(SharedService.ApolloClient)
}

function getWebSocketLink() {
  return checkHasValue<any>(SharedService.WebSocketLink)
}

export default {
  setApolloClient,
  getApolloClient,
  setWebSocketLink,
  getWebSocketLink,
}
