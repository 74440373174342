import { Box, css } from '@mui/material'
import { styled } from '@mui/system'

export const TabWrapper = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  cursor: pointer;
`

interface IconWrapperProps {
  active?: boolean
}
const iconWrapperCss = ({ active }: IconWrapperProps) =>
  active &&
  css`
    background-color: #f1f1f1;
  `

export const IconWrapper = styled(Box)<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  &:first-child {
    border-right: 1px solid #f1f1f1;
  }
  &:hover {
    background-color: #f1f1f1;
  }

  ${iconWrapperCss}
`
