import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { AlertColor, Button, Snackbar } from '@mui/material'
import { Alert, Input } from 'common'
import { ALERT_DELAY } from 'constants/params'
import { emailValidationReg } from 'constants/validations'
import { useFormik } from 'formik'
import { LOGIN_ADMIN } from 'graphql/auth/mutations'
import { AuthContext } from 'index'
import jwtDecode from 'jwt-decode'
import { LoginFormLayout } from 'layouts'
import { Routes } from 'router/routes'
import Auth from 'services/Auth'
import { getErrorMessage } from 'utils/Error'
import * as yup from 'yup'

import { InitialState } from './login.types'
import { StyledLink, Title } from './styles'

function Login() {
  const navigate = useNavigate()
  const { setAuthorized } = useContext(AuthContext)
  const [loginAdmin, { loading }] = useMutation(LOGIN_ADMIN)

  const [alert, setAlert] = useState<{
    isOpen: boolean
    text?: string
    alertColor?: AlertColor
  }>({
    isOpen: false,
  })

  const initialValues: InitialState = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Invalid email')
      .matches(emailValidationReg, 'Invalid email')
      .required('Email is a required field'),
    password: yup.string().min(4).required(),
  })

  const handleSubmit = async (values: InitialState) => {
    try {
      const { email, password } = values
      const result = await loginAdmin({
        variables: {
          password,
          email,
        },
      })

      if (result?.data?.loginAdmin) {
        const decodedToken = jwtDecode<any>(result?.data.loginAdmin?.access)
        Auth.handleAuth(
          result?.data.loginAdmin?.access,
          result?.data.loginAdmin?.refresh,
        )
        if (
          decodedToken?.verificationTOTPEnabled &&
          !decodedToken?.checkedVerification
        ) {
          navigate(Routes.VERIFICATION, { state: { email, password } })
        } else {
          setAuthorized(true)
          navigate(Routes.ROOT)
        }
      }
    } catch (error) {
      setAlert({
        isOpen: true,
        text: getErrorMessage(error),
        alertColor: 'error',
      })
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  })

  return (
    <>
      <LoginFormLayout>
        <Title>Sign In</Title>
        <Input
          error={formik.errors.email}
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Input
          error={formik.errors.password}
          name="password"
          placeholder="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <Button
          disabled={loading}
          sx={{ textTransform: 'initial' }}
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </Button>
        <StyledLink to={Routes.CONFIRM_EMAIL}>Forgot password?</StyledLink>
      </LoginFormLayout>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Login
