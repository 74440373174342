import React, { useCallback, useMemo } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { MNWDailyStats } from 'common/types'
import { propertiesMetadata } from 'constants/stats'
import { getFormattedNumber } from 'utils/Number'

import { isNumber } from 'lodash'
import find from 'lodash/find'
import first from 'lodash/first'
import keys from 'lodash/keys'
import map from 'lodash/map'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'

import { TableWrapper } from './styles'

type Props = {
  data?: (MNWDailyStats | { [key: string]: number | string })[]
}

const useStyles = makeStyles({
  fixedColumn: {
    padding: 0,
    position: 'sticky',
    left: 0,
    fontWeight: 'bold',
  },
})

export default function BasicTable({ data = [] }: Props) {
  const classes = useStyles()

  const dates = useMemo(() => uniq(map(data, 'day')), [data])
  const properties = useMemo(
    () => keys(omit(first(data), ['day', 'id', '__typename'])),
    [data],
  )

  const formatData = useCallback((property: string, data: number | string) => {
    if (isNumber(data)) return getFormattedNumber(data)
    return data
  }, [])

  return (
    <TableWrapper>
      <TableContainer>
        <Table aria-label="daily stats table">
          <TableHead sx={{ padding: 0 }}>
            <TableRow>
              <TableCell className={classes.fixedColumn}>
                <Box
                  borderRight="1px solid rgba(224, 224, 224, 1)"
                  height="100%"
                  padding="28px 16px"
                  width="100%"
                >
                  Property
                </Box>
              </TableCell>
              {map(dates, date => (
                <TableCell
                  key={date}
                  sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                >
                  {date}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data?.length ? (
              <TableRow>
                <TableCell align={'center'} colSpan={4}>
                  The list is empty
                </TableCell>
              </TableRow>
            ) : (
              properties.map(property => (
                <TableRow key={property}>
                  <TableCell className={classes?.fixedColumn}>
                    <Box
                      borderRight="1px solid rgba(224, 224, 224, 1)"
                      display="flex"
                      height="100%"
                      justifyContent="space-between"
                      padding={2}
                      width="100%"
                    >
                      <Box mr={2} whiteSpace="nowrap">
                        {
                          // @ts-ignore
                          propertiesMetadata[property]?.title
                        }
                      </Box>
                      <Box alignItems="center" display="flex">
                        <Tooltip
                          title={
                            // @ts-ignore
                            propertiesMetadata[property]?.hint
                          }
                        >
                          <InfoIcon
                            className="itemIcon"
                            sx={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                  {dates.map(date => {
                    const dataForDate = find(data, { day: date })

                    return (
                      <TableCell key={`${property}-${date}`}>
                        {dataForDate && property in dataForDate
                          ? formatData(
                              property,
                              (
                                dataForDate as {
                                  [key: string]: string | number
                                }
                              )[property],
                            )
                          : '-'}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  )
}
