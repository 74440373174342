import { gql } from '@apollo/client'

export const SYSTEM_SETTINGS = gql`
  query SystemSettings {
    systemSettings {
      cliVersion
      dailyValidationsCap
      gasEmailsList
      currentDailyValidationsCap
    }
  }
`
