import React from 'react'

import { Box, Typography } from '@mui/material'
import { ModalWrapper } from 'common'
import { NewsArticle } from 'common/types'
import { getFormattedDate } from 'utils/Date'

interface Props {
  open: boolean
  onClose: () => void
  newsData: NewsArticle
}
function ViewNewsModal({ open, onClose, newsData }: Props) {
  const { title, createdAt, content, published, publishedAt } = newsData

  return (
    <ModalWrapper open={open} title="Announcement details" onClose={onClose}>
      <Box>
        <Typography fontWeight={600} sx={{ wordWrap: 'break-word' }}>
          {title}
        </Typography>
        <Box maxHeight={300} my={3} sx={{ overflow: 'auto' }}>
          {content}
        </Box>
      </Box>
      <Box textAlign="center">
        <Typography>Created At: {getFormattedDate(createdAt)} </Typography>
        {published && (
          <Typography mt={1}>
            Published At: {getFormattedDate(publishedAt)}{' '}
          </Typography>
        )}
      </Box>
    </ModalWrapper>
  )
}

export default ViewNewsModal
